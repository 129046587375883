export const normalTheme = {
    company_blue : "#2594A2",
    light_blue_grey : "#F8F9Fb",
    orange : "#FF9803",
    red : "#F44236",
    black : "#000000",
    white : "#FFFFFF", 
    light_grey : "#D3D3D3",
    dark_grey : "#A9A9A9",
};
