import Dialog from '@mui/material/Dialog';
import React from "react"
import {useTranslation} from "react-i18next"
import { shopServiceTime } from '../../utils/utils';
import moment from 'moment-timezone'
import {shopStatusCode} from  "../../utils/utils";

let intervalsArray = new Array(8);

const DayTime = (props) => {
    return(
        <div className={props.type !== undefined ? props.type + " day-available-time" : " day-available-time"}>
            <span className="day">{props.day} :</span>
            <div className="time">
                {props.times.map((time,index)=>{
                    return <span key={index} className="time">{time}</span>
                })}
            </div>
        </div>
    );
}
function ServiceHours(props) {
    const {t} = useTranslation();
    const { onClose, open, dayOfWeek, lastOrder, currentTime, deviceTime } = props;
    const handleClose = () => {
      onClose();
    };
    const showTimezone = currentTime !== deviceTime ? true : false;
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className="service-hours-dialog icon-dialog">
            {showTimezone 
                ? <div className="title text-main-color"> {t("serviceHours")} ( {t("shopTimeZone")} : UTC+8 )</div> 
                : <div className="title text-main-color"> {t("serviceHours")} </div>
            }
            <div className="service-hours-dialog-content">
                <div className="time-list">
                    <DayTime day={t("sunday")} times={intervalsArray[0]} type={dayOfWeek===0?"today":undefined}></DayTime>
                    <DayTime day={t("monday")} times={intervalsArray[1]} type={dayOfWeek===1?"today":undefined}></DayTime>
                    <DayTime day={t("tuesday")} times={intervalsArray[2]} type={dayOfWeek===2?"today":undefined}></DayTime>
                    <DayTime day={t("wednesday")} times={intervalsArray[3]} type={dayOfWeek===3?"today":undefined}></DayTime>
                    <DayTime day={t("thursday")} times={intervalsArray[4]} type={dayOfWeek===4?"today":undefined}></DayTime>
                    <DayTime day={t("friday")} times={intervalsArray[5]} type={dayOfWeek===5?"today":undefined}></DayTime>
                    <DayTime day={t("saturday")} times={intervalsArray[6]} type={dayOfWeek===6?"today":undefined}></DayTime>        
                    <DayTime day={t("publicHoliday")} times={intervalsArray[7]} type={dayOfWeek===7?"today":undefined}></DayTime>
                </div>
                {(lastOrder != null && lastOrder !== 0) ? <hr/> : null}
                {(lastOrder != null && lastOrder !== 0) ? <div className="time-list">
                    <div className={" day-available-time"}>
                        <span className="day">{t("lastOrder")} :</span>
                        <div className="time">
                            <span className="time">{t("minBeforeClose1")}{lastOrder}{t("minBeforeClose2")}</span>
                        </div>
                    </div>
                </div> : null}
            </div>
        </Dialog>
    );
  }
function ServiceHoursDialog(props) {
    const {t} = useTranslation();
    let {serviceTime, selectTime, currentTime, deviceTime, isPublicHolidayWeek, lastOrder, actualCurrentTime, status} = props;
    const [open, setOpen] = React.useState(false);

    const timeZone = "Asia/Hong_Kong";
    let dayOfWeek = moment.unix(actualCurrentTime).tz(timeZone).day(); //Display in shop timezone

    currentTime = actualCurrentTime ? moment.unix(actualCurrentTime).tz(timeZone).format("YYYY MMM DD, HH:mm") : moment.unix(currentTime).tz(timeZone).format("YYYY MMM DD, HH:mm")
    let currentTimeShort = actualCurrentTime ? moment.unix(actualCurrentTime).tz(timeZone).format("yyyyMMDD") : moment.unix(currentTime).tz(timeZone).format("yyyyMMdd")
    deviceTime = actualCurrentTime ? moment.unix(actualCurrentTime).format("YYYY MMM DD, HH:mm") : moment(deviceTime).format("YYYY MMM DD, HH:mm")

    if (selectTime == null || isNaN(selectTime)) {
        //selectTime = currentTime
    } else {
        dayOfWeek = moment.unix(selectTime).tz(timeZone).day()
        //selectTime = moment.unix(selectTime).tz(timeZone).format("YYYY MMM DD, HH:mm")
    }
    if (isPublicHolidayWeek.hasOwnProperty(currentTimeShort)) {
      if (isPublicHolidayWeek[currentTimeShort] && (serviceTime[dayOfWeek].followPh != null && serviceTime[dayOfWeek].followPh)) {
        dayOfWeek = 7
      }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = (date, time) => {
        setOpen(false);
    };
    serviceTime.forEach((service,index)=>{
        intervalsArray[index] = [];
        if(service.status===shopServiceTime.open24Hour){
            intervalsArray[index].push(t("allDay"));
        }else if(service.status===shopServiceTime.closed){
            intervalsArray[index].push(t("shopClosed"));
        }else if(service.status===shopServiceTime.custom){
            service.intervals.forEach((interval)=>{
                intervalsArray[index].push(
                    numberToString((interval.begin-interval.begin%60)/60,2)+":"+numberToString(interval.begin%60,2)+" - "+
                    numberToString((interval.end-interval.end%60)/60,2)+":"+ numberToString(interval.end%60,2));
            });
        }
    });
    let todayIntervalString = "";
    switch(dayOfWeek){
        default: todayIntervalString=t("sunday")+" : "; break;
        case 1: todayIntervalString=t("monday")+" : "; break;
        case 2: todayIntervalString=t("tuesday")+" : "; break;
        case 3: todayIntervalString=t("wednesday")+" : "; break;
        case 4: todayIntervalString=t("thursday")+" : "; break;
        case 5: todayIntervalString=t("friday")+" : "; break;
        case 6: todayIntervalString=t("saturday")+" : "; break;
        case 7: todayIntervalString=t("publicHolidayShort")+" : "; break;
    }
    intervalsArray[dayOfWeek].forEach((interval,index)=>{
        if(index!==0) todayIntervalString = todayIntervalString.concat(", ");
        todayIntervalString = todayIntervalString.concat(interval);
    })
    var statusText
    if (status === shopStatusCode.active) {
        statusText = todayIntervalString;
      } else if (status === shopStatusCode.temporarilyClosed) {
        statusText = t('shopTemporarilyClosed');
      } else if (status === shopStatusCode.permanentlyClosed) {
        statusText = t('shopPermanentlyClosed');
      } else {
        statusText = t('shopUnderRenovation');
      }
  
    return (
        <React.Fragment>
            {status === shopStatusCode.active ?
            <div style={{cursor: "pointer", display:"inline-block"}} /*className="service-hours-click"*/ onClick={handleClickOpen}>
                <p style={{margin: "0"}}>{todayIntervalString}</p>
                {(!(todayIntervalString.toLowerCase().includes("close") || todayIntervalString.toLowerCase().includes("关门") || todayIntervalString.toLowerCase().includes("休息")) && lastOrder != null && lastOrder !== 0) ? <p style={{margin: "0"}}>{t("lastOrder")} : {t("minBeforeClose1")}{lastOrder}{t("minBeforeClose2")}</p> : null}
            </div>
            : <div>{statusText}</div>}
            <ServiceHours open={open} onClose={handleClose} dayOfWeek={dayOfWeek} lastOrder={lastOrder} currentTime={currentTime} deviceTime={deviceTime}/>
        </React.Fragment>
    );
}

//create leading zeros
function numberToString(number, digits){
    var numberRemaining = number;
    var returnString = "";
    for(let i=(digits-1);i>=0;i--){
        returnString+=Math.floor(numberRemaining/Math.pow(10,i));
        numberRemaining=numberRemaining%Math.pow(10,i);
    }
    return returnString;
}
export default ServiceHoursDialog;
