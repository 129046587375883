import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';

class UserAppRedirect extends Component {
    render (){
        var link
        if (localStorage.getItem("language")==="en") {link = "/en/user/app"}
        if (localStorage.getItem("language")==="zh_Hant") {link = "/tc/user/app"}
        if (localStorage.getItem("language")==="zh_Hans") {link = "/sc/user/app"}
        if (localStorage.getItem("language")==null || localStorage.getItem("language")==="") {link = "/en/user/app"}
        return (<Navigate to={link}/>)
    }
}

export default UserAppRedirect;
