import Dialog from '@mui/material/Dialog';
import React from "react"
import {getDeltaOps} from "../../utils/utils";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import parse from 'html-react-parser';

function Tag(props) {
    const { onClose, open, tag} = props;
    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className="icon-dialog">
            <div className="title text-main-color">{tag.name}</div>
            <div className="tag-dialog-content">
                {(() => {
                    const dataList = JSON.parse(tag.remarks);
                    const deltaOps = getDeltaOps(dataList);
                    const cfg = {};
                    const converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
                    const html = converter.convert();
                    return <div className="text-border">{parse(html)}</div>;
                })()}
            </div>
        </Dialog>
    );
  }
function TagDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    //TODO change <a>
    return (
        <React.Fragment>
            <div style={{cursor: "pointer", display:"inline-block"}} onClick={handleClickOpen}>
                <span key={ props.key }>{ props.tag.name }</span>
            </div>
            <Tag open={open} onClose={handleClose} tag={props.tag}/>
        </React.Fragment>
    );
}
export default TagDialog;
