import { getI18n } from "react-i18next";

export async function getMerchantSeo(username) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/seo-username/"+username+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    });
}

export async function getMerchantSeoByID(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/seo/"+id+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    });
}

export async function getMerchantInfo(username) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/info-username/"+username+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    });
}

export async function getMerchantInfoByID(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/info/"+id+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    });
}

export async function getMerchantShop(username) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/shop-list-username/"+username+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    });
}

export async function getMerchantShopByID(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/merchant/shop-list/"+id+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    });
}