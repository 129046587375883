import React from "react"
import {withTranslation} from "react-i18next";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../font/demo.css'
import '../font/iconfont.css'
import '../styles/shop.css'
import '../font/app_iconfont.js'
import Header from  "../components/item/Header"
import Page404 from "../pages/404";
import EmptyError from "../components/EmptyError";
import EmptyLoading from "../components/EmptyLoading";
import BoxItem from "../components/home/BoxItem"
import SNSDialog from  "../components/item/SNSDialog"
import CategoryDialog from "../components/item/CategoryDialog"
import WarningDialog from  "../components/WarningDialog";
import ShareSnack from  "../components/item/ShareSnack"
import {getMerchantInfo, getMerchantInfoByID, getMerchantShop, getMerchantShopByID} from "../services/merchant";
import {shopStatusCode, getTimeRep, categoryToLevel, changeLanguage, getDeltaOps} from  "../utils/utils";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import parse from 'html-react-parser';

class Merchant extends React.Component{

    constructor(props){
        super(props)
        this.productChange = this.productChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.state = {
            merchant: null,
            shopList: null,
            hasError: "",
            shareLink: null,
        };
    }
    componentDidMount() { 
        const {i18n} = this.props;
        const username = this.props.params.username;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const queryID = params.get('id');

        const queryLocale = params.get('locale') ? params.get('locale').toLowerCase() : "";
        var locale = "";

        if (queryLocale === "tc" || queryLocale === "zhhant" || queryLocale === "zh_hant" || queryLocale === "zh-hant" || queryLocale === "zhhk" || queryLocale === "zh_hk" || queryLocale === "zh-hk" || queryLocale === "zhtw" || queryLocale === "zh_tw" || queryLocale === "zh-tw" || queryLocale === "zhmo" || queryLocale === "zh_mo" || queryLocale === "zh-mo") {
            locale = "zh_Hant"
        } else if (queryLocale === "sc" || queryLocale === "zhhans" || queryLocale === "zh_hans" || queryLocale === "zh-hans" || queryLocale.slice(0,2) === "zh") {
            locale = "zh_Hans"
        } else if (queryLocale === "en" || queryLocale.slice(0,2) === "en") {
            locale = "en";
        }
        if (locale === "") {
            if(localStorage.getItem("language")){
                i18n.changeLanguage(localStorage.getItem("language"));
            }
        } else {
            i18n.changeLanguage(locale);
            localStorage.setItem("language",locale);
        }

        //TODO Simplify this part
        if (queryID != null && queryID !== "") {
            getMerchantInfoByID(queryID).then((res)=>{
                if(res.data){
                    this.setState({merchant: res.data});
                    getMerchantShopByID(queryID).then((res)=>{
                        if(res.data){
                            let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                            let handleSet = new Set();
                            let shopList = [];
                            shopListWithDuplicate.map((shop) => {
                                if (!handleSet.has(shop.handle)) {
                                  handleSet.add(shop.handle);
                                  shopList.push(shop);
                                }
                                return shop
                              });
                            shopList.sort((a,b)=>{
                                if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return 1;
                                }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return -1;
                                }else{
                                    return 0;
                                }
                            });
                            this.setState({shopList: shopList});
                        }else{ //invalid merchant id
                            this.setState({hasError: "merchantInfoError"});
                        }
                    }).catch((err)=>{
                        this.setState({hasError: "netError"})
                    });
                        this.setState({shareLink: process.env.REACT_APP_LINK +"/m/"+ res.data.username + '?id=' + res.data.id});
                    //params.delete('id')
                    window.history.replaceState(null, '', `/m/${res.data.username}`);
                }else{ //invalid merchant id
                    getMerchantInfo(username).then((res)=>{
                        if(res.data){
                            this.setState({merchant: res.data});
                            getMerchantShop(username).then((res)=>{
                                if(res.data){
                                    let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                    let handleSet = new Set();
                                    let shopList = [];
                                    shopListWithDuplicate.map((shop) => {
                                        if (!handleSet.has(shop.handle)) {
                                          handleSet.add(shop.handle);
                                          shopList.push(shop);
                                        }
                                        return shop
                                      });
                                    shopList.sort((a,b)=>{
                                        if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                            return 1;
                                        }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                            return -1;
                                        }else{
                                            return 0;
                                        }
                                    });
                                    this.setState({shopList: shopList});
                                }else{ //invalid merchant username
                                    this.setState({hasError: "merchantInfoError"});
                                }
                            }).catch((err)=>{
                                this.setState({hasError: "netError"})
                            });
                                this.setState({shareLink: process.env.REACT_APP_LINK +"/m/"+ res.data.username + '?id=' + res.data.id});
                        }else{ //invalid merchant username
                            this.setState({hasError: "merchantInfoError"});
                        }
                        //params.delete('id')
                        window.history.replaceState(null, '', `/m/${username}`);
                    }).catch((err)=>{
                        //params.delete('id')
                        window.history.replaceState(null, '', `/m/${username}`);
                        this.setState({hasError: "netError"})
                    });
                }
            }).catch((err)=>{
                this.setState({hasError: "netError"})
            });
        } else {
            getMerchantInfo(username).then((res)=>{
                if(res.data){
                    this.setState({merchant: res.data});
                    getMerchantShop(username).then((res)=>{
                        if(res.data){
                            let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                            let handleSet = new Set();
                            let shopList = [];
                            shopListWithDuplicate.map((shop) => {
                                if (!handleSet.has(shop.handle)) {
                                  handleSet.add(shop.handle);
                                  shopList.push(shop);
                                }
                                return shop
                              });
                            shopList.sort((a,b)=>{
                                if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return 1;
                                }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return -1;
                                }else{
                                    return 0;
                                }
                            });
                            this.setState({shopList: shopList});
                        }else{ //invalid merchant username
                            this.setState({hasError: "merchantInfoError"});
                        }
                    }).catch((err)=>{
                        this.setState({hasError: "netError"})
                    });
                        this.setState({shareLink: process.env.REACT_APP_LINK +"/m/"+ res.data.username + '?id=' + res.data.id});
                }else{ //invalid merchant username
                    this.setState({hasError: "merchantInfoError"});
                }
                //params.delete('id')
                window.history.replaceState(null, '', `/m/${username}`);
            }).catch((err)=>{
                //params.delete('id')
                window.history.replaceState(null, '', `/m/${username}`);
                this.setState({hasError: "netError"})
            });
        }
    } 
    componentDidUpdate(prev) {
        if(prev.params.username!==this.props.params.username || prev.location.search!==this.props.location.search){
            this.componentDidMount();
        } 
    }
    reloadBody(username){
        getMerchantInfo(username).then((res)=>{
            if(res.data){
                this.setState({merchant: res.data});
                    this.setState({shareLink: process.env.REACT_APP_LINK +"/m/"+ res.data.username + '?id=' + res.data.id});
            }else{ //invalid merchant username
                this.setState({hasError: "merchantInfoError"});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError"})
        });
        getMerchantShop(username).then((res)=>{
            if(res.data){
                let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                let handleSet = new Set();
                let shopList = [];
                shopListWithDuplicate.map((shop) => {
                    if (!handleSet.has(shop.handle)) {
                      handleSet.add(shop.handle);
                      shopList.push(shop);
                    }
                    return shop
                  });
                /*shopList.sort((a,b)=>{
                    if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                        return 1;
                    }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                        return -1;
                    }else{
                        return 0;
                    }
                });*/
                this.setState({shopList: shopList});
                /*navigator.geolocation.getCurrentPosition((position) => {
                    shopList.forEach((shop)=>{
                        shop.distance = calcCrow(shop.lat,shop.lon,position.coords.latitude, position.coords.longitude);
                    });
                    shopList.sort((a,b)=>{
                        if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                            return 1;
                        }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                            return -1;
                        }else{
                            return 0;
                        }
                    });
                    this.setState({shopList: shopList});
                }, (error) => {
                    getInit().then((res)=>{
                        if(res.data){
                            shopList.forEach((shop)=>{
                                shop.distance = calcCrow(shop.lat,shop.lon,res.data.mapbox.defaultLat,res.data.mapbox.defaultLon);
                            });
                            shopList.sort((a,b)=>{
                                if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return 1;
                                }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                    return -1;
                                }else{
                                    return 0;
                                }
                            });
                            this.setState({shopList: shopList});
                        }
                    }).catch((err)=>{
                    });
                });*/
            }else{ //invalid merchant username
                this.setState({hasError: "merchantInfoError"});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError"})
        });
    }
    tabChange(e){
        document.querySelector(".tab-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".tab-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }

    productChange(e){
        document.querySelector(".box-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".box-right .box-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }
    groupChangeState(e){
        let items2 = e.currentTarget.parentElement.querySelector('.box_items_1')
        items2.classList.remove("box_items_1")
        items2.classList.add("box_items_2")
        e.currentTarget.classList.remove("box_items_2")
        e.currentTarget.classList.add("box_items_1")
        
    }
    sortChangeState(e){
        e.currentTarget.parentElement.querySelector('.sort-item.active').classList.remove('active');
        e.currentTarget.classList.add("active");
    }
    /*sortChangeValue(value){
        let { shopList} = this.state;
        switch(value){
            case "date":
                shopList.sort((a,b)=>{
                    if (a.id > b.id){
                        return 1;
                    }else{
                        return -1;
                    }
                });
                break;
            case "dist":
                shopList.sort((a,b)=>{
                    if (a.distance > b.distance){
                        return 1;
                    }else{
                        return -1;
                    }
                });
                break;
            default: //name
                shopList.sort((a,b)=>{
                    if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                        return 1;
                    }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                        return -1;
                    }else{
                        return 0;
                    }
                });
                break;
        }
        this.setState({shopList: shopList});
    }*/
    
    mobileFilterClose(e){
        e.currentTarget.parentElement.parentElement.classList.add('hide');  
        document.querySelector(".wrapper .merchant-content").classList.remove("hide");   
        document.querySelector(".wrapper .mobile-content-div").classList.add("hide");  
    }

    mobileFilterClick(e){
        document.getElementById(e.currentTarget.dataset.href).classList.remove("hide");  
        document.querySelector(".wrapper .mobile-content-div").classList.remove("hide");   
        document.querySelector(".wrapper .merchant-content").classList.add("hide");   
    }
    goBack(){
        try {
            this.props.navigate(-1);
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t, i18n} = this.props;
        const { merchant , shopList, hasError, shareLink} = this.state;
        const username = this.props.params.username;
        const nextLanguage = () => {
            if(i18n.language==="en"){
                changeLanguage("zh_Hant");
                this.reloadBody(username);
            }else if(i18n.language==="zh_Hant"){
                changeLanguage("zh_Hans");
                this.reloadBody(username);
            }else{
                changeLanguage("en");
                this.reloadBody(username);
            }
            /*if(props.reloadBody){
                props.reloadBody();
            }*/
        }

        if(merchant && shopList && !hasError){
            /*let emailsText = "", phonesText = "";
            merchant.emails.forEach((email,index)=>{
                if(index!==0){
                    emailsText = emailsText.concat(", ")
                }
                emailsText = emailsText.concat(email);
            });
            merchant.phones.forEach((phone,index)=>{
                if(index!==0){
                    phonesText = phonesText.concat(", ")
                }
                phonesText = phonesText.concat(phone);
            });*/
            let result = [];
            result = categoryToLevel(merchant.categories);

            return (
                <React.Fragment>
                    <Helmet>
                        <title>{`${merchant.name} - Lulu HK`}</title>
                        <meta property="og:title" content={`${merchant.name} - Lulu HK`} />
                        <meta property="og:description" content={merchant.bio} />
                        <meta property="og:image" content={`${merchant.avatar.substring(0, merchant.avatar.lastIndexOf("."))}_luluimg-m.jpg`} />
                        <meta name="description" content={merchant.bio}/>
                    </Helmet>
                    <div className="wrapper">
                        <Header location={false} merchant={true} merchantData={merchant.username} reloadBody={()=>this.reloadBody(username)}></Header>
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                                <span className="text-main-color title">{"@"+merchant.username}</span>
                                {/*<div className="search-back">
                                    <ArrowBackIosIcon onClick={this.goBack} />
                                    <span className="text-main-color title">{"@"+merchant.username}</span>
                                </div>*/}
                                <div className="mobile-icon-right">
                                <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                                </Link>
                                <Link to="/setting">
                                    <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                                </Link>
                                {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                                <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                                </div>
                                {/*<span className="icon LuluHK filter merchant-filter" data-href="merchant-filter" onClick={this.mobileFilterClick} ></span>*/}
                            </div>
                        </div>
                        <div className="mobile-content-div hide">
                            <div className="mobile-content hide merchant-main-filter" id="merchant-filter">
                                <div className="mobile-nav">
                                    {/*<div className="search-back" onClick={this.mobileFilterClose}><ArrowBackIosIcon /></div>*/}
                                    {/*<span className="text-main-color">Filters</span>
                                    <span className="icon LuluHKset result-filter text-main-color"></span>*/}
                                </div>
                                {/*<div className="main-merchant-filter-content filter-content">
                                    <div className="sort block-title">
                                        <span className="text-title-color text-md-left">{t('sort')}</span>
                                    </div>
                                    <div className="sort block-content">
                                        <div className="sort-item active" onClick={(e) => {this.sortChangeValue("name"); this.sortChangeState(e);} }>{t("shopName")}</div>
                                        <div className="sort-item" onClick={ (e) => {this.sortChangeValue("date"); this.sortChangeState(e);} }>{t("createTime")}</div>
                                        <div className="sort-item" onClick={ (e) => {this.sortChangeValue("dist"); this.sortChangeState(e);}  }>{t("distanceAsc")}</div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                        <div className="merchant-content" >
                            <div className="allImgBox">
                                <div className="topImgBox" style={{ backgroundImage: "url("+merchant.cover+")" }}></div>
                                <div className="mainImg">
                                    <img src={merchant.cover} alt="" height="100%"/>
                                </div>
                            </div>
                            <div className="businessNews">
                                <div className="circleImg">
                                    <img src={merchant.avatar} alt=""/>
                                </div>
                                <div className="newsTitle">
                                    <span className="text-main-color merchant-title">{merchant.name}</span>
                                    <span className="merchant-desc">{merchant.bio}</span>
                                </div>
                                <div className="shareLink text-main-color">
                                    <p>
                                        <ShareSnack source={'merchant'} shareLink={shareLink} title={merchant.name} address={merchant.address} phone={merchant.phones[0]} whatsapp={merchant.sns.whatsapp}/>
                                    </p>
                                </div>
                            </div>
                            <hr /*className="pc-hr"*//>
                            <div id="tab-demo">
                                <ul className="tab-title">
                                    <li key="shop" className="active"><span data-href="Shop" onClick={ this.tabChange }>{t("shop")+" ("+shopList.length+")"}</span></li>
                                    <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                    <li key="about" className=""><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                </ul>
                                <div id="Shop" className="tab-inner active">
                                {shopList.length === 0 ? 
                                        <div className="no-shop" style={{ display: "block", marginTop: "5em" }}>
                                            <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                            <p>{t("noShop")}</p>
                                        </div>
                                    : 
                                    <div className="tab-box">
                                        {/*<div className="box-left">
                                            <div className="content_box_2">
                                                <p className="text-title-color text-md-left">{t('sort')}</p>
                                            </div>
                                            <div className="content_box_2">
                                                <div style={{cursor: 'pointer'}} className="box_items_1" onClick={ (e) => {this.sortChangeValue("name"); this.groupChangeState(e);} }>{t("shopName")}</div>
                                                <div className="box_items_2" onClick={ (e) => {this.sortChangeValue("date"); this.groupChangeState(e);} }>{t("createTime")}</div>
                                                <div className="box_items_2" onClick={ (e) => {this.sortChangeValue("dist"); this.groupChangeState(e);} }>{t("distanceAsc")}</div>
                                            </div>
            </div>*/}
                                        <div className="box-right">
                                            <div className="item-list">  
                                                {shopList ? shopList.map((shop,index)=>{
                                                    let displayStatus, displayStatusMsg;
                                                    let shopResult = "";
                                                    shopResult = categoryToLevel(shop.categories);
                                        
                                                    var desc = ""
                                                    if (shopResult === undefined || shopResult.length === 0 || (shopResult.length === 1 && shopResult[0].trim() === '')) {
                                                        desc = "uncategorized"
                                                    } else {
                                                    for (var i = 0; i < shopResult.length; i++) {
                                                        if (i===3) {break}
                                                        var categoryDesc = (i === shopResult.length - 1 || i === 2) ? shopResult[i].split('>').pop().trim() : `${shopResult[i].split('>').pop().trim()}\xa0 • \xa0`
                                                        desc = desc + categoryDesc
                                                    }}
                                                    switch(shop.status){
                                                        case shopStatusCode.active:
                                                            if(shop.isOpen){
                                                                displayStatus = "normal";
                                                            }else{
                                                                displayStatus = "photo";
                                                                if(shop.nextOpenTime){
                                                                    displayStatusMsg = getTimeRep(shop.nextOpenTime,"open");
                                                                }else{
                                                                    displayStatusMsg = t("shopClosedToday");
                                                                }
                                                            }
                                                            break;
                                                        case shopStatusCode.temporarilyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopTemporarilyClosed");
                                                            break;
                                                        case shopStatusCode.underRenovation:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopUnderRenovation");
                                                            break;
                                                        case shopStatusCode.permanentlyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopPermanentlyClosed");
                                                            break;
                                                        default:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopClosed");
                                                            break;
                                                    }
                                                    return <BoxItem
                                                        key={shop.id} //TODO: why sometimes error?
                                                        handle={shop.handle}
                                                        status={displayStatus}
                                                        msg={displayStatus==="photo"?displayStatusMsg:null}
                                                        imgSrc={shop.cover}
                                                        imgAlt={shop.name}
                                                        name={shop.name}
                                                        desc={desc}
                                                        showID={true}
                                                        //distance={shop.distance >= 1 ? (Math.round(shop.distance*10)/10).toFixed(1)+"km": Math.round(shop.distance*1000)+"m"}
                                                    />  
                                                }): <div>{t("loading")}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div id="Post" className="tab-inner">
                                    <div className="vertical">
                                        {merchant.descriptions&&merchant.descriptions.length!==0 ? 
                                            merchant.descriptions.map((description,index)=>{
                                                if(description.type==="text"){
                                                    var dataList = JSON.parse(description.data)
                                                    var deltaOps =  getDeltaOps(dataList)
                                                    var cfg = {};
                                                    var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
                                                    var html = converter.convert();
                                                    return <div key={index} className="text-border">{parse(html)}</div>
                                                }else{
                                                    return <img key={index} className="image-border" src={description.data} alt=""/>
                                                }
                                            })
                                        :
                                            <div className="no-post" style={{ display: "block", marginTop: "5em" }}>
                                                <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                                <p>{t("noPost")}</p>
                                            </div> 
                                        }
                                    </div>
                                </div>
                                <div id="About" className="tab-inner">
                                    <div className="vertical">
                                        <div className="about-box">
                                            <div className="about-item">
                                                <span className="icon LuluHK home_line"></span>
                                                <label>{merchant.name}</label>
                                            </div>
                                            {(result === undefined || result.length === 0 || (result.length === 1 && result[0].trim() === '')) ? null : <div className="about-item">
                                                <span className="icon LuluHK category"></span>
                                                <label>{result.map((category,index)=>{
                                                    if (index === (result.length-1)){
                                                        return(
                                                            <CategoryDialog 
                                                                key={category.split('>').pop().trim()}
                                                                category={category}
                                                            />
                                                        )
                                                    } else {
                                                        return(
                                                            <React.Fragment key={category.split('>').pop().trim()}>
                                                                <CategoryDialog
                                                                    category={category}
                                                                />,&nbsp;
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })}</label>
                                            </div>}
                                            <div className="about-item">
                                                <span className="icon LuluHK location_line"></span>
                                                <label><a href={`https://www.google.com/maps/search/${merchant.lat},${merchant.lon}/@${merchant.lat},${merchant.lon},17z`} target="_blank" rel="noreferrer">
                                                {merchant.address}
                                                </a></label>
                                            </div>
                                            {merchant.phones&&merchant.phones.length!==0 ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK call_line"></span>
                                                    <label>{merchant.phones.map((phone,index)=>{
                                                            if (index === (merchant.phones.length-1)){
                                                                return(
                                                                    <a key={phone} href={`tel:${phone}`}>{phone}</a>
                                                                )
                                                            } else {
                                                                return(
                                                                    <React.Fragment key={phone}><a href={`tel:${phone}`}>{phone}</a>,&nbsp;</React.Fragment>
                                                                )
                                                            }
                                                        })}</label>
                                                </div>
                                            :null}
                                            {merchant.emails&&merchant.emails.length!==0 ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK mail_line"></span>
                                                        <label>{merchant.emails.map((email,index)=>{
                                                            if (index === (merchant.emails.length-1)){
                                                                return(
                                                                    <a key={email} href={`mailto:${email}`}>{email}</a>
                                                                )
                                                            } else {
                                                                return(
                                                                    <React.Fragment key={email}><a href={`mailto:${email}`}>{email}</a>,&nbsp;</React.Fragment>
                                                                )
                                                            }
                                                        })}</label>
                                                </div>
                                            :null}
                                            {merchant.website ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK lang"></span>
                                                    <label><a href={merchant.website} target="_blank" rel="noreferrer">{merchant.website}</a></label>
                                                </div>
                                            :null}
                                            <div className="social-media">
                                                {merchant.sns.facebook?
                                                    <a href={merchant.sns.facebook} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/facebook.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.instagram?
                                                    <a href={merchant.sns.instagram} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/instagram.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.youtube?
                                                    <a href={merchant.sns.youtube} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/youtube.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {/*{merchant.sns.weibo?
                                                    <a href={merchant.sns.weibo} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/weibo.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.twitter?
                                                    <a href={merchant.sns.twitter} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/twitter.png" alt=""></img>
                                                    </a>
                                                :null}*/}
                                                {merchant.sns.messenger?
                                                    <a href={merchant.sns.messenger} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/messenger.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.whatsapp?
                                                    <a href={merchant.sns.whatsapp} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/whatsapp.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.wechat?
                                                    <SNSDialog media="wechat" mediaId={merchant.sns.wechat}/>
                                                :null}
                                                {/*merchant.sns.qq?
                                                    <SNSDialog media="qq" mediaId={merchant.sns.qq}/>
                                                :null*/}
                                                {merchant.sns.deliveroo?
                                                    <a href={merchant.sns.deliveroo} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/deliveroo.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.foodpanda?
                                                    <a href={merchant.sns.foodpanda} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/foodpanda.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {merchant.sns.openrice?
                                                    <a href={merchant.sns.openrice} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/openrice.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {/* <SNSDialog />
                                                <YoutubeDialog /> */}
                                            </div>
                                            {merchant.links&&merchant.links.length!==0 ?
                                                <><hr /><div className="title">
                                                            <span className="linked-merchants">{t("linkedMerchants")}</span>
                                                        </div><div className="merchants">
                                                                {merchant.links.map((link) => {
                                                                    return (
                                                                    <Link target="_blank" key={link.username} to={"/m/" + link.username}>
                                                                        <span>
                                                                            {"@" + link.username}
                                                                        </span>
                                                                    </Link>);
                                                                })}
                                                            </div></>
                                            :null}
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }else if(hasError){ //header + EmptyError
            if(hasError==="merchantInfoError"){
                return <Page404 />
            }else{
                return (
                    <React.Fragment>
                    <div className="wrapper">
                        <Header merchantData="" reloadBody={()=>this.reloadBody(username)}></Header>
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                                {/*<div className="search-back">
                                    <ArrowBackIosIcon onClick={this.goBack} />
                                    <span className="text-main-color title"></span>
                                </div>*/}
                                                                <div className="mobile-icon-right">
                                <Link to="#">
                                    <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                                </Link>
                                <Link to="/setting">
                                    <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                                </Link>
                                {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                                <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                                </div>
                            </div>
                        </div>
                        <div className="merchant-content">
                            <EmptyError msg={t("error")+": "+t(hasError)}/>
                        </div>
                    </div>
                    </React.Fragment>
                )
            }            
        }else{ //header + EmptyLoading
            return (
                <React.Fragment>
                <div className="wrapper">
                    <Header merchantData="" reloadBody={()=>this.reloadBody(username)}></Header>
                    <div className="mobile-merchant-main">
                        <div className="mobile-nav">
                            {/*<div className="search-back">
                                <ArrowBackIosIcon onClick={this.goBack} />
                                <span className="text-main-color title"></span>
                            </div>*/}
                            <div className="mobile-icon-right">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                            </div>
                        </div>
                    </div>
                    <div className="merchant-content">
                        <EmptyLoading msg={t("loading")}/>
                    </div>
                </div>
                </React.Fragment>
            )
        }

    }
}

const MerchantWithLocation = (props) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return <Merchant {...props} location={location} params={params} navigate={navigate} />;
};

export default withTranslation()(MerchantWithLocation);
