
const EmptyLoading = (props) => {
    return(
      <div className="shop-loading">
        <div className="loading-item">
          <div className="loadEffect">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span className="text loading">{props.msg?props.msg:"Loading..."}</span>
          {/*Cannot implement i18n here because i18n is not loaded before this loading page appears*/}
        </div>
      </div>
    );
}

export default EmptyLoading;
