import { getI18n } from "react-i18next";

export async function getProductSeoByID(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/product/seo/"+id+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    }); 
}

export async function getProductSeo(shopHandle, handle) {
    return fetch(process.env.REACT_APP_API_SERVER+"/product/seo-handle/"+shopHandle+"/"+handle+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    }); 
}

export async function getProductInfoByID(id, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/product/info/"+id+"?locale="+getI18n().language+"&ts="+selectTime).then((res)=>{
        return res.json();
    }); 
}

export async function getProductInfo(shopHandle, handle, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/product/info-handle/"+shopHandle+"/"+handle+"?locale="+getI18n().language+"&ts="+selectTime).then((res)=>{
        return res.json();
    }); 
}

export async function getPtagList() {
    return fetch(process.env.REACT_APP_API_SERVER+"/product/tag/suggest?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    });
}