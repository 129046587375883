
import React from "react";
import {useTranslation} from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function BoxItem(props){
    const {t} = useTranslation();
    const shopUrl = (props.isEvent ? "/e/" : "/s/") + props.handle;
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleClickOpenSuccess = () => {
        setOpenSuccess(true);
    };

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    return (
        <React.Fragment>
            <div className="box_items" onClick={props.isSameShop != null && props.isSameShop ? handleClickOpenSuccess : null}>
                {props.status === "normal" ? (
                    props.isSameShop != null && props.isSameShop ? (
                        <a href="#" onclick="event.preventDefault();">
                            <div className="normal">
                                <img src={props.imgSrc} alt={props.imgAlt} />
                            </div>
                        </a>
                    ) : (
                        <a href={shopUrl}>
                            <div className="normal">
                                <img src={props.imgSrc} alt={props.imgAlt} />
                            </div>
                        </a>
                    )
                ) : (
                    props.isSameShop != null && props.isSameShop ? (
                        <a href="#" onclick="event.preventDefault();">
                            <div className="photo">
                                <img src={props.imgSrc} alt={props.imgAlt} />
                                <div className="photo_msg">{props.msg}</div>
                            </div>
                        </a>
                    ) : (
                        <a href={shopUrl}>
                            <div className="photo">
                                <img src={props.imgSrc} alt={props.imgAlt} />
                                <div className="photo_msg">{props.msg}</div>
                            </div>
                        </a>
                    )
                )}

                {props.isSameShop != null && props.isSameShop ? (
                    <h4>{props.name}</h4>
                ) : (
                    <a href={shopUrl}>
                        <h4>{props.name}</h4>
                    </a>
                )}

                {props.isSameShop != null && props.isSameShop ? (
                        <div className="box_detail">
                            <p className="desc">{props.showID ? props.handle : props.desc === "uncategorized" ? t("uncategorized") : props.desc}</p>
                            {props.distance ? <p className="distance">{props.distance}</p> : null}
                        </div>
                ) : (
                    <a href={shopUrl}>
                        <div className="box_detail">
                            <p className="desc">{props.showID ? props.handle : props.desc === "uncategorized" ? t("uncategorized") : props.desc}</p>
                            {props.distance ? <p className="distance">{props.distance}</p> : null}
                        </div>
                    </a>
                )}
            </div>
            <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSuccess} severity="info" sx={{ width: '100%' }}>
                    {t("alreadyOnShopPage")}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
export default BoxItem;