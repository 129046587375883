import Dialog from '@mui/material/Dialog';
import React from "react"
import {useTranslation} from "react-i18next";

function Category(props) {
    const {t} = useTranslation();
    const { onClose, open, category } = props;
    return (
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className="category-dialog icon-dialog">
        <div className="title text-main-color">{t("category")}</div>
        <div className="category-dialog-content">
            <div className="content text-main-color">
                { category }
            </div>
        </div>
        
      </Dialog>
    );
  }
function CategoryDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    //TODO change <a>
    return (
        <React.Fragment>
            <div style={{cursor: "pointer", display:"inline-block"}} /*className="shop-category-click"*/ onClick={handleClickOpen}>
                {props.category.split('>').pop().trim()}
            </div>
            <Category open={open} onClose={handleClose} category={props.category} />
        </React.Fragment>
    );
}
export default CategoryDialog;
