import { createGlobalStyle } from "styled-components";

const pc_style = createGlobalStyle`
    #root {
        min-height: 100%;
    }
    html, body {
        background-color:${(props) => props.theme.white};
        font-family:'Calibri',sans-serif !important;
        max-width: 1920px;
        height: 100%; 
        margin: 0 auto;
        padding: 0;
        overflow-x: hidden;
        //overflow-y: auto;
         overflow-y: overlay;
        //overflow-y: hidden;
    }
    .gm-style-mot {
        top: 20%!important; 
    }
    ::-webkit-scrollbar {
        margin-right: 0.1em;
        width: 16px;
        background-color: transparent;
    }        
    a, a:visited {
        color: ${(props) => props.theme.black };
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px
    }       
    ::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    :target {
        padding-top: 100px!important;
        margin-top: -100px!important;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 30px;
        font-weight: 700;
    }
    button {
        font-family:'Calibri',"Microsoft Yahei UI Light","微軟雅黑","微软雅黑",sans-serif !important;
    }
    .loading-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .loading-item .loading{
        margin-top: 25px
    }
    .loadEffect {
        width: 50px;
        height: 50px;
        position: relative;
        margin: 0 auto;
    }
    .loadEffect span {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: ${(props) => props.theme.company_blue};
        position: absolute;
        -webkit-animation: load 1.04s ease infinite;
    }
    @-webkit-keyframes load {
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0.4;
        }
    }
    .loadEffect span:nth-child(1) {
        left: 0;
        top: 50%;
        margin-top: -4px;
        -webkit-animation-delay: 0.13s;
    }
    .loadEffect span:nth-child(2) {
        left: 7px;
        top: 7px;
        -webkit-animation-delay: 0.26s;
    }
    .loadEffect span:nth-child(3) {
        left: 50%;
        top: 0;
        margin-left: -4px;
        -webkit-animation-delay: 0.39s;
    }
    .loadEffect span:nth-child(4) {
        top: 7px;
        right: 7px;
        -webkit-animation-delay: 0.52s;
    }
    .loadEffect span:nth-child(5) {
        right: 0;
        top: 50%;
        margin-top: -4px;
        -webkit-animation-delay: 0.65s;
    }
    .loadEffect span:nth-child(6) {
        right: 7px;
        bottom: 7px;
        -webkit-animation-delay: 0.78s;
    }
    .loadEffect span:nth-child(7) {
        bottom: 0;
        left: 50%;
        margin-left: -4px;
        -webkit-animation-delay: 0.91s;
    }
    .loadEffect span:nth-child(8) {
        bottom: 7px;
        left: 7px;
        -webkit-animation-delay: 1.04s;
    }
    body.page {
        text-align: center;
    }
    .icon-dialog .MuiPaper-root {
        width: 90%;
        max-width: 420px;
        height: auto;
    }
    .icon-dialog .MuiPaper-root .title {
        width: 100%;
        padding: 19px 20px;
        box-sizing: border-box;
        font-size: 17px;
        font-weight: 550;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
        text-align: left;
    }
    .icon-dialog .MuiPaper-root .list-content .list {
        text-align: left;
        padding: 18px 20px;
        font-size: 16px;
        border-top: 0.5px solid #F2F2F2;
    }
    .icon-dialog .MuiPaper-root .tag-dialog-content {
        padding: 0px 20px 16px;
        text-align: left;
    }
    .icon-dialog .MuiPaper-root .category-dialog-content {
        padding: 5px 20px 30px 20px;
    }
    .icon-dialog .MuiPaper-root .category-dialog-content .content {
        text-align: left;
        border: 2px solid ${(props) => props.theme.company_blue};
        padding: 8px;
        border-radius: 10px;
        font-size: 14px;
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content {
        padding: 0px 20px 20px 20px;
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content .time-list {
		display: flex;
		flex-direction: column;
		align-content: flex-start;
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content .time-list .day-available-time {
		font-size: 14px;
		text-align: left;
		margin: 3px 0;
		display: flex;
        align-items: flex-start;
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content .time-list .day-available-time div.time {
		display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content .time-list .day-available-time.today {
		color: ${(props) => props.theme.company_blue};
    }
    .icon-dialog .MuiPaper-root .service-hours-dialog-content .time-list .day-available-time .day {
		width: 70px;
    }
    .empty-result {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .empty-result .No{
        height: 50px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .wrapper div.icon-div {
        position: relative;
    }
    .wrapper {
        height: calc(100% - 70px);
    }
    a.visited {
        color: ${(props) => props.theme.black};
    }
    .wrapper .header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: ${(props) => props.theme.white};
        width: 100%;
        height: 56px;
        padding: 9px 20px;
        text-align: center;
        position: fixed;
        z-index: 2;
        justify-content: space-between;
        border-bottom: #F9F9F9 1px solid;
        box-sizing: border-box;
        z-index: 5;
    }
    #root .mobile-footer {
        display: none;
    }    
    // .wrapper .mobile-footer {
    //     display: none;
    // }
    .wrapper .mobile-cart-content {
        display: none;
    }
    .wrapper .mobile-setting-content {
        display: none;
    }
    .wrapper .mobile-merchant-main {
        display: none;
    }
    .wrapper .mobile-item-main {
        display: none;
    }
    #root .mobile-footer span {
        font-size: 25px;
    }
    // .wrapper .mobile-footer span {
    //     font-size: 25px;
    // }
    .wrapper .mobile-shop-header {
        display: none;
    }
    .wrapper .mobile-shop-shopping-plan {
        display: none;
    }
    .wrapper .mobile-order-plan-header{
        display: none;
    }
    .wrapper .mobile-order-detail-header{
        display: none;
    }
    .wrapper .shopping .mobile-nav {
        display: none;
    }
    .wrapper .header .header-item {
        // width: 33%;
        // margin: 0px auto;
    }
    .header-item span.header-item-logo {
        font-size: 37px;
    }
    .header-item .header-username {
        font-size: 15px;
        margin: 0 0 0 3em;
        padding-top: 0.5em; 
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 400px;
        //word-wrap: break-word;
        //word-break: break-all;
        //white-space: nowrap;
    }
    .wrapper .header p, .wrapper .header span {
        color: ${(props) => props.theme.company_blue}
    }
    .wrapper .header span.text-title-color {
        color: ${(props) => props.theme.black};
    }
    .wrapper .mobile-content-div {
        top: 0;
        display: none;
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: ${(props) => props.theme.white};
        z-index: 10;
    }
    .wrapper .home-content {
        height: 100vh !important;
    }
    .header-item ul {
        width: 370px;
        height: 50px;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .header-item ul li {
        position: relative;
        line-height: 5px;
        display: inline-block;
        margin-left: 0;
        width: 43px;
    }
    .icon_pattern {
        margin-right: 12px;
        font-size: 15.5px;
        font-weight: bold;
    }
    .icon_pattern_2 {
        font-size: 15.5px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 300px;
        text-align: left;
    }
    .icon_pattern_3 {
        font-size: 15.5px;
    }
    .text-main-color {
        color: ${(props) => props.theme.company_blue} !important;
    }
    .text-darksub-color {
        color: #878787 !important;
    }
    .text-title-color {
        color: ${(props) => props.theme.black};
    }
    .text-red-color {
        color: ${(props) => props.theme.red};
    }
    .text-md-left {
        text-align: left!important;
        display: flex;
    }
    .text-md-center {
        text-align: center!important;
    }
    .text-md-center > p {
        display: flex;
        flex-direction: row;
        margin: 0;
        height: 35px;
        align-content: center;
        align-items: center;
    }
    .text-md-right {
        text-align: right!important;
    }
    .wrapper .shop-loading {
        padding-top: 120px;
    }
    .wrapper .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        height: 100%;
        line-height: 100%;
        text-align: center;
        box-sizing: border-box;
        // overflow-x: hidden;
    }
    .wrapper .padding_1 {
        padding: 55px 30px;
    }
    .width-big-1 {
        width: calc(100vw - 300px);
        max-width: 1600px;
    }
    .width-small-1 {
        width: 250px;
    }
    .width-big-2 {
        width: 78%;
        left: 0; 
        padding-top: 55px;
    }
    
    .wrapper .content .content-big.width-big-2 {
        max-width: calc(100vw - 327px);
    }
    .width-small-2 {
        width: 22%;
        height: 100%;
        position: fixed;
        right: 0;
        padding-top: 55px;
    }
    .wrapper .shareLink{
        width: auto;
        align-items: center;
        display: flex;
        margin: 0% 3.5% 0% 0.5%;
        //text-align: left;
        svg{
            width: 100%;
            height: 20px;
        }
    }
    .wrapper .content .content-small {
        height: 100%;
        margin: 0px auto;
    }
    .wrapper .content .content-small.width-small-2 {
        min-width: 310px;
        box-sizing: border-box;
        right: 16px;
        z-index: 3;
    }
    .wrapper .content .content-small .content_box_1 {
        margin: 0 5%;
        border-radius: 3px;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
    }
    .wrapper .content .content-small .content_box_1 input {
        width: 80%;
        padding: 15px 0;
        outline-style: none;
        border: 0;
    }
    .wrapper .content .content-small .tab {
        margin: 0 5%;
    }
    .wrapper .content .content-small .tab span {
        padding: 15px 12.5px;
        font-weight: 550;
    }
    .wrapper .content .content-small .tab span.active {
        color: ${(props) => props.theme.company_blue};
        border-bottom: 2.5px solid ${(props) => props.theme.company_blue};
    }
    .wrapper .content .content-small hr {
        width: 90%;
        border-top: 1px solid #F7F7F7;
        margin: 20px 5%;
    }
    .p_margin {
        margin-top: -10px;
    }
    .wrapper .content .content-small .content_box_2 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5%;

    }
    .wrapper .content .content-small .content_box_2 .box_items_1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1% 1%;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border-radius: 5%;
        height: 50px;
        width: 48%;
        box-sizing: border-box;
        padding: 0px 5px;
    }
    .wrapper .content .content-small .content_box_2 .box_items_1 {
        color: ${(props) => props.theme.white};
    }
    .wrapper .content .content-small .content_box_2 .box_items_2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1% 1%;
        background-color: #F8F9FB;
        border-radius: 5%;
        height: 50px;
        color: ${(props) => props.theme.company_blue};
        width: 48%;
        box-sizing: border-box;
        padding: 0px 5px;
    }
    .wrapper .content .content-small .content_box_3 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5%;
    }
    .wrapper .content .content-small .content_box_3 .range {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .wrapper .content .content-small .content_box_3 input {
        padding: 12px;
        text-align: center;
        border: 0px;
        border-bottom: 1px solid #9A9A9A;
        width: 117px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    .wrapper .content .content-small .content_box_3 button.add {
        box-sizing: border-box;
        padding: 5px 15px;
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        border-radius: 50px;
        border: 1px solid #F4F4F4;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .wrapper .content .content-small .content_box_3 button.item {
        box-sizing: border-box;
        padding: 5px 10px;
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        border-radius: 10px;
        border: 2px solid ${(props) => props.theme.company_blue};
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .wrapper .content .content-small .content_box_3 button.item i {
        color: #848484;
    }
    .wrapper .content .content-big .content_box_1 {
        text-align: left;
        margin: 1% 0% 2% 10px;
    }
    .wrapper .content .content-big .content_box_1 h2 {
        font-size: 20px;
    }
    .wrapper .content .content-big .content_box_2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // margin: 1% 1% 2% 2%;    
    }
    .wrapper .content .content-big .content_box_2 .box_items {
        background-color: ${(props) => props.theme.white};
        width: calc((100% - 80px) / 4);
        min-width: 200px;
        margin: 0 10px;
        margin-bottom: 20px;
    }
    .wrapper .content .content-big .content_box_2 .box_items > div.normal,
    .wrapper .content .content-big .content_box_2 .box_items > div.photo {
        width: 100%;
        height: calc(((((100vw - 337px) * 0.97) - 80px) / 64) * 9);
        max-width: 368px;
        max-height: 207px;
    }
    .wrapper .content .content-big .content_box_2 .box_items .photo {
        width: 100%;
        border-radius: 3px;
        position: relative;
    }
    .wrapper .content .content-big .content_box_2 .box_items .photo .photo_msg {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        color: ${(props) => props.theme.white};
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
    }
    .wrapper .content .content-big .content_box_2 .box_items .photo .photo_msg p {
        text-align: center;
        width: 100%;
    }
    .wrapper .content .content-big .content_box_2 .box_items img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
    .wrapper .content .content-big .content_box_2 .box_items h4 {
        text-align: left;
        margin: 10px 0px 5px 0px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .content .content-big .content_box_2 .box_items .box_detail {
        display: flex;
        justify-content: center;
        color: #ABABAB;
    }
    .wrapper .content .content-big .content_box_2 .box_items .box_detail p.desc {
        width: 70%;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .content .content-big .content_box_2 .box_items .box_detail p.distance {
        width: 30%;
        text-align: right;
        margin: 0;
        font-size: 14px;
    }
    .wrapper .content .content-big .content_box_2 .box_items .merchant-desc {
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        height: 55px;
        width: 100%;
    }
    .wrapper .content .content-big .content_box_2 .box_items .merchant-desc img {
        max-width: 55px;
        max-height: 55px;
    }
    .wrapper .content .content-big .content_box_2 .box_items .merchant-desc .data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 55px);
        box-sizing: border-box;
        padding-left: 10px;
    }
    .wrapper .content .content-big .content_box_2 .box_items .merchant-desc .data .merchant-name {
        font-size: 18px;
        font-weight: 550;
        text-align: left;
        width: 100%;
    }
    .wrapper .content .content-big .content_box_2 .box_items .merchant-desc .data .desc {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .wrapper .content .content-big .content_box_2 .item {
        display: flex;
        flex-direction: row;
        position: relative;
        background-color: ${(props) => props.theme.white};
        width: calc((100% - 80px) / 4);
        min-width: 200px;
        margin: 0 10px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .wrapper .content .content-big .content_box_2 .item > a {
        display: flex;
        flex-direction: row;
        position: relative;
        background-color: ${(props) => props.theme.white};
        width: 100%;
    }
    .wrapper .content .content-big .content_box_2 .item .img-detail {
        width: 90px;
        position: relative;
    }
    .wrapper .content .content-big .content_box_2 .item .img-detail img {
        height: 90px;
        width: 90px;
        border-radius: 5px;
        object-fit: cover;
        font-size: 10.5px;
    }
    .wrapper .content .content-big .content_box_2 .item .img-msg {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 5px 1px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        bottom: 0;
        font-size: 8px;
        width: 90px;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 10px;
        width: calc(100% - 90px);
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-title {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 550;
        text-align: left;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-tag {
        overflow: hidden;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        text-align: left;
        margin-top: 5px;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-tag span {
        font-size: 13px;
        background-color: #F8FAFB;
        border-radius: 10px;
        margin: 1.5px;
        line-height: 2em;
        padding: 5px;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price .price-int {
        font-weight: 550;
        font-size: 20px
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price .shop-info span {
        font-size: 13px;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price .shop-info svg {
        font-size: 10px;
        margin-left: 5px;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price .shop-info .shop {
        color: #929292;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price .shop-info {
        display: flex;
        justify-content: space-between;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price > div {
        display: flex;
        align-items: baseline;
    }
    .wrapper .content .content-big .content_box_2 .item .item-news .item-price > div > .item-delete {
        text-decoration: line-through;
        color: #B8B8B8;
    }
    .wrapper .content .content-big .content_box_2 .item span.item-add {
        font-size: 32px;
        margin: 0px 10px;
        padding: 0;
        color: ${(props) => props.theme.company_blue};
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
    .wrapper .content .content-small .content_box_3 .item svg[data-icon="times"] {
        color: ${(props) => props.theme.dark_grey}
    }
    .wrapper .content .content-big .allImgBox {
        width: 100%;
        height: 243px;
        position: relative;
        display: inline-block;
    }
    .wrapper .content .content-big .allImgBox .topImgBox {
        height: 243px;
        background-size: cover;
        background-position: center center;
        opacity: 0.5;
        filter: alpha(opacity=50);
        background-color: rgb(255, 255, 255);
        background-blend-mode: multiply;
    }
    .wrapper .content .content-big .allImgBox .mainImg {
        height: 243px;
        width: 100%;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
    }
    .wrapper .content .content-big .allImgBox .mainImg img {
        height: 243px;
        width: calc(243px * 16 /9);
    }
    .wrapper .content .content-big .allImgBox .mainImg .mainImg_msg {
        display: flex;
        align-items: center;
        height: 243px;
        width: 100%;
        border-radius: 3px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: ${(props) => props.theme.white};
        font-size: 20px;
        font-weight: bold;
    }
    .wrapper .content .content-big .allImgBox .mainImg .mainImg_msg p {
        text-align: center;
        width: 100%;
    }
    .wrapper #shop-content .content-big .allImgBox .mainImg .mainImg_msg p {
        font-size: 18px;
    }
    .wrapper .content .content-big .businessNews {
        width: 100%;
        display: flex;
    }
    .wrapper .content .content-big #news-large {
        padding: 5px;
    }
    .wrapper .content .content-big .businessNews .shop-title {
        font-size: 17px;
        font-weight: 550;
        border-bottom: 8px solid  ${(props) => props.theme.white};
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-all;
    }
    .wrapper .content .content-big .businessNews .shop-desc {
        color: #878787;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 15;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        word-wrap: break-word;
        //word-break: break-all;
        font-size: 15px;
        margin-bottom: 8px;
    }
    .MuiPaper-root hr{
        color: #FCFCFC;
        border-color: #FCFCFC;
        background-color: #FCFCFC;
    }    
    .MuiPaper-root .close-dialog {
        position: absolute;
        right: 0;
        top: 0;
        color: #2594A2;
    }    
    .MuiPaper-root .category-nav{
        text-align: left;
        padding-top: 10px;
    }    
    .MuiPaper-root .category-nav div {
        margin-top: 10px;
    }    
    .MuiPaper-root .category-nav div span{
        color: ${(props) => props.theme.company_blue };
        font-size: 14px;        
    } 
    .MuiPaper-root .category-nav .category-nav-title{
        font-weight: bold;
        padding: 15px;
    } 
    .MuiPaper-root .category-nav .category-nav-content{
        font-weight: 500;
        display: flex;
        justify-content: space-between;      
        padding: 0 15px;  
    }
    .MuiDialogTitle-root > span{
        color: ${(props) => props.theme.company_blue};  
        font-weight: bold;
    }
    .MuiDialogTitle-root > hr{
        color: #EFEFEF;
        border-color: #EFEFEF;
        background-color: #EFEFEF;
        border-style: solid;
    }
    .MuiDialogContent-root .tag-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .MuiDialogContent-root.category-dialog-content::-webkit-scrollbar {
        margin-right: 0.1em;
        width: 16px;
        background-color: transparent;
    }        
    .MuiDialogContent-root.category-dialog-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px
    }       
    .MuiDialogContent-root.category-dialog-content::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    .MuiDialogContent-root .category-content .category-item {
        margin: 3% 0;
        text-align: left;
        position: relative;
    }
    .MuiDialogContent-root .category-content .category-item .category-title {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .MuiDialogContent-root .category-content .category-item input{
        margin-right: 2.5%;
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;

    }
    .MuiDialogContent-root .category-content .category-item .checkbox-custom{
        position: absolute;
        top: -1;
        left: 0;
        height: 14px;
        width: 14px;
        border: solid #BAC3C3 2px;
        border-radius: 2px;
        background-color: ${(props) => props.theme.white};
    }
    .MuiDialogContent-root .category-content .category-item input:indeterminate ~ .indeterminate-custom{
        position: absolute;
        top: -1;
        left: 0;
        height: 14px;
        width: 14px;
        border: solid #BAC3C3 2px;
        border-radius: 2px;
        background-color: ${(props) => props.theme.white};
    }
    .MuiDialogContent-root .category-content .category-item input:checked ~ .checkbox-custom,
    .MuiDialogContent-root .category-content .category-item input:indeterminate ~ .indeterminate-custom{
        background-color: ${(props) => props.theme.company_blue};
        border-color : ${(props) => props.theme.company_blue}
    }    
    .MuiDialogContent-root .category-content .category-item .checkbox-custom:after,
    .MuiDialogContent-root .category-content .category-item .indeterminate-custom:after
    {
        content: "";
        position: absolute;
        display: none;
    }   
    .MuiDialogContent-root .category-content .category-item input:checked ~ .checkbox-custom:after,
    .MuiDialogContent-root .category-content .category-item input:indeterminate ~ .indeterminate-custom:after{
        display: block;
    }
    .MuiDialogContent-root .category-content .category-item .checkbox-custom:after{
        color: ${(props) => props.theme.white};
        left: 4px;
        top: 0px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-radius: 1px;
        border-width: 0 2px 2px 0;  
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .MuiDialogContent-root .category-content .category-item .indeterminate-custom:after{
        color: ${(props) => props.theme.white};
        left: 0px;
        top: 6px;
        width: 12px;
        border: solid white;
        border-radius: 1px;
        border-width: 0 2px 2px 0;
    }
    .MuiDialogContent-root .category-content .category-item span{
        color: #262626;
    }
    .MuiDialogContent-root .category-content .category-item .category-arrow {
        position: absolute;
        right: 0px;
        top: 3.5px;
    }
    .MuiDialogContent-root .category-content .category-item .category-arrow svg {
        font-size: 1rem;
    }
    .category-dialog .MuiDialogActions-root{
        padding: 10px;
    }
    .payment-dialog .MuiDialogActions-root{
        padding: 10px;
    }
    .MuiDialogActions-root button {
        font-weight: 550;
        font-size: 16px;
        border-radius: 5px;
        margin: 0px 10px;
        padding: 8px 0px;
    }
    
    .MuiDialogContent-root .map-content .content_box_1 {
        margin: 0 5%;
        border-radius: 3px;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
    }
    .MuiDialogContent-root .map-content .content_box_1 input {
        width: 95%;
        padding: 15px 0;
        font-weight: 545;
        font-size: 15px;
        outline-style: none;
        border: 0;
    }
    .MuiDialogActions-root button.btn_ok {
        width: 100%;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        margin: 10px 0 7px 0;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    .MuiDialogActions-root button.btn_location_confirm {
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        margin-top: 10px;
        margin-bottom: 10px;
        width: 95%;
        border-width: 0px;
    }
    .MuiDialogActions-root button.btn_category_clear {
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        width: 30%;
        border: 0.5px solid #DFDFDF;
    }
    .MuiDialogActions-root button.btn_category_apply {
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        width: 60%;
        border-width: 0px;
    }
    .MuiDialogActions-root button.btn_payment_clear {
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        width: 30%;
        border: 0.5px solid #DFDFDF;
    }
    .MuiDialogActions-root button.btn_payment_apply {
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        width: 60%;
        border-width: 0px;
    }
    .MuiDialogContent-root .category-content .category-item input:indeterminate {
        background-color: ${(props) => props.theme.company_blue}
        color: ${(props) => props.theme.white}
    }
    .MuiDialogContent-root .tag-content .tag-item {
        border-radius: 5px;
        background-color: #F8F9FB;
        color: #2D2D2E;
        padding: 8px;
        margin: 2% 1%;
        font-size: 14px;
    }
    .MuiDialogContent-root .tag-content .tag-item.selected {
        background-color: ${(props) => props.theme.company_blue };
        color: ${(props) => props.theme.white };
    }
    .MuiDialogContent-root .payment-content .payment-item {
        margin: 3% 0;
        text-align: left;
        position: relative;
    }
    .MuiDialogContent-root .payment-content .payment-item .payment-title {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .MuiDialogContent-root .payment-content .payment-item input{
        margin-right: 2.5%;
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;

    }
    .MuiDialogContent-root .payment-content .payment-item .checkbox-custom{
        position: absolute;
        top: -1;
        left: 0;
        height: 14px;
        width: 14px;
        border: solid #BAC3C3 2px;
        border-radius: 2px;
        background-color: ${(props) => props.theme.white};
    }
    .MuiDialogContent-root .payment-content .payment-item input:checked ~ .checkbox-custom {
        background-color: ${(props) => props.theme.company_blue};
        border-color : ${(props) => props.theme.company_blue}
    }    
    .MuiDialogContent-root .payment-content .payment-item .checkbox-custom:after {
        content: "";
        position: absolute;
        display: none;
    }   
    .MuiDialogContent-root .payment-content .payment-item input:checked ~ .checkbox-custom:after {
        display: block;
    }
    .MuiDialogContent-root .payment-content .payment-item .checkbox-custom:after{
        color: ${(props) => props.theme.white};
        left: 4px;
        top: 0px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-radius: 1px;
        border-width: 0 2px 2px 0;  
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .payment-dialog .payment-nav {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        align-items: flex-start;
    }
    .payment-dialog .payment-nav .recent-block {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .payment-dialog .payment-nav .recent-block .recent-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .payment-dialog .payment-nav .recent-block .recent-nav > span {
        font-weight: 550;
        font-size: 18px;
    }
    .payment-dialog .payment-nav .recent-block .recent-nav .manage,
    .payment-dialog .payment-nav .recent-block .recent-nav .done {
        margin-left: 10px;
    }
    .payment-dialog .payment-nav .recent-block .keyword-item {
        padding: 8px;
        margin-right: 15px;
        margin-top: 18px;
        background-color: #F8FAFB;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .payment-dialog .payment-nav .recent-block .icon.cross {
        margin-left: 10px;
    }
    .payment-dialog .payment-nav .recent-block .recent-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

    }    
    .payment-dialog .payment-nav .recent-block .recent-content .keyword-item {
        padding: 8px;
        margin: 5px 10px 5px 0;
        background-color: #F8FAFB;
        border-radius: 6px;
    }
    .payment-dialog .payment-nav .payment-content-title {
        font-weight: 550;
        font-size: 18px;
        margin-top: 8px;
    }
    
    .MuiDialogContent-root.payment-dialog-content::-webkit-scrollbar {
        margin-right: 0.1em;
        width: 16px;
        background-color: transparent;
    }        
    .MuiDialogContent-root.payment-dialog-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px
    }       
    .MuiDialogContent-root.payment-dialog-content::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    .wrapper .merchant-content .businessNews {
        width: 100%;
        display: flex;
    }
    .wrapper .merchant-content .businessNews .newsTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wrapper .merchant-content .businessNews .merchant-title {
        font-size: 17px;
        font-weight: 550;
        border-bottom: 8px solid  ${(props) => props.theme.white};
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-all;
    }
    .wrapper .merchant-content .businessNews .merchant-desc {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 15;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        word-wrap: break-word;
        //word-break: break-all;
        font-size: 15px;
        margin-bottom: 8px;
    }
    .businessNews .circleImg {
        height: 100%;
        width: 12%;
        text-align: right;
        margin: auto;
    }
    .businessNews .circleImg img {
        width: 55%;
        margin: 7.5% 5%;
        border-radius: 50%;
        border: 1px solid #CFCFCF
    }
    .businessNews .newsTitle {
        width: 100%;
        text-align: left;
        padding: 1% 2%;    
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 100vw;
        box-sizing: border-box;
        position: relative;
    }
    .wrapper .content .content-big hr {
        width: 100%;
        //max-width: calc(100vw - 16px)!important;
        border: 1px solid #F7F7F7;
        margin: 0;
    }
    #tab-demo {
        width: 100%;
        height: auto;
        display: inline-block;
    }
    #tab-demo > ul {
        display: block;
        position: sticky;
        margin: 0;
        top: 55px;
        list-style: none;
        background-color: ${(props) => props.theme.white};
        padding-left: 0;
		z-index: 2;
    }
    #tab-demo > ul > li {
        display: inline-block;
        vertical-align: top;
        margin: 0 -1px -1px 0;
        height: 50px;
        width: calc(100%/4);
        line-height: 50px;
        background: ${(props) => props.theme.white};
        padding: 0 5px;
        list-style: none;
        box-sizing: border-box;
        font-size: 15px;
    }
    #tab-demo > #tab-large > li {
        width: 100% !important;
    }
    #tab-demo > #tab-title-two > li {
        width: calc(100%/2) !important;
    }
    #tab-demo > #tab-title-three > li {
        width: calc(100%/3) !important;
    }
    #tab-demo > #tab-title-five > li {
        width: calc(100%/5) !important;
    }
    #tab-demo > ul > li a {
        color: ${(props) => props.theme.black};
        text-decoration: none;
        font-size: 24px;
    }
    #tab-demo > ul > li.active {
        border-bottom: 3px solid ${(props) => props.theme.company_blue};
        background: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        font-weight: bold;
    }
    #tab-demo > ul > li.active a {
        background: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    #tab-demo > .tab-inner {
        clear: both;
        color: ${(props) => props.theme.black};
    }
    .tab-inner {
        display: flex;
        justify-content: center;
        background-color: #F8F9FB;
        padding: 25px;
        min-height: calc(100vh - 190px);
    }
    .tab-demo .tab-inner {
        min-height: calc(100vh - 130px) !important;
    }
    .tab-inner .tab-box {
        display: flex;
        justify-content: center;
        width: 95%;
        background-color: ${(props) => props.theme.white};
        min-width: 850px;
        margin: 0px auto;
    }
    .tab-inner .tab-box .box-left {
        width: 15%;
        height: 100%;
        max-width: 180px;
    }
    .tab-inner .tab-box .box-left ul { 
        margin: 0;
        padding: 0;
        position: sticky;
        top: 115px;
        height: calc(100vh - 106px);
        overflow: scroll;
    }
    .tab-inner .tab-box .box-left li {
        text-align: left;
        background: ${(props) => props.theme.white};
        margin: 9px 0 0 0;
        padding: 15px 15px 10px 15px;
        list-style: none;
        box-sizing: border-box;
    }
    .tab-inner .tab-box .box-left li > a {
        color: #878787;
        font-size: 15px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 22px;
    }
    .tab-inner .tab-box .box-left > ul > li.active {
        background-color : ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    .tab-inner .tab-box .box-left > ul > li.active a {
        background-color : ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    .tab-inner .tab-box .box-right {
        width: 100%;
        height: 100%;
        // min-width: 1085px;
    }
    .tab-inner .tab-box .box-right .box-inner {
        width: 99%;
        margin-bottom: 25px;
        box-sizing: border-box;
        // min-height: 90vh;
    }
    .tab-inner .tab-box .box-right .box-inner > p {
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        margin: 0px 0 10px 0;
        padding: 26px 10px 13px 3px;
        color: ${(props) => props.theme.company_blue};
        background-color: ${(props) => props.theme.white};
        position: sticky;
        top: 103px;
        z-index: 1;
        word-wrap: break-word;
        word-break: break-all;   
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        flex-direction: row;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item {
        display: flex;
        flex-direction: row;
        width: calc((100% - 30px) / 3);
        height: auto;
        margin: 3px;
        background-color: ${(props) => props.theme.white};
        //box-shadow: 1px 1px 6px #ABABAB;
        border: 1px solid #F5F5F7;
        min-width: 250px;
        min-height: 90px;
        position: relative;
        overflow-x: scroll;
        cursor: pointer;
        overflow-y: hidden;

        ::-webkit-scrollbar {
            height: 15px;
          }
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item a {
        display: flex;
        flex-direction: row;
        position: relative;
        //width: 100%;
    }
    .item-opacity {
        opacity:0.55;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item .img-detail {
        width: 31%;
        margin: 12px 10px 0px 10px;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item .img-detail img {
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
        max-width: 80px;
        max-height: 80px;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item .img-msg {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 3px 0px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        bottom: 0;
        height: auto;
        width: 80px;
        //min-height: 20px;
        max-width: 80px;
        font-size: 10.5px;
        line-height: 115%;
        align-items: center;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item .item-news {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 70%;
        margin: 10px 0 0 0;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items .item .item-add {
        font-size: 32px;
        margin: 0px 10px;
        padding: 0;
        color: ${(props) => props.theme.company_blue}; 
        position: absolute;
        bottom: 10px;
        right: 0px;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        margin: 10px;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item {
        display: flex;
        flex-direction: row;
        width: calc(100% - 20px);
        height: 50%;
        margin: 3px;
        background-color: ${(props) => props.theme.white};
        //box-shadow: 1px 1px 6px #ABABAB;
        border: 1px solid #F5F5F7;
        min-width: 250px;
        min-height: 90px;
        position: relative;
        overflow-x: scroll;
        cursor: pointer;
        overflow-y: hidden;

        ::-webkit-scrollbar {
            height: 15px;
          }
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item a {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
    }
    .item-opacity {
        opacity:0.55;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item .img-detail {
        margin: 10px 10px 0px 10px;
        position: relative;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item .img-detail img {
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
        max-width: 250px;
        max-height: 250px;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item .img-msg {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 3px 0px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        bottom: 0;
        height: auto;
        width: 250px;
        //min-height: 20px;
        max-width: 250px;
        font-size: 14px;
        line-height: 115%;
        align-items: center;
    }
    .tab-inner .tab-box .box-right .box-inner .box-items-large .item .item-news {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        width: 100%;
        margin: 10px 6px 0px;
    }
    .tab-inner .vertical {
        display: flex;
        flex-direction: column;
        width: 760px;
        margin: 0 auto;
        padding-bottom: 60px;
    }
    .tab-inner .vertical .no-post *{
        color: #605E5E;
    }
    .tab-inner .no-product *{
        color: #605E5E;
    }
    .tab-inner .vertical .no-shop *{
        color: #605E5E;
    }
    .tab-inner .vertical .text-border {
        background-color: ${(props) => props.theme.white};
        text-align: left;
        padding: 5px 30px ;
        //margin-top: 5px;
        //margin-bottom: 5px;
        word-wrap: break-word;
        //word-break: break-all;
        line-height: 1.2;
        font-size: 15.5px;
        width: 700px;
    }
    .tab-inner .vertical .text-border br{
        display: block;
        content: " ";
        margin: 10px 0;
    }
    .tab-inner .vertical .image-border {
        background-color: ${(props) => props.theme.white};
        text-align: left;
        padding: 0px 30px ;
        //margin-top: 5px;
        //margin-bottom: 5px;
        word-wrap: break-word;
        //word-break: break-all;
        line-height: 1.2;
        font-size: 15.5px;
        width: 700px;
    }
    .tab-inner .vertical .text-border blockquote {
        padding: 0.8rem 1.5rem;
        border-left: 4px solid #D3D3D3;
    }
    .tab-inner .vertical > div {
        position: relative;
        width: 760px;
    }
    .tab-inner .vertical .img-border {
        max-width: 700px; 
        width: 700px;
        height: auto;
        margin: 0 auto;
        border-left: 30px solid ${(props) => props.theme.white};
        border-right: 30px solid ${(props) => props.theme.white};
    }
    #Post .vertical img:first-child {
        border-top: 30px solid ${(props) => props.theme.white};
    }
    #Post .vertical img:last-child {
        border-bottom: 30px solid ${(props) => props.theme.white};
    }
    .tab-inner .vertical .about-box {
        background-color: ${(props) => props.theme.white};
        padding: 15px 15px 10px 15px;
        box-sizing: border-box;
        width: 760px;
        max-width: 760px;
        margin: 0px auto;
        //overflow-x: scroll;

        ::-webkit-scrollbar {
            height: 15px;
          }
    }
    .tab-inner .vertical .about-item {
        width: 100%;
        display: flex;
        text-align: left;
        //width: 100%;
        margin: 4px 0;
        padding: 10px;
        box-sizing: border-box;
        font-size: 15px;
        //white-space: nowrap;
        //max-width: 10px;
    }
    .tab-inner .vertical .about-item label {
        text-overflow: ellipsis;
        display: -webkit-box;
        //-webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-word;
    }
    .tab-inner .vertical .about-item span {
        font-size: 18px;
        //width: 22px;
        height: 16px;
        color: #939393;
        margin-right: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
    .tab-inner .vertical .social-media {
        display: flex;
        margin-bottom: 18px;
        flex-wrap: wrap;
        padding-left: 10px;
        padding-top: 10px;
    }
    .tab-inner .vertical .social-media img {
        width: 30px;
        height: 30px;
        margin-right: 14px;
        margin-top: 3px;
        margin-bottom: 4px;
    }
    .tab-inner .vertical .item-tag {
        text-align: left;
        margin-bottom: 19px;
        margin-left: 6px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-word;
    }
    .tab-inner .vertical .item-tag span {
        background-color: #F7F9FB;
        border-radius: 10px;
        margin: 0 10.5px 0 0;
        line-height: 2.8em;
        padding: 6px;
        font-size: 12px;
    }
    .tab-inner .vertical .item-award {
        text-align: left;
        margin-bottom: 15px;
        margin-left: 6px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-word;
    }
    .tab-inner .vertical .item-award span {
        background-color: #FFE6D5;
        border-radius: 10px;
        margin: 0 10.5px 0 0;
        line-height: 2.8em;
        padding: 6px;
        font-size: 12px;
    }
    .tab-inner hr {
        margin: 28px 0 0 0 !important;
    }
    .tab-inner .vertical .social-media-msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 6px 5px 6px;
        font-size: 15px;
    }
    .tab-inner .vertical .social-media-msg h3 {
        width: 20%;
        text-align: left;
        font-size: 15px;
    }
    .tab-inner .vertical .social-media-msg .landmark {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin: 8px 0 15px 0;
    }
    .tab-inner .vertical .social-media-msg .landmark label {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .tab-inner .vertical .social-media-image {
        display: flex;
        align-items: center;
        margin: 8px 6px 5px 6px;
        font-size: 15px;
    }
    .tab-inner .vertical .social-media-image-hr hr {
        margin: 3px 0 0 0 !important;
    }
    .tab-inner .vertical .social-media-image h3 {
        text-align: left;
        font-size: 15px;
    }
    .tab-inner .vertical .images-box {
        max-width: 760px;
        overflow-y: hidden;
        white-space: nowrap;
        text-align: left;
        margin: 8px 6px 16px 6px;
        ::-webkit-scrollbar {
            height: 15px;
        }
    }
    .tab-inner .vertical .images-box img {
        max-width: 80%;
        padding-right: 1.3px;
        padding-left: 1.3px;
        cursor: pointer;
    }
    .tab-inner .vertical .images-box img:first-child {
        padding-right: 1.3px;
        padding-left: 0px;
    }
    .tab-inner .vertical .images-box img:last-child {
        padding-right: 0px;
        padding-left: 1.3px;
    }
    .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .about-item {
        display: flex;
        align-items: center;
        word-break: break-all;
    }
    .item-news > .item-title {
        margin: 2px 0 4px 0;
        padding: 0 0 4px 0;
        text-align: left;
        //overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 15px;
        width: 90%;
        max-width: 250px;
        white-space: nowrap;
        //line-height: 100%;
    }
    .box-items-large .item-news > .item-title {
        font-size: 16.5px !important;
        -webkit-line-clamp: unset !important;
        line-height: 1.5 !important;
        width: 100% !important;
        max-width: 100% !important;
        white-space: break-spaces !important;
    }
    .box-items-large .item-news > .item-price {
        white-space: break-spaces !important;
    }
    .item-news > h4, 
    .item-news > h5, 
    .item-news > p,
    .item-price > div > h4,
    .item-price > div > h5{
        margin: 10px 0 0 0;
        padding: 0;
        text-align: left;
    }
    .item-price > div > h5{
        font-size: 12.5px;
    }
    .box-items-large .item-category {
        color: #878787!important;
        font-size: 14.5px!important;
        line-height: 1.2 !important;
        text-align: left;
        margin-bottom: 13px;
    }
    .box-items-large .item-price > div > h5{
        font-size: 14.5px !important;
    }
    .box-items-large .item-price > div > h4 {
        font-size: 18px !important;
    }
    .item-news .item-tag {
        //overflow: hidden;
        //-webkit-line-clamp: 1;
        white-space: nowrap;
        // max-width: 125px;
        text-align: left;
        width: 90%;
        max-width: 250px;
    }
    .item-news .item-tag span {
        background-color: #F8F9FB;
        border-radius: 10px;
        margin: 0 4px 0 0;
        line-height: 1.5em;
        padding: 5px;
        font-size: 10.5px;
    }
    .box-items-large .item-news .item-tag {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 13px !important;
    }
    .box-items-large .item-news .item-tag span {
        font-size: 11.5px !important;
        line-height: 2.5em !important;
        white-space: break-spaces !important;
    }
    .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news .item-price {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        min-width: 90%;
        max-width: 90%;
        margin-top: 7px;
        //overflow: hidden;
        white-space: nowrap;
    }
    .item-price {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between; 
        //overflow: hidden;
    }
    .item-price > div {
        display: flex;
        align-items: baseline;
    }
    .item-price > div > .item-delete {
        text-decoration: line-through;
        color: #B8B8B8;
    }
    .item-dialog .MuiDialogContent-root {
        position: relative;
    }
    .item-dialog .item-content {
        display: flex;
        height: 100%;
    }
    .item-dialog .item-content .item-img {
        position: relative;
        width: 50%;
        height: 100%;
    }
    .item-dialog .item-content .item-img .img-data {
        position: relative;
        width: 90%;
        height: 100%;
        margin: 0 5px;
    }
    .item-dialog .item-content .item-img .item-detail-background {
        background-size: cover;
        position: relative;
        width: 100%;
        // height: 61%;
        min-height: 60%;
    }
    .item-dialog .item-content .item-img .item-detail {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        border: 1px solid #F5F5F7;
        padding: 20px;
    }
    .item-dialog .item-content .item-img .item-detail * {
        font-size: 14px;
    }
    .item-dialog .item-content .item-img .item-detail div {
        margin-bottom: 10px;
    }
    .item-dialog .item-content .item-img .item-detail sup {
        font-size: 8px;
    }
    .item-dialog .item-content .item-img .item-detail .item-number,
    .item-dialog .item-content .item-img .item-detail .item-date,
    .item-dialog .item-content .item-img .item-detail .other-detail {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }
    .item-dialog .item-content .item-img img.show,
    .item-dialog .item-content .item-img .item-detail-background.show {
        display: block;
    }
    .item-dialog .item-content .item-img img {
        width: 100%;
        min-height: 60%;
        max-height: 65%;
    }
    .item-dialog .item-content .item-img .view-item {
        width: 100%;
        border: 2px #F8F9FA solid;
        background-color: ${(props) => props.theme.white };
        border-radius: 5px;
        color: ${(props) => props.theme.company_blue };
        margin: 10px auto;
        padding: 15px;
        font-weight: 550;
    }
    .item-dialog .item-content .item-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: 5px;
    }
    .item-dialog .item-content .item-info hr{
        margin: 10px 0;
        width: 100%;
    }
    .item-dialog .item-content .item-info .item-price{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 10px;
    }
    .item-dialog .item-content .item-info .item-price .item-currency {
        color:  ${(props) => props.theme.company_blue };
        font-size: 14px;
    }
    .item-dialog .item-content .item-info .item-price .price {
        margin: 0 8px;
        color:  ${(props) => props.theme.company_blue };
        font-size: 20px;
        font-weight: 550;
    }
    .item-dialog .item-content .item-info .item-price .delete-price {
        font-size: 14px;
        color:  #C1C1C1;
        text-decoration: line-through;
    }
    .item-dialog .item-content .item-info .item-stock {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .item-dialog .item-content .item-info .item-stock.normal {
        color:  ${(props) => props.theme.company_blue };
    }
    .item-dialog .item-content .item-info .item-stock.warning {
        color:  #FAA970;
    }
    .item-dialog .item-content .item-info .item-stock.danger {
        color:  #F54039;
    }
    .item-dialog .item-content .item-info .item-data {
        width: 100%;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: left;
        font-weight: 550;
    }
    .item-dialog .item-content .item-info .show-detail,
    .item-dialog .item-content .item-info .hide-detail {
        text-decoration: underline;
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 550;
    }
    .item-dialog .item-content .item-info .option-title {
        font-weight: 550;
        font-size: 14px;
        margin: 10px 0;
    }
    .item-dialog .item-content .item-info .option-title-info {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    .item-dialog .item-content .item-info .option-title-info .option-title {
        margin: 0;
    }
    .item-dialog .item-content .item-info .option-title-info .msg {
        font-size: 12px;
        color: #858585;
    }
    .item-dialog .item-content .item-info .flex-tag {
        display: flex;
        flex-wrap: wrap;
    }
    .item-dialog .item-content .item-info .option-tag {
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #F8F9FB;
        font-size: 14px;
        font-weight: 550;
        margin: 5px 10px 5px 0;
    }
    .item-dialog .item-content .item-info .option-tag[data-type="normal"] {
        border: 0;
    }
    .item-dialog .item-content .item-info .option-tag[data-type="selected"] {
        color:  ${(props) => props.theme.company_blue };
        border: 1px solid  ${(props) => props.theme.company_blue };
        background-color: #ECF7F9;
    }
    .item-dialog .item-content .item-info .option-tag[data-type="disabled"] {
        color:  #B1B2B5;
        border: 0;
    }
    .item-dialog .item-content .item-info .option-tag[data-type="forbid"] {
        color:  #F43D36;
        border: 1px solid  #F56762;
    }
    .item-dialog .MuiDialogActions-root {
        position: relative;        
    }
    .item-dialog .item-action {
        width: 100%;
    }
    .item-dialog .item-action .prompt-msg {
        width: 100%;
        display: flex;
    }
    .item-dialog .item-action .prompt-msg span {
        font-size: 12px;
    }
    .item-dialog .item-action .btn_div {
        width: 100%;
        text-align: right;
    }
    .item-dialog .item-action .bt_save {
        padding: 10px 40px;
        background-color: #95D5DD;
        color: ${(props) => props.theme.white };
        border-radius: 20px;
        border: 0;
        font-size: 14px;
        font-weight: 500;
        margin: 5px 0;
    }
    .content-small .shopping {
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.white};
        border-left: 1px solid #F7F7F7;
        box-shadow: 0px 0px 9px ${(props) => props.theme.light_grey};
        overflow-y: hidden;
     }
    .content-small .shopping .shopping-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: left;
        color: ${(props) => props.theme.company_blue};
        font-size: 15.5px;
        font-weight: bold;
        padding: 0 20px;
        border-bottom: 1px solid #F7F7F7;
        box-sizing: border-box;
    }    
    .content-small .shopping .shopping-title span {
        color: ${(props) => props.theme.company_blue};
    }
    .content-small .shopping .shopping-plan {  
        width: 100%;
        height: calc(100% - 55px);
        background-color: #F8F9FB;
        padding: 0 10px 0 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 113px;
    }
    .content-small .shopping .shopping-plan { 
        -ms-overflow-style: none; 
        overflow: -moz-scrollbars-none;
    }
    .content-small .shopping .shopping-plan::-webkit-scrollbar { 
        width: 0 !important;
    }
    .content-small .shopping .shopping-plan .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    .content-small .shopping .shopping-plan .empty p{
        color: #5D5D5D;
        font-size: 14px;
    }
    .content-small .shopping .shopping-plan .empty span {
        color: #5D5D5D;
        margin: 0 auto;
        font-size: 36px;
    }
    .content-small .shopping .shopping-plan .full {
        width: calc(100%-10px);
        background-color: ${(props) => props.theme.white};
        padding: 10px;
        margin: 10px;
        text-align: left;
        border-radius: 15px;
        box-sizing: border-box;
    }
    .content-small .shopping .shopping-plan .full input[type='checkbox']{
        margin-right: 10px;
    }
    .content-small .shopping .shopping-plan .full .plan-type {
        display: flex;
        align-items: center;
    }   
    .content-small .shopping .shopping-plan .full .plan-type .circleImg {
        height: 100%;
        width: 15%;
        text-align: right;
        margin: 0 10px 0 0;
    }
    .content-small .shopping .shopping-plan .full .plan-type .circleImg img {
        width: 60%;
        margin: 7.5% 5%;
        border-radius: 50%;
        border: 1px solid #CFCFCF
    }
    .content-small .shopping .shopping-plan .full .plan-item {
        display: flex;
        align-items: center;
        height: 100px;
        width: 100%;
    }
    .content-small .shopping .shopping-plan .full .plan-item .itemImg {
        width: 25%;
        margin: 0 10px;
        max-width: 90px;
    }
    .content-small .shopping .shopping-plan .full .plan-item .itemImg img {
        width: 100%;
        vertical-align: middle;
        border-radius: 10px;
    }
    .content-small .shopping .shopping-plan .full .plan-item .itemDetail {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        width: calc(75% - 18px);
        min-width: calc(100% - 128px);
    }
    .content-small .shopping .shopping-plan .full .plan-item .itemDetail h4 {
        margin: 0;    
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 15px;
    }
    .content-small .shopping .shopping-plan .full .plan-item .itemDetail p {
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
    }
    .itemDetail-tag {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .plan-item .itemDetail-tag p {
        color: #878787;
        margin: 0px;
        padding: 10px 0;
    }
    .itemDetail-price {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
    }
    .itemDetail-price span.itemDetail-add {
        font-size: 32px;
        margin: 0px 10px;
        padding: 0;
        color: ${(props) => props.theme.company_blue};
    }
    .itemDetail-price > h4,
    .itemDetail-price > h5 {
        margin: 0;
    }
    .itemDetail-price .item-num {
        width: 50%;
        display: flex;
        justify-content: space-between;
        max-width: 90px;
    }
    .itemDetail-price .item-num > label {
        font-size: 20px;
    }
    .itemDetail-button {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    .itemDetail-button > button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
        border: 1px solid ${(props) => props.theme.company_blue};
        border-radius: 25px;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .content-small .shopping .shopping-footer {
        display: flex;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
        align-items: flex-start;
        flex-direction: column;
        box-shadow: 0px 0px 9px ${(props) => props.theme.light_grey};
        position: absolute;
        bottom: 0;
        background-color: ${(props) => props.theme.white};
    
    }
    .shopping-footer .footer-msg {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .shopping-footer .footer-msg .msg {
        text-align: left;
        font-size: 13px;
        color: #939393;
        overflow-y: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .shopping-footer .footer-msg .msg label {
        color: red;
        text-decoration: underline;
    }
    .shopping-footer .footer-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .footer-buttons .bt-left {
        width: 30%;
        text-align: left;
    }
    .footer-buttons .bt-middle {
        color: ${(props) => props.theme.company_blue};
        width: 1%;
        text-align: right;
    }
    .footer-buttons .bt-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        text-align: right;
    }
    .footer-buttons .bt-right button {
        outline: none;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        padding: 10px 15px;
        border-radius: 20px;
        border: 0;
        margin: 0 5px;
    }
    .footer-buttons .bt-right .price {        
        display: flex;
        align-items: baseline;
    }
    .footer-buttons .bt-right h5,
    .footer-buttons .bt-right h3 {
        color: ${(props) => props.theme.company_blue};
        margin-right: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .footer-buttons .bt-right button.shopping-operate:disabled {
        background-color: #95D5DD;
    }
    .lightbox-target:target {
        opacity: 1;
        top: 0;
        bottom: 0;
    }
    .lightbox-target {
        position: fixed;
        top: -100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        -moz-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        -webkit-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
        overflow: hidden;
    }
    .lightbox-target:target .lightbox-content,
    .lightbox-target:target img {
        max-height: 46%;
        max-width: 25%;
    }
    .lightbox-target .lightbox-content {
        width: 90%;
        height: 90%;
        background: #fff;
        color: #333;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 0%;
        max-width: 0%;
        border: 3px solid #fff;
        -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 8px rgb(0 0 0 / 30%);
        box-shadow: 0 0 8px rgb(0 0 0 / 30%);
        box-sizing: border-box;
        -moz-transition: ease-in-out 0.5s;
        -o-transition: ease-in-out 0.5s;
        -webkit-transition: ease-in-out 0.5s;
        transition: ease-in-out 0.5s;
    }
    .lightbox-target:target .lightbox-close {
        top: 27%;
        left: 60%;
    }
    .lightbox-close {
        display: block;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        color: ${(props) => props.theme.company_blue};
        position: absolute;
        top: 15%;
        right: 5%;
        -moz-transition: ease-in-out 0.5s;
        -o-transition: ease-in-out 0.5s;
        -webkit-transition: ease-in-out 0.5s;
        transition: ease-in-out 0.5s;
    }
    .lightbox-close p {
        font-size: 25px;
        margin: 0;
        line-height: 50px;
    }
    .lightbox-content > div {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: ${(props) => props.theme.company_blue};
        font-size: 22px;
        font-weight: bold;
    }
    .lightbox-content > p {
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        margin: 10px 0 6px 0;
    }
    .lightbox-content .date-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: space-around;
        height: auto;
        padding-bottom: 12px;
        text-align: left;
    }
    .lightbox-content .date-items button {
        width: 90px;
        margin: 5px;
        padding: 8px;
        background-color: #F3F3F3;
        color: #999999;
        border: 0;
        border-radius: 50px;
        cursor: pointer;
    }
    .lightbox-content .date-items button.bt_selected {
        background-color: #2594A2;
        color: #FFFFFF;
    }
    .lightbox-content .time-items {
        width: 100%;
        height: 65px;
        padding-bottom: 13px;
        overflow-y:hidden;
        white-space: nowrap;
        text-align: left;
    }
    .lightbox-content .time-items .btn {
        width: 15%;
        margin: 1%;
        padding: 10px 5px;
        background-color: #F3F3F3;
        color: #838383;
        border: 0;
        border-radius: 50px;
    }
    .lightbox-content .time-items button:disabled {
        background-color: #F8F8F8;
        color: #E1E1E1;
    }
    .lightbox-content .time-items button.bt_selected {
        background-color: #2594A2;
        color: #FFFFFF;
    }
    .lightbox-content button.bt_ok {
        width: 95%;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        margin: 18px 0 10px 0;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
    }
    .time-items::-webkit-scrollbar {
        height: 16px;
        margin-top: 1em; 
        background-color: transparent;
    }        
    .time-items::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px
    }       
    .time-items::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    .lightbox-content .scroller {
        //width: 300px;
        //height: 100px;
        //overflow-x: auto;
        //overflow-y: hidden;
    }
    .lightbox-content .container {
        //position: relative; /*important for the .position() method */
        //height: 100px;
        //width: 770px;
    }
    .lightbox-content .container div {
        //height: 90px;
        //width: 60px;
        //float: left;
        //margin: 5px;
        //background: #39f;
    }
    .wrapper .content.plan-background {
        background-color: #F8F9FB;
        height: 100vh;
    }
    .wrapper .content .plan-block {
        background-color: ${(props) => props.theme.white};
        box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
        width: 40%;
        height: 65%;
        border-radius: 10px;
        margin: 6% auto 0 ;
    }
    .wrapper .content.order-plan {
        min-width: 760px;
    }
    .wrapper .content.order-plan .plan-block {
        margin-top: 120px;
        min-width: 760px;
    }
    .wrapper .content .plan-block .plan-title {
        color: ${(props) => props.theme.company_blue};
        font-weight: 550;
        margin: 3%;
    }
    .wrapper .content .plan-block hr {
        color: #F5F5F5;
        border-color: #F5F5F5;
        border-style: solid;
    }
    .wrapper .content .plan-content {
        padding: 20px;
    }
    .wrapper .content .plan-content .prompt-msg {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
    }
    .wrapper .content .plan-content .prompt-msg span{
        font-size: 14px;
    }
    .wrapper .content .plan-content .prompt-msg .msg {
        color: #C0C0C0;
        margin-left: 2%;
        margin-bottom: 2%;
        text-align: left;
        font-size: 14px;
    }
    .wrapper .content .plan-content .shop-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .wrapper .content .plan-content .shop-list .shop-item {
        display: flex;
        flex-direction: row;
        width: 45%;
        margin: 1%;
        padding: 8px;
        border: 1px solid #F2F2F2;
        align-items: center;        
        justify-content: space-between;
    }
    .wrapper .content .plan-content .shop-list .shop-item .shop-info {
        display: flex;
        flex-direction: row;
        padding: 5px;
        align-items: center;      
        width: 100%; 
    }
    .wrapper .content .plan-content .shop-list .shop-item .shop-info .shop-name {
        color: #181818;
    }
    .wrapper .content .plan-content .shop-list .shop-item .shop-info img {
        width: 52px;
        height: 52px;
    }
    .wrapper .content .plan-content .shop-list .shop-item .shop-name {
        text-align: left;
        margin: 3%;
    }
    .wrapper .content .plan-content .shop-list .shop-item .times-icon {
        margin-right: 5px;
    }
    .wrapper .content .plan-block hr {
        margin-bottom: 0;
    }
    .wrapper .content .order-plan-content {
        height: 68%;
        display: flex;
        background-color: #F8F9FB;
        padding: 16px 3%;
        justify-content: space-between;
    }
    .wrapper .content .order-plan-content .content-left {
        width: 48%;
    }
    .wrapper .content .order-plan-content .content-left .customer-contact,
    .wrapper .content .order-plan-content .content-left .order-date{
        font-weight: 500;
        background-color: ${(props) => props.theme.white};
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        margin-bottom: 3%;
        align-items: center;
    }
    .wrapper .content .order-plan-content .content-left .contact-data svg{
        font-size: 1em;
    }
    .wrapper .content .order-plan-content .content-left .contact-data .data{
        flex-direction: column;
        align-items: flex-end;
        display: flex;

    }
    .wrapper .content .order-plan-content .content-left .order-date .date,
    .wrapper .content .order-plan-content .content-left .contact-data .data span{
        font-size: 12px;
    }
    .wrapper .content .order-plan-content .content-left .order-date .date,
    .wrapper .content .order-plan-content .content-left .contact-data .data span.customer-tel{
        color: #B1B1B1;
    }
    .wrapper .content .order-plan-content .content-right {
        height: auto;
        border-radius: 10px;
        background-color: ${(props) => props.theme.white};
        width: 45%;
        padding: 15px;
        overflow-y: auto;
    }
    .wrapper .content .order-plan-content .content-right .shop-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .wrapper .content .order-plan-content .content-right .shop-title span {
        text-align: left;
    }
    .wrapper .content .order-plan-content .content-right .shop-title img {
    }
    .wrapper .content .order-plan-content .content-right .item .img-detail {
        width: 25%;
    }
    .wrapper .content .order-plan-content .content-right .item .img-detail img {
        width: 100%;
    }
    .wrapper .content .order-plan-content .content-right .item .item-news {
        width: 75%;
    }
    
    .wrapper .content .order-plan-content .content-right .item {
        display: flex;
        margin: 10px 0;
        
    }
    .wrapper .content .order-plan-content .content-right .item .item-news{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 8px;
    }
    .wrapper .content .order-plan-content .content-right .item .item-news .item-desc{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wrapper .content .order-plan-content .content-right .item .item-news .order-item-title {
        text-align: left;
        overflow-y: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-size: 14px;
    }
    .wrapper .content .order-plan-content .content-right .item .item-news .order-item-tag {
        text-align: left;
        color: #C3C3C3;
        font-size: 13px;
        overflow-y: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .wrapper .content .order-plan-content .content-right .item .item-price .currency{
        font-size: 12px;
        margin-right: 3px;
    }
    .wrapper .content .order-plan-content .content-right .item .item-price .item-amount{
        font-size: 14px;
        color: #C3C3C3;
    }
    .wrapper .content .order-plan-content .content-right .item .item-price{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .wrapper .content .order-plan-footer {
        height: 14%;
        padding: 15px;
    }
    .wrapper .content .order-plan-footer .prompt-msg{
        display: flex;
    }
    .wrapper .content .order-plan-footer .prompt-msg span{
        font-size: 12px;
    }
    .wrapper .content .order-plan-footer .prompt-msg .msg{
        font-size: 12px;
        text-align: left;
        color: #ACACAC;
        overflow-y: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .wrapper .content .order-plan-footer .prompt-msg .msg > span{
        color: ${(props) => props.theme.red};
        text-decoration: underline;
    }
    .wrapper .content .order-plan-footer .price-and-save {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .wrapper .content .order-plan-footer .price-and-save h5{
        color: ${(props) => props.theme.company_blue};
        margin: 0 8px;
    }
    .wrapper .content .order-plan-footer .price-and-save h3{
        color: ${(props) => props.theme.company_blue};
        margin: 0 20px 0 0;
    }
    .wrapper .content .order-plan-footer .price-and-save button{
        padding: 12px 22px;
        border-radius: 20px;
        border-width: 0;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
    }
    .content-right::-webkit-scrollbar {
        margin-right: 0.1em;
        width: 16px;
        background-color: transparent;
    }        
    .content-right::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px;
    }       
    .content-right::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    .MuiDialogContent-root .customer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .MuiDialogContent-root .customer-content label {
        text-align: left;
        font-size: 14px;
        font-weight: 550;
        width: 100%;
    }
    .MuiDialogContent-root .customer-content span {
        text-align: right;
        width: 100%;
        font-size: 12px;
        color: #C3C3C3;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
    }
    .MuiDialogContent-root .customer-content input {
        text-align: left;
        padding: 10px 0;
        width: 100%;
        font-size: 16px;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }
    .MuiDialogContent-root .customer-content input:focus {
        outline: none;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }

    .MuiDialogActions-root .customer-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MuiDialogActions-root .customer-action {
        width: 100%;
    }
    .MuiDialogActions-root .customer-action h5 {
        font-weight: 500;
        width: 100%;
        color: #B5B5B5;
        margin: 0;
        margin-bottom: 14px;
    }
    .MuiDialogActions-root .customer-action button {
        width: 100%;
        background-color: ${(props) => props.theme.company_blue };
        color: ${(props) => props.theme.white };
        border: 0px;
        padding: 12px;
        font-size: 14px;
    }
    .wrapper .content .detail-block {
        background-color: ${(props) => props.theme.white};
        box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
        width: 40%;
        height: 80%;
        border-radius: 10px;
        margin: 6% auto 0 ;
        min-width: 760px;
    }
    .wrapper .content .detail-block .detail-title {
        color: ${(props) => props.theme.company_blue};
        font-weight: 550;
        margin: 3%;
    }
    .wrapper .content .detail-block hr {
        margin: 0;
        color: #F5F5F5;
        border-color: #F5F5F5;
        border-style: solid;
    }
    .wrapper .content .order-detail-title {
        position: relative;
        padding: 20px;
    }
    .wrapper .content .order-detail-title a {
        position: absolute;
        right: 15px;
        display: flex;
        top: 20px;
        color: ${(props) => props.theme.company_blue };
    }
    .wrapper .content .order-detail-title a span.icon {
        font-weight: 550;
        margin-right: 8px;
    }
    .wrapper .content .order-detail-title a span.icon-span {
        font-size: 14px;
    }
    .wrapper .content .order-detail-content {
        padding: 20px;
        background-color: #F8F9FB;
        height: 73%;
    }
    .wrapper .content .order-detail-content .content {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 55%;
        background-color: ${(props) => props.theme.white};
        margin: 0 auto;
    }
    .wrapper .content .order-detail-content .content .shop-data{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }
    .wrapper .content .order-detail-content .content .shop-data .shop-name {
        font-size: 16px;
        font-weight: 700;
        margin: 10px auto;
    }
    .wrapper .content .order-detail-content .content .shop-data .shop-address {
        font-size: 14px;
        font-weight: 500;
        margin: 5px auto;
    }
    .wrapper .content .order-detail-content .content .title{
        font-size: 14px;
        color: #B2B2B2;
    }    
    .wrapper .content .order-detail-content .content .customer-data{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    .wrapper .content .order-detail-content .content .customer-data span{
        font-size: 14px;
        margin: 2px auto;
    }
    .wrapper .content .order-detail-content .content .order-data{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }
    .wrapper .content .order-detail-content .content .order-data div{
        display: flex;
        flex-direction: column;
    }
    .wrapper .content .order-detail-content .content .order-data div span { 
        font-size: 14px;
        margin: 3px auto;
    }
    .wrapper .content .order-detail-content .content hr {
        margin: 0;
        margin-bottom: 10px;
        color: #F5F5F5;
        border-color: #F5F5F5;
        border-style: solid;
    }
    .wrapper .content .order-detail-content .content .item-data{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0 10px;
        overflow-y: auto;
    }
    .order-detail-content .item-data::-webkit-scrollbar {
        margin-right: 0.1em;
        width: 16px;
        background-color: transparent;
    }        
    .order-detail-content .item-data::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px;
    }       
    .order-detail-content .item-data::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D3D3D3;
    }
    .wrapper .content .order-detail-content .content .item-data thead {
        position: sticky;
        top: 0;
        background-color: ${(props) => props.theme.white};
    }
    .wrapper .content .order-detail-content .content .item-data thead td {
        background-color: ${(props) => props.theme.white};
    }
    .wrapper .content .order-detail-content .content .item-data .td-quantity {
        text-align: left;
    }
    .wrapper .content .order-detail-content .content .item-data .td-name {
        text-align: left;
        padding: 0 10px;
    }
    .wrapper .content .order-detail-content .content .item-data .td-price {
        text-align: right;
    }

    .wrapper .content .order-detail-content .content .item-data td {
        font-size: 14px;
        color: #B2B2B2;
    }
    .wrapper .content .order-detail-content .content .item-data tbody td {
        padding-top: 15px;
        vertical-align: top;
    }
    .wrapper .content .order-detail-content .content .item-data .list-item .item-desc {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
    }
    .wrapper .content .order-detail-content .content .item-data .list-item .item-desc .item-name {
        color: ${(props) => props.theme.black};
        font-weight: 550;
    }
    .wrapper .content .order-detail-content .content .item-data .list-item .item-desc .item-tag {
        margin-top: 5px;
    }
    .wrapper .content .order-detail-content .content .total-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
    }
    .wrapper .content .order-detail-content .content .total-price .total {
        font-weight: 550;
    }
    .wrapper .content .order-detail-content .content .total-price .currency {
        font-size: 12px;
        margin-right: 5px;
        font-weight: 550;
    }
    .wrapper .content .order-detail-content .content .total-price .price {
        font-weight: 550;
    }
    .wrapper .content .order-detail-footer {
        padding: 15px;
    }
    .wrapper .content .order-detail-footer .prompt-msg{
        display: flex;
    }
    .wrapper .content .order-detail-footer .prompt-msg span{
        font-size: 12px;
    }
    .wrapper .content .order-detail-footer .prompt-msg .msg{
        font-size: 12px;
        text-align: left;
        color: #ACACAC;
    }
    .wrapper .content .order-detail-footer .prompt-msg .msg > span{
        color: ${(props) => props.theme.red};
        text-decoration: underline;
    }
    .wrapper .content .order-detail-footer button {
        padding: 12px 135px;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        border-radius: 20px;
    }
    .wrapper .content .setting-block {
        background-color: ${(props) => props.theme.white};
        box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);
        width: 50%;
        height: 65%;
        border-radius: 6px;
        margin: 6% auto 0 ;
        overflow-y: hidden
    }
    .wrapper .content .setting-block .setting-title {
        color: ${(props) => props.theme.company_blue};
        font-weight: 550;
        font-size: 15px;
        margin: 2.5%;
    }
    .wrapper .content .setting-block hr {
        border-top: 1px solid #FFFFFF;
        border-left: 1px solid #F5F5F5;
        border-right: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 0;
    }
    .wrapper .content .setting-block .setting-content{
        display: flex;
        height: 95%;
    }
    .wrapper .content .setting-block .setting-content .setting-nav{
        width: 27%;
        border-right: 1px solid #F5F5F5;
    }
    .wrapper .content .setting-block .setting-content .setting-nav .nav-title{
        padding: 12px 0;
        padding-left: 25px;
        text-align: left;
        margin: 8px 0;
        font-weight: 550;
        font-size: 14px;
        cursor: pointer;
    }
    .wrapper .content .setting-block .setting-content .setting-data .sub-content:not(.active){
        display: none;
    }
    .wrapper .content .setting-block .setting-content .setting-nav .nav-title.active{
        color: ${(props) => props.theme.company_blue };
        border-left: 3px solid ${(props) => props.theme.company_blue };	
        padding-left: 22px;
    }
    .wrapper .content .setting-block .setting-content .setting-data{
        padding: 18px;
        width: 73%;
    }
    .wrapper .content .setting-content .planned-order-content .prompt-msg {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
    }
    .wrapper .content .setting-content .planned-order-content .prompt-msg span{
        font-size: 14px;
    }
    .wrapper .content .setting-content .planned-order-content .prompt-msg .msg {
        color: #C0C0C0;
        margin-left: 2%;
        margin-bottom: 2%;
        text-align: left;
        font-size: 14px;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop {
        display: flex;
        justify-content: space-between;
    }
    .wrapper .content .setting-content .planned-order-content .order-list hr {
        color: #F5F5F5;
        border-color: #F5F5F5;
        border-style: solid;
        margin 15px 0;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-data {
        display: flex;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-data img {
        width: 50px;
        height: 50px;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-desc .shop-name {
        font-weight: 550;
        font-size: 14px;
        margin-bottom: 3px;
        color: ${(props) => props.theme.company_blue };
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-desc .order-number{
        font-size: 12px;
        color: #B6B6B6;
    }
    .wrapper .content .setting-content .planned-order-content .order-list .shop .shop-desc .price-date{
        font-size: 12px;
        color: #B6B6B6;
    }

    .wrapper .content .setting-content .planned-order-content .order-list .shop .cancel-icon{
        margin: auto 0;
    }

    
    .wrapper .content .setting-content .contact-info-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .wrapper .content .setting-content .contact-info-content label {
        text-align: left;
        font-size: 14px;
        font-weight: 550;
        width: 100%;
        margin-bottom: 10px;
    }
    .wrapper .content .setting-content .contact-info-content span {
        text-align: right;
        width: 100%;
        font-size: 12px;
        color: #A6A6A6;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
    }
    .wrapper .content .setting-content .contact-info-content input {
        text-align: left;
        padding: 10px 0;
        width: 100%;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }
    .wrapper .content .setting-content .contact-info-content input:focus {
        outline: none;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }
    .wrapper .content .setting-content .contact-info-content .info {
        margin-top: 60px;
        width: 100%;
        text-align: right;
    }
    .wrapper .content .setting-content .contact-info-content .info button{
        padding: 10px 25px;
        background-color: ${(props) => props.theme.company_blue };
        color: ${(props) => props.theme.white };
        margin-left: 25px;
        border: 0;
        border-radius: 20px;
    }
    .wrapper .content .setting-content .language-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wrapper .content .setting-content .language-content .language-block {
        margin-bottom: 20px;
        margin-top: 5px;
        margin-left: 10px;
    }
    .wrapper .content .setting-content .language-content .language-block label{
        font-weight: 500;
        font-size: 14px;
    }
    
    .wrapper .content .setting-content .language-content .language-block input[type="radio"] {
        position: absolute;
        opacity: 0;
    }
    .wrapper .content .setting-content .language-content .language-block input[type="radio"] + label:before {
        content: '';
        border-radius: 100%;
        border: 1px solid #575757;
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        top: 0em;
        margin-right: 1.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
    }
    .wrapper .content .setting-content .language-content .language-block input[type="radio"]:checked + label:before {
        background-color: ${(props) => props.theme.company_blue };
        box-shadow: inset 0 0 0 3px ${(props) => props.theme.white };
        border-color:  ${(props) => props.theme.company_blue };
    }
    .wrapper .content .setting-content .language-content .language-block input[type="radio"]:focus + label:before {
        outline: none;
        border-color: ${(props) => props.theme.company_blue };
    }

    .wrapper .content .setting-content .merchant-signup-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wrapper .content .setting-content .merchant-signup-content .prompt {
        font-size: 14px;
        color: #B6B6B6;
        text-align: left;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data {
        width: 100%;
        margin-top: 30px;
        text-align: left;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data.address-icon {
        position: relative;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data.address-icon span {
        color: ${(props) => props.theme.company_blue };
        font-size: 22px;
        position: absolute;
        bottom: 15px;
        right: 5px;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data label {
        text-align: left;
        font-size: 14px;
        font-weight: 550;
        width: 100%;
        margin-bottom: 10px;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data input {
        text-align: left;
        padding: 10px 0;
        width: 100%;
        font-size: 14px;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }
    .wrapper .content .setting-content .merchant-signup-content .merchant-data input:focus {
        outline: none;
        border: 0px;
        border-bottom: 1px solid #C3C3C3;
    }
    .wrapper .content .setting-content .merchant-signup-content .info {
        margin-top: 60px;
        width: 100%;
        text-align: right;
    }
    .wrapper .content .setting-content .merchant-signup-content .info button{
        padding: 10px 25px;
        background-color: ${(props) => props.theme.company_blue };
        color: ${(props) => props.theme.white };
        margin-left: 25px;
        border: 0;
        border-radius: 20px;
    }
    .wrapper .content .setting-content .about-luluhk-content .about-data {
        width: 100%;
        text-align: left;
        margin-bottom: 25px;
        display: flex;
        margin-left: 10px;
        margin-top: 3px;
    }
    .wrapper .content .setting-content .about-luluhk-content .about-data span {
        font-weight: 500;
        font-size: 14px;
    }
    .wrapper .content .setting-content .about-luluhk-content .about-data svg {
        font-size: 1em;
        margin-left: 20px;
    }
    .wrapper .content .setting-content .about-luluhk-content hr {
        margin: 20px 0;
    }
    .wrapper .content .setting-content .about-luluhk-content .app-href{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .wrapper .content .setting-content .about-luluhk-content .app-href img{
        height: 30px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
    }
    .wrapper .merchant-content {
        padding-top: 55px;
    }
    .wrapper .merchant-content .businessNews {
        width: 100%;
        display: flex;
    }
    .wrapper .merchant-content hr {
        width: 100%;
        border: 1px solid #F7F7F7;
        margin: 0;
        //max-width: calc(100vw - 16px)!important;
    }
    .wrapper .merchant-content .allImgBox {
        width: 100%;
        height: 243px;
        position: relative;
        display: inline-block;
    }
    .wrapper .merchant-content .allImgBox .topImgBox {
        height: 243px;
        background-size: cover;
        background-position: center center;
        opacity: 0.5;
        filter: alpha(opacity=50);
        background-color: rgb(255, 255, 255);
        background-blend-mode: multiply;
    }
    .wrapper .merchant-content .allImgBox .mainImg {
        height: 243px;
        width: 100%;
        position: absolute;
        display: inline-block;
        /*z-index: 2;*/
        left: 0;
        top: 0;
    }
    .wrapper .merchant-content .allImgBox .mainImg img {
        height: 243px;
        width: calc(243px * 16 / 9);
    }
    .wrapper .merchant-content .allImgBox .mainImg .mainImg_msg {
        display: flex;
        align-items: center;
        height: 243px;
        width: 100%;
        border-radius: 3px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        color: ${(props) => props.theme.white};
        font-size: 32px;
        font-weight: bold;
    }
	
    .wrapper .merchant-content .businessNews .circleImg {
        height: 100%;
        width: 12%;
        text-align: right;
        margin: auto;
    }
    .wrapper .merchant-content .businessNews .circleImg img {
        width: 55%;
        margin: 7.5% 5%;
        border-radius: 50%;
        border: 1px solid #CFCFCF
    }
    .wrapper .merchant-content .businessNews .newsTitle {
        width: 100%;
        text-align: left;
        padding: 1% 4% 1% 2%;
    }
    .wrapper .merchant-content .allImgBox .mainImg .mainImg_msg p {
        text-align: center;
        width: 100%;
    }
	
    .wrapper .merchant-content #tab-demo {
        width: 100%;
        height: auto;
        display: inline-block;
    }
    .wrapper .merchant-content #tab-demo > ul {
        display: block;
        position: sticky;
        margin: 0;
        top: 55px;
        list-style: none;
        background-color: ${(props) => props.theme.white};
        padding-left: 0;
    }
    .wrapper .merchant-content #tab-demo > ul > li {
        display: inline-block;
        vertical-align: top;
        margin: 0 -1px -1px 0;
        height: 50px;
        width: calc(100%/3);
        line-height: 50px;
        background: ${(props) => props.theme.white};
        padding: 0 15px;
        list-style: none;
        box-sizing: border-box;
    }
    .wrapper .merchant-content #tab-demo > ul > li.shoplist {
        width: 100% !important;
    }
    .wrapper .merchant-content #tab-demo > ul > li.active {
        border-bottom: 3px solid ${(props) => props.theme.company_blue};
        background: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    .wrapper .merchant-content .tab-inner .tab-box {
        display: flex;
        justify-content: center;
        width: 70%;
        background-color: ${(props) => props.theme.white};
        min-width: 1007px;
    }
    .wrapper .merchant-content .tab-inner .tab-box .box-left {
        height: 100%;
        min-width: 320px;
        max-width: 320px;
        box-sizing: border-box;
    }
    .wrapper .merchant-content .tab-box .box-left{
		padding: 20px;
    }
    .wrapper .merchant-content .tab-box .box-left p{
		font-weight: 550;
		margin-top: 0;
    }
    .wrapper .merchant-content #Shop .vertical {
        width: 70%;
        background-color: ${(props) => props.theme.white};
        min-width: 992px;
        padding: 0;
    }
    
    .wrapper .merchant-content #About .vertical {
        //width: 600px;
        //background-color: ${(props) => props.theme.white};
        //padding: 0;
    }
    .wrapper .merchant-content .tab-inner .vertical .about-item {
        display: flex;
        align-items: center;
    }
    .about-item .shop-category-click,
    .about-item .service-hours-click {
        display: flex;
        align-items: center;
    }
    .wrapper .merchant-content .content_box_2 {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
    .wrapper .merchant-content .content_box_2 .box_items_1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2%;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border-radius: 5%;
        height: 50px;
        width: 46%;
        box-sizing: border-box;
        padding: 0px 5px;
    }
    .wrapper .merchant-content .content_box_2 .box_items_2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2%;
        background-color: #F8F9FB;
        border-radius: 5%;
        height: 50px;
        color: ${(props) => props.theme.company_blue};
        width: 46%;
        box-sizing: border-box;
        padding: 0px 5px;
    }
	
    .wrapper .content .item-list {
		padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
    }
    .wrapper .content .item-list .box_items {
        background-color: ${(props) => props.theme.white};
        width: calc((100% - 60px) / 3);
        min-width: 250px;
        margin: 0 10px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .wrapper .content .item-list .box_items > div.normal,
    .wrapper .content .item-list .box_items > div.photo {
        width: 100%;
        height: calc((((((100vw - 17px) * 0.7) - 360px ) - 60px ) / 48) * 9);
        max-width: 301px;
        max-height: 169.3125px;
        min-height: calc(((647px - 60px ) / 48) * 9);
    }
    .wrapper .content .item-list .box_items .photo {
        width: 100%;
        border-radius: 3px;
        position: relative;
    }
    .wrapper .content .item-list .box_items .photo .photo_msg {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        color: ${(props) => props.theme.white};
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
    }
    .wrapper .content .item-list .box_items .photo .photo_msg p {
        text-align: center;
        width: 100%;
    }
    .wrapper .content .item-list .box_items img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
    .wrapper .content .item-list .box_items h4 {
        text-align: left;
        margin: 10px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .content .item-list .box_items .box_detail {
        display: flex;
        flex-direction: row;
        justify-content: start;
        color: #ABABAB;
    }
    .wrapper .content .item-list .box_items .box_detail p.desc {
        //width: 70%;
        width: 100%;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .content .item-list .box_items .box_detail p.address {
        width: 70%;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
    }
    .wrapper .content .item-list .box_items .box_detail p.distance {
        width: 30%;
        text-align: right;
        margin: 0;
        font-size: 14px;
    }

    .wrapper .merchant-content .item-list {
		padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
    }
    .wrapper .merchant-content .item-list .box_items {
        background-color: ${(props) => props.theme.white};
        width: calc((100% - 60px) / 3);
        min-width: 275px;
        margin: 0 10px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .wrapper .merchant-content .item-list .box_items > div.normal,
    .wrapper .merchant-content .item-list .box_items > div.photo {
        width: 100%;
        height: calc((((((100vw - 17px) * 0.7) - 360px ) - 60px ) / 48) * 9);
        max-width: 301px;
        max-height: 169.3125px;
        min-height: calc(((647px - 60px ) / 48) * 9);
    }
    .wrapper .merchant-content .item-list .box_items .photo {
        width: 100%;
        border-radius: 3px;
        position: relative;
    }
    .wrapper .merchant-content .item-list .box_items .photo .photo_msg {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        position: absolute;
        /*z-index: 2;*/
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        color: ${(props) => props.theme.white};
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
    }
    .wrapper .merchant-content .item-list .box_items .photo .photo_msg p {
        text-align: center;
        width: 100%;
    }
    .wrapper .merchant-content .item-list .box_items img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
    .wrapper .merchant-content .item-list .box_items h4 {
        text-align: left;
        margin: 10px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .merchant-content .item-list .box_items .box_detail {
        display: flex;
        flex-direction: row;
        justify-content: start;
        color: #ABABAB;
    }
    .wrapper .merchant-content .item-list .box_items .box_detail p.desc {
        //width: 70%;
        width: 100%;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .wrapper .merchant-content .item-list .box_items .box_detail p.address {
        width: 70%;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
    }
    .wrapper .merchant-content .item-list .box_items .box_detail p.distance {
        width: 30%;
        text-align: right;
        margin: 0;
        font-size: 14px;
    }
    .wrapper .merchant-content #About .title {
        text-align: left;
        margin: 25px 13px 18px 13px;
        font-weight: 550;
    }
    .wrapper .merchant-content #About .merchants {
        display: flex;
        margin: 15px 13px 20px 13px;
        flex-wrap: wrap;
        cursor: pointer;
        word-break: break-all;
        text-align: start;
        font-size: 15px;
    }
    .wrapper .merchant-content #About .merchants span {
        margin-right: 15px;
        margin-bottom: 10px;
        line-height: 1.4;
    }
    img.round-img {
        border-radius: 50%;
    }
	.wrapper .item-content .item-intro {
		
	}

    .wrapper .item-content .item-intro .intro-content {
		width: 66%;
		margin: 0 auto;
		display: flex;
        min-width: 850px;
    }
    .wrapper .item-content .item-intro .intro-content .info {
		display: flex;
		flex-direction: column;
		align-content: flex-start;
		align-items: flex-start;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview {
		position: relative;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .main-img {
		width: 450px;
		height: 450px;
		border: 1px solid #EEEEEE;
		margin-bottom: 10px;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .main-img img {
		width: 450px;
		height: 450px;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .main-img .zoom-area {
        position: absolute;
        z-index: 10;
        width: 243px;
        height: 243px;
        border: 1px solid #CFCFCF;
        background-color: rgba(108, 108, 108, 0.2);
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .zoom-div {
		width: 510px;
		height: 510px;
		z-index: 5;
		position: absolute;
		left: 452px;
		top: 0;
		border: 1px solid #EEEEEE;
		overflow: hidden;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .zoom-div .img-div {
        position: relative;
		width: 765px;
		height: 765px;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .zoom-div .zoom-img {
        z-index: 5;
        position: absolute;
		width: 765px;
		height: 765px;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div {
		position: relative;
		width: 452px;
		margin-bottom: 25px;
		overflow: hidden;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-prev,
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-next {
		height: 77px;
		background-color: #F8F8F8;
		position: absolute;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-prev {
		left: 0;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-next {
		right: 0;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-prev.disabled svg,
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-next.disabled svg {
        color: #E8E8E8;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div svg {
		width: 20px;
		height: 75px;
        color: #999999;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div svg:hover {
        color: ${(props) => props.theme.black}
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list {
		position: relative;
		width: 400px;
		height: 75px;
		overflow-x: hidden;
		margin: 0 auto;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list .img-ul {
		position: absolute;
	 	width: 800px;
		height: 75px;
		top: 0px;
		left: 0px;
		overflow-x: visible;
		overflow-y: hidden;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list li {
        height: 75px;
        width: 75px;
		float: left;
		margin: 0 2.5px;
		opacity: 0.5;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list li.hover {
		opacity: 1;
	}
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list img {
		width: 75px;
		height: 75px;

	}
    .wrapper .item-content .item-intro .intro-content .name {
		color: ${(props) => props.theme.company_blue };
		font-size: 20px;
		margin: 5px 0;
		font-weight: 550;
    }
    .wrapper .item-content .item-intro .intro-content .types {
		color: #8C8C8C;
		margin: 10px 0;
    }
    .wrapper .item-content .item-intro .intro-content .tag {
		display: flex;
		margin: 5px 0; 
    }
    .wrapper .item-content .item-intro .intro-content .stock{
		width: 100%;
		text-align: center;
		color: ${(props) => props.theme.white};
		margin: 15px 0;
		padding: 5px 0;
		font-size: 14px;
    }
    .wrapper .item-content .item-intro .intro-content .stock.normal {
		background-color: ${(props) => props.theme.company_blue};
    }
    .wrapper .item-content .item-intro .intro-content .stock.warn {
		background-color: #F7B83F;
    }
    .wrapper .item-content .item-intro .intro-content .stock.danger {
		background-color: #F41C13;
    }
    .wrapper .item-content .item-intro .intro-content .stock.not-available {
		background-color: #969696;
    }
    .wrapper .item-content .item-intro .intro-content .price-info {
		margin: 10px 0;
		font-size: 14px;
		display: flex;
		align-items: baseline;
    }
    .wrapper .item-content .item-intro .intro-content .price-info span {
		font-weight: 530;
    }
    .wrapper .item-content .item-intro .intro-content .currency {
		font-size: 14px;
		color: ${(props) => props.theme.company_blue};
		font-weight: 550;
		margin: 0 5px;
    }
    .wrapper .item-content .item-intro .intro-content .price {
		font-size: 20px;
		font-weight: 550;
		color: ${(props) => props.theme.company_blue};
    }
    .wrapper .item-content .item-intro .intro-content .delete {
		margin: 0 5px;
		color: #858585;
		text-decoration: line-through;
		font-size: 14px;
    }
    .wrapper .item-content .item-intro .intro-content .available-time {
		margin: 10px 0px;
		display: flex;
		align-items: flex-start;
		width: 100%;
    }
    .wrapper .item-content .item-intro .intro-content .available-time svg {
		font-size: 20px;
		color: #A7A7A7; 
    }
    .wrapper .item-content .item-intro .intro-content .available-time .list {
		display: flex;
		flex-direction: column;
		width: 100%;
    }
    .wrapper .item-content .item-intro .intro-content .available-time .list-control {
		margin: 0 12px;
		display: flex;
		align-items: center;
    }
    .wrapper .item-content .item-intro .intro-content .available-time .list-control span {
		font-size: 14px;
		color: ${(props) => props.theme.black};
    }
    .wrapper .item-content .item-intro .intro-content .available-time .list-control svg {
		margin-left: 8px;
		color: ${(props) => props.theme.black};
		display: inline-block;
    }
    .wrapper .item-content .item-intro .intro-content .time-list {
		display: flex;
		flex-direction: column;
		margin: 0 12px;
		align-content: flex-start;
    }
    .wrapper .item-content .item-intro .intro-content  .time-list .day-available-time {
		font-size: 14px;
		text-align: left;
		margin: 3px 0;
		display: flex;
    }
    .wrapper .item-content .item-intro .intro-content  .time-list .day-available-time.today {
		color: ${(props) => props.theme.company_blue};
    }
    .wrapper .item-content .item-intro .intro-content  .time-list .day-available-time .day {
		width: 50px;
    }
    .wrapper .item-content .item-intro .intro-content .img-preview {
		width: 452px;
    }
    .wrapper .item-content .item-intro .intro-content .info {
		width: calc(100% - 452px);
        min-width: 350px;
        margin-left: 50px;
    }
    .wrapper .item-content .item-intro .intro-content .item-tag {
        padding: 5px 12px;
        border-radius: 20px;
        background-color: #F8F9FB;
        font-size: 14px;
        margin: 5px 10px 5px 0;
    }
	.wrapper .item-content #tab-demo li {
		width: 33%;
	}
	.wrapper .item-content #tab-demo .tab-box {
		width: 66%;
	}
	.wrapper .item-content #tab-demo #Select .tab-box {
		flex-direction: column;
	}
	.wrapper .item-content .select-content {
        display: flex;
        height: 100%;
		padding: 15px;
    }
    .wrapper .item-content .select-content .item-img {
        position: relative;
        width: 50%;
        height: 100%;
    }
    .wrapper .item-content .select-content .item-img .img-data {
        position: relative;
        width: 90%;
        height: 100%;
        margin: 0 5px;
    }
    .wrapper .item-content .select-content .item-img .item-detail-background {
        background-size: cover;
        position: relative;
        width: 100%;
        // height: 61%;
        min-height: 60%;
    }
    .wrapper .item-content .select-content .item-img .item-detail {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        border: 1px solid #F5F5F7;
        padding: 20px;
    }
    .wrapper .item-content .select-content .item-img .item-detail * {
        font-size: 14px;
    }
    .wrapper .item-content .select-content .item-img .item-detail div {
        margin-bottom: 10px;
    }
    .wrapper .item-content .select-content .item-img .item-detail sup {
        font-size: 8px;
    }
    .wrapper .item-content .select-content .item-img .item-detail .item-number,
    .wrapper .item-content .select-content .item-img .item-detail .item-date,
    .wrapper .item-content .select-content .item-img .item-detail .other-detail {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }
    .wrapper .item-content .select-content .item-img img.show,
    .wrapper .item-content .select-content .item-img .item-detail-background.show {
        display: block;
    }
    .wrapper .item-content .select-content .item-img img {
        width: 100%;
        min-height: 60%;
        max-height: 65%;
    }
    .wrapper .item-content .select-content .item-img .view-item {
        width: 100%;
        border: 2px #F8F9FA solid;
        background-color: ${(props) => props.theme.white };
        border-radius: 5px;
        color: ${(props) => props.theme.company_blue };
        margin: 10px auto;
        padding: 15px;
        font-weight: 550;
    }
    .wrapper .item-content .select-content .item-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: 5px;
    }
    .wrapper .item-content .select-content .item-info hr{
        margin: 10px 0;
        width: 100%;
    }
    .wrapper .item-content .select-content .item-info .item-price{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 10px;
    }
    .wrapper .item-content .select-content .item-info .item-price .item-currency {
        color:  ${(props) => props.theme.company_blue };
        font-size: 14px;
    }
    .wrapper .item-content .select-content .item-info .item-price .price {
        margin: 0 8px;
        color:  ${(props) => props.theme.company_blue };
        font-size: 20px;
        font-weight: 550;
    }
    .wrapper .item-content .select-content .item-info .item-price .delete-price {
        font-size: 14px;
        color:  #7B7B7B;
        text-decoration: line-through;
    }
    .wrapper .item-content .select-content .item-info .item-stock {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .wrapper .item-content .select-content .item-info .item-stock.normal {
        color:  ${(props) => props.theme.company_blue };
    }
    .wrapper .item-content .select-content .item-info .item-stock.warning {
        color:  #FAA970;
    }
    .wrapper .item-content .select-content .item-info .item-stock.danger {
        color:  #F54039;
    }
    .wrapper .item-content .select-content .item-info .item-data {
        width: 100%;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: left;
        font-weight: 550;
    }
    .wrapper .item-content .select-content .item-info .show-detail {
        text-decoration: underline;
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 550;
    }
    .wrapper .item-content .select-content .item-info .option-title {
        font-weight: 550;
        font-size: 14px;
        margin: 10px 0;
    }
    .wrapper .item-content .select-content .item-info .option-title-info {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    .wrapper .item-content .select-content .item-info .option-title-info .option-title {
        margin: 0;
    }
    .wrapper .item-content .select-content .item-info .option-title-info .msg {
        font-size: 12px;
        color: #858585;
    }
    .wrapper .item-content .select-content .item-info .flex-tag {
        display: flex;
        flex-wrap: wrap;
    }
    .wrapper .item-content .select-content .item-info .option-tag {
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #F8F9FB;
        font-size: 14px;
        font-weight: 550;
        margin: 5px 10px 5px 0;
		border: 0;
    }
    .wrapper .item-content .select-content .item-info .option-tag[data-type="normal"] {
        border: 0;
    }
    .wrapper .item-content .select-content .item-info .option-tag[data-type="selected"] {
        color:  ${(props) => props.theme.company_blue };
        border: 1px solid  ${(props) => props.theme.company_blue };
        background-color: #ECF7F9;
    }
    .wrapper .item-content .select-content .item-info .option-tag[data-type="disabled"] {
        color:  #B1B2B5;
        border: 0;
    }
    .wrapper .item-content .select-content .item-info .option-tag[data-type="forbid"] {
        color:  #F43D36;
        border: 1px solid  #F56762;
    }
    .wrapper .item-content .MuiDialogActions-root {
        position: relative;        
    }
    .wrapper .item-content .item-action {
        width: 100%;
    }
    .wrapper .item-content .item-action .prompt-msg {
        width: 100%;
        display: flex;
    }
    .wrapper .item-content .item-action .prompt-msg span {
        font-size: 12px;
    }
    .wrapper .item-content .item-action .btn_div {
        width: 100%;
        text-align: right;
    }
    .wrapper .item-content .item-action .bt_save {
        padding: 10px 40px;
        background-color: #95D5DD;
        color: ${(props) => props.theme.white };
        border-radius: 20px;
        border: 0;
        font-size: 14px;
        font-weight: 500;
        margin: 5px 0;
    }
    .wrapper .content .content-big .content_box_2 .box_items .photo,
    .wrapper .content .content-big .content_box_2 .box_items .box_detail p.distance{
        cursor: default;
    }
    .wrapper .content #tab-demo .tab-title li span,
	.wrapper .merchant-content #tab-demo > ul > li span,
    .wrapper .content .content-big .content_box_2 .box_items .normal,
    .wrapper .content .content-big .content_box_2 .box_items h4,
    .wrapper .content .content-big .item span.item-add,
    .item-dialog .item-content .item-img .view-item,
    .item-dialog .item-content .item-info .show-detail,
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-prev:not(.disabled),
    .wrapper .item-content .item-intro .intro-content .img-preview .img-list-div .img-next:not(.disabled),
    .wrapper .content .content-small .tab span,
    .wrapper .content .content-small .content_box_2 .box_items_1,
    .wrapper .content .content-small .content_box_2 .box_items_2,
    .wrapper .content .content-small .content_box_3 .item svg[data-icon="times"],
    .wrapper .content .content-small .content_box_3 .add,
    .wrapper .content .content-small .shopping-title .plan_manage,
    .wrapper .content .content-small .shopping-title .plan_done,
    .MuiDialogContent-root .tag-content .tag-item,
    .wrapper .content .setting-block .setting-content .setting-nav .nav-title {
        cursor: pointer;
    }
    a {
        text-decoration: none;
    }
    .shopping-plan .full input[type="checkbox"]:disabled{
        color: #EDEDED;
    }
    // .icon {
    //     width: 1em;
    //     height: 1em;
    //     vertical-align: -0.15em;
    //     fill: currentColor;
    //     // overflow: hidden;
    // }
    ::placeholder { /* CSS 3 標準 */
        color: #CFCFCF;
    }
    ::-webkit-input-placeholder { /* Chrome, Safari */
        color: #CFCFCF;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: #CFCFCF;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: #CFCFCF;
        opacity: 1;
    }
// page item mobile version start
    .wrapper .content.item-content {
        display: none;
    }
    .wrapper .mobile-item-main {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
    }
    .wrapper .mobile-item-main .mobile-nav {
        display: none;
    }
    .wrapper .mobile-item-main .mobile-item-content {
        padding-top: 103px;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
    }

    .wrapper .mobile-item-main .mobile-item-content .item-tab {
        // height: calc(100vh - 60px);
        padding-bottom: 50px;
        box-sizing: border-box;
        background-color: #F8F9FB;
    }

    .wrapper .item-tab-title {
        display: block;
        position: fixed;
        margin-top: 55px;
        list-style: none;
        background-color: ${(props) => props.theme.white};
        padding-left: 0;
        width: 100vw;
        height: 50px;
        z-index: 19;    
        border-top: 1px solid #EDEDED;
    }
    .wrapper .item-tab-title li {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin: 0 -1px -1px 0;
        height: 50px;
        width: 50%;
        line-height: 50px;
        background: ${(props) => props.theme.white};
        padding: 0 15px;
        list-style: none;
        box-sizing: border-box;
    }
    .wrapper .item-tab-title li.active {
        border-bottom: 3px solid ${(props) => props.theme.company_blue};
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    .wrapper .item-tab-title li span {
        font-weight: 550;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title {
        display: block;
        position: sticky;
        margin: 0;
        list-style: none;
        background-color: ${(props) => props.theme.white};
        padding-left: 0;
        width: 100vw;
        height: 50px;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title li {
        display: inline-block;
        vertical-align: top;
        margin: 0 -1px -1px 0;
        height: 50px;
        width: calc(100%/4);
        line-height: 50px;
        background: ${(props) => props.theme.white};
        padding: 0 14px;
        list-style: none;
        box-sizing: border-box;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-large li {
        width: 100% !important;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-two li {
        width: calc(100%/2) !important;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-three li {
        width: calc(100%/3) !important;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-five li {
        width: calc(100%/5) !important;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title li.active {
        border-bottom: 3px solid ${(props) => props.theme.company_blue};
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.company_blue};
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-title li span {
        font-weight: 550;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-inner {
        display: flex;
        width: calc(100vw - 16px);
        max-width: 500px;
        margin-left: auto;
        margin-right: auto; 
        // height: 75vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        position: fixed;
        background-color: ${(props) => props.theme.white};
        margin-bottom: 43px;
    }
    .wrapper .mobile-item-main .mobile-item-content .tab-inner:not(.active) {
        display: none!important;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper {
        width: 100vw;
        max-width: 500px;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .swiper-slide {
        width: 100vw!important;
        max-width: 500px!important;
        height: 100vw!important;
        max-height: 500px!important;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .img-mask {
        width: 100vw!important;
        max-width: 500px!important;
        height: 100vw!important;
        max-height: 500px!important;
        position: relative;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .img-mask .mask {
        width: 100vw;
        max-width: 500px;
        height: 100vw;
        max-height: 500px;
        position: absolute;
        z-index: 1;
        background-color: rgba(0,0,0,0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${(props) => props.theme.white};
        font-size: 20px;
        font-weight: 550;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .title {
        font-size: 26px;
        font-weight: 550;
        margin: 10px 0;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .item_category {
        color: #919191;
        margin-bottom: 5px;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .tag-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .tag-list span {
        background-color: #F8FAFB;
        border-radius: 10px;
        margin: 5px;
        line-height: 2em;
        padding: 5px;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info a:visited {
        color: ${(props) => props.theme.black};
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .shop {
        font-size: 18px;
        margin-top: 15px;
        padding-bottom: 2px;
        border-bottom: 1px solid ${(props) => props.theme.black};
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status {
        width: 100vw;
        position: fixed;
        bottom: 56px;    
        z-index: 10;
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status .stock{
        width: 100%;
        text-align: center;
        color: ${(props) => props.theme.white};
        padding: 8px 0;
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status .stock.normal {
        background-color: ${(props) => props.theme.company_blue};
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status .stock.warn {
        background-color: #F7B83F;
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status .stock.danger {
        background-color: #F41C13;
    }
    .wrapper .mobile-item-main .mobile-item-content .item-status .stock.not-available {
        background-color: #969696;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Description {
        background-color: ${(props) => props.theme.white};
        width: 100vw;
        max-width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description * {
        color: #9E9E9E;
    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description span {
        font-size: 60px;

    }
    .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description p {
        font-weight: 550;
        font-size: 18px;
    }
    #root .mobile-item-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        align-items: center;
        bottom: 0;
        width: 100vw;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: ${(props) => props.theme.white};
        z-index: 1;
    }
    #root .mobile-item-footer .item-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
    }
    #root .mobile-item-footer .item-price span {
        color: #949494;
        margin-right: 8px;
    }
    #root .mobile-item-footer .item-price span.from {
        color: ${(props) => props.theme.black};
    }
    #root .mobile-item-footer .item-price span.price {
        font-size: 22px;
    }
    #root .mobile-item-footer .item-price span.item-delete {
        text-decoration: line-through;
    }
    #root .mobile-item-footer .footer-right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    #root .mobile-item-footer .footer-right .select {
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        padding: 8px 12px;
        border-radius: 30px;
        font-size: 16px;
        margin-right: 15px;
        white-space: nowrap;
    }
    #root .mobile-item-footer .footer-right .select.disabled {
        background-color: #9D9D9D!important;
    }
    #root .mobile-item-footer .footer-right .shop-nav-time {
        padding: 15px 0 15px 20px;
        border-left: 1px solid #EEEEEE;
    }
    #root .mobile-item-footer .footer-right span.time_line {
        font-size: 26px;
        color: ${(props) => props.theme.company_blue};
    }
    #root .mobile-item-footer .footer-right span.time_line.disabled {
        color: #A0A0A0!important;
    }
    // .wrapper .mobile-item-main .mobile-item-footer .item-price {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     align-items: baseline;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .item-price span {
    //     color: #949494;
    //     margin-right: 8px;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .item-price span.from {
    //     color: ${(props) => props.theme.black};
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .item-price span.price {
    //     font-size: 22px;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .item-price span.item-delete {
    //     text-decoration: line-through;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right .select {
    //     background-color: ${(props) => props.theme.company_blue};
    //     color: ${(props) => props.theme.white};
    //     border: 0;
    //     padding: 8px 12px;
    //     border-radius: 30px;
    //     font-size: 16px;
    //     margin-right: 15px;
    //     white-space: nowrap;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right .select.disabled {
    //     background-color: #9D9D9D!important;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right .shop-nav-time {
    //     padding: 15px 0 15px 20px;
    //     border-left: 1px solid #EEEEEE;
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right span.time_line {
    //     font-size: 26px;
    //     color: ${(props) => props.theme.company_blue};
    // }
    // .wrapper .mobile-item-main .mobile-item-footer .footer-right span.time_line.disabled {
    //     color: #A0A0A0!important;
    // }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-pagination {
        margin-bottom: 20px;
        width: 107px!important; 
    }
    .swiper-pagination-bullet {
        width: 8px!important;
        height: 8px!important;
        transform: scale(1)!important;
        background-color: ${(props) => props.theme.white};
        opacity: 1;    
        margin: 0 6px!important;
        margin-bottom: 3.5px!important;
    }
    .swiper-pagination-bullet-active {
        width: 15px!important;
        height: 15px!important;    
        margin-bottom: 0px!important;

    }    
    .wrapper .mobile-img-viewer {
        width: 100vw!important;
        height: 100vh!important;
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;
    }
    .wrapper .mobile-img-viewer .mobile-nav {
        display: flex;
        flex-direction: row;
        width: 100vw;
        margin: 0 auto;
        padding: 18px 20px 18px 15px;
        text-align: center;
        position: fixed;
        box-sizing: border-box;
        z-index: 5;
        justify-content: space-between;
        border-bottom: #EDEDED 1px solid;
        align-items: center;
        background-color: ${(props) => props.theme.white };
        height: 64px;
    }
    .wrapper .mobile-img-viewer .mobile-nav .search-back {
        color: #9C9C9C;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .wrapper .mobile-img-viewer .mobile-nav .img-position {
        font-weight: 550;
        font-size: 22px;
    }
    .wrapper .mobile-img-viewer .img-content {
        width: 100vw;
        height: calc(100vh - 64px);
        margin-top: 64px;
        background-color: ${(props) => props.theme.white };
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 150;
    }
    .wrapper .mobile-img-viewer .img-content img {
        width: 100%;
        max-width: 100vw;
        max-height: calc(100vh - 64px);
    }
    .wrapper .mobile-img-viewer .viewer-mask {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        z-index: 160;
        position: absolute;
        top: 0;
        left: 0;
    }
    .wrapper .mobile-img-viewer .viewer-mask button {
        width: 95vw;
        margin-bottom: 10px;
        padding: 25px 0;
        border: 0;
        border-radius: 15px;
        font-size: 20px;
    }
    .wrapper .mobile-img-viewer .viewer-mask .save {
        color: ${(props) => props.theme.company_blue }; 
        background-color: #EFEDED;
    }
    .wrapper .mobile-img-viewer .viewer-mask .cancel {
        color: ${(props) => props.theme.red }; 
        background-color: ${(props) => props.theme.white }; 
    }
    
    .mobile-item-dialog {
        display: flex;
        height: 100%;
        width: 100vw;
        max-width: 100vw;
    }
    .mobile-item-dialog .item-dialog-content {
        display: flex;
        flex-direction: column;
        width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        padding: 20px;
        box-sizing: border-box;
    }
    .mobile-item-dialog .item-dialog-content .dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: calc(100vw - 40px);
        max-width: calc(100vw - 40px);
        padding-bottom: 70px;
    }
    .mobile-item-dialog .MuiDialog-scrollPaper {
        align-items: flex-end;
    }
    .mobile-item-dialog .MuiBackdrop-root {
        width: 100vw;
        max-width: 100vw;
    }
    .mobile-item-dialog .MuiPaper-root {
        margin: 0;
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
        height: 70vh;
    }
    .mobile-item-dialog .item-img {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        max-height: 110px;
    }
    .mobile-item-dialog .item-img a {
        width: calc((100vw - 40px)*0.3);
        max-width: 110px;
        max-height: 110px;
    }
    .mobile-item-dialog .item-img img.show,
    .mobile-item-dialog .item-img .item-detail-background.show {
        display: block;
    }
    .mobile-item-dialog .item-img img {
        width: 100%;
        max-width: 110px;
        max-height: 110px;
    }
    .mobile-item-dialog .item-img .view-item {
        width: 100%;
        border: 2px #F8F9FA solid;
        background-color: ${(props) => props.theme.white };
        border-radius: 5px;
        color: ${(props) => props.theme.company_blue };
        margin: 10px auto;
        padding: 15px;
        font-weight: 550;
    }
    .mobile-item-dialog .item-info {
        width: calc((100vw - 40px)*0.7);
        min-width: calc(100vw - 150px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-evenly;
        padding-left: 15px;
    }
    .mobile-item-dialog hr{
        margin: 10px 0;
        width: 100%;
    }
    .mobile-item-dialog .item-info .item-price{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .mobile-item-dialog .item-info .item-price > span{
        font-size: 22px;
    }
    .mobile-item-dialog .item-info .item-price .item-currency {
        color:  ${(props) => props.theme.company_blue };
        font-size: 14px;
    }
    .mobile-item-dialog .item-info .item-price .price {
        margin: 0 8px;
        color:  ${(props) => props.theme.company_blue };
        font-size: 20px;
        font-weight: 550;
    }
    .mobile-item-dialog .item-info .item-price .delete-price {
        font-size: 14px;
        color:  #C1C1C1;
        text-decoration: line-through;
    }
    .mobile-item-dialog .item-info .item-stock {
        font-size: 14px;
    }
    .mobile-item-dialog .item-info .item-stock.normal {
        color:  ${(props) => props.theme.company_blue };
    }
    .mobile-item-dialog .item-info .item-stock.warning {
        color:  #FAA970;
    }
    .mobile-item-dialog .item-info .item-stock.danger {
        color:  #F54039;
    }
    .mobile-item-dialog .item-info .item-data {
        width: 100%;
        font-size: 14px;
        text-align: left;
        font-weight: 550;
    }
    .mobile-item-dialog .item-info .show-detail {
        text-decoration: underline;
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 550;
    }
    .mobile-item-dialog .option {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left;
    }
    .mobile-item-dialog .option-title-info {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    .mobile-item-dialog .option-title-info .option-title {
        margin: 0;
    }
    .mobile-item-dialog .option-title-info .msg {
        
        color: #858585;
    }
    .mobile-item-dialog .option-title {
        text-align: left;
    }
    .mobile-item-dialog .flex-tag {
        display: flex;
        flex-wrap: wrap;
    }
    .mobile-item-dialog .option-tag {
        padding: 6px 8px ;
        border-radius: 16px;
        background-color: #F8F9FB;
        font-size: 14px;
        font-weight: 550;
        margin: 5px 10px 5px 0;
    }
    .mobile-item-dialog .option-tag[data-type="normal"] {
        border: 0;
    }
    .mobile-item-dialog .option-tag[data-type="selected"] {
        color:  ${(props) => props.theme.company_blue };
        border: 1px solid  ${(props) => props.theme.company_blue };
        background-color: #ECF7F9;
    }
    .mobile-item-dialog .option-tag[data-type="disabled"] {
        color:  #B1B2B5;
        border: 0;
    }
    .mobile-item-dialog .option-tag[data-type="danger"] {
        color:  #F43D36;
        border: 1px solid  #F56762;
    }
    .mobile-item-dialog .item-dialog-footer {
        border-top: 2px solid #D0D0D0;
        border-bottom: 20px solid ${(props) => props.theme.white};
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        padding-top: 10px;
        justify-content: space-between;
        width: calc(100vw - 40px);
        align-items: center;
        background-color: ${(props) => props.theme.white};
    }

    .mobile-item-dialog .item-dialog-footer .amount {
        font-size: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .mobile-item-dialog .item-dialog-footer .amount span {
        font-size: 18px;
        font-weight: 550;
    }
    .mobile-item-dialog .item-dialog-footer .amount label {
        font-size: 20px;
        margin: 0 15px;
    }
    .mobile-item-dialog .item-dialog-footer .footer-right span {
        color: ${(props) => props.theme.red};
        font-size: 18px;
    }
    .mobile-item-dialog .item-dialog-footer .footer-right button {
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        padding: 8px 12px;
        border-radius: 30px;
        font-size: 18px;
        margin-left: 18px;
        white-space: nowrap;
    }
    .mobile-item-dialog .item-dialog-footer .footer-right button.disabled {
        background-color: #9D9D9D!important;
    }
    
    .shop-time-dialog {
        width: 100vw;
        max-width: 100vw;
    }
    .shop-time-dialog .MuiDialog-scrollPaper {
        align-items: flex-end;
    }
    .shop-time-dialog .MuiBackdrop-root {
        width: 100vw;
        max-width: 100vw;
    }
    .shop-time-dialog .MuiPaper-root {
        margin: 0;
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
    }
    .shop-time-dialog .time-dialog-content .title {
        font-size: 18px;
        margin: 15px;
        font-weight: 550;
    }

    .shop-time-dialog .time-dialog-content .date,
    .shop-time-dialog .time-dialog-content .time {
        width: 100%;
        white-space: nowrap;
        padding-bottom: 8px;
    }
    
    .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar,
    .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar {
    }        
    .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar-track,
    .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 10px
    }       
    .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar-thumb,
    .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar-thumb {
        border: 0px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: transparent;
    }
        
    .shop-time-dialog .time-dialog-content .date {
        height: auto;
        overflow-y:hidden;
    }
    .shop-time-dialog .time-dialog-content .date button {
        width: 28%;
        margin: 0.5%;
        padding: 10px 15px;
        background-color: #F3F3F3;
        color: #999999;
        border: 0;
        border-radius: 50px;
        max-width: 100px;
    }
    .shop-time-dialog .time-dialog-content .date button.bt_selected {
        background-color: #2594A2;
        color: #FFFFFF;
    }
    .shop-time-dialog .time-dialog-content .time {
        width: 100%;
        height: auto;
        overflow-y:hidden;
        white-space: nowrap;
    }
    .shop-time-dialog .time-dialog-content .time .btn {
        width: auto;
        margin: 1%;
        padding: 15px 20px;
        background-color: #F3F3F3;
        color: #838383;
        border: 0;
        border-radius: 50px;
    }
    .shop-time-dialog .time-dialog-content .time button:disabled {
        background-color: #F8F8F8;
        color: #E1E1E1;
    }
    .shop-time-dialog .time-dialog-content .time button.bt_selected {
        background-color: #2594A2;
        color: #FFFFFF;
    }
    .shop-time-dialog .time-dialog-content .date-selected {
        width: 100vw;
        border: 0;
        box-sizing: border-box;
        border-top: 1px solid #EDEDED;
        padding: 12px;
    }
    .shop-time-dialog .time-dialog-content .date-selected button {
        width: 95vw;
        background-color: ${(props) => props.theme.company_blue};
        color: ${(props) => props.theme.white};
        border: 0;
        padding: 11px;
        border-radius: 5px;
        font-size: 18px;
    }
    
    .shop-detail-dialog .MuiPaper-root {
        width: 80vw;
        border-radius: 5px;
        max-width: 600px;
    }
    .shop-detail-dialog .item-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }

    .shop-detail-dialog .item-detail .title {
        font-size: 24px;
        color: ${(props) => props.theme.company_blue};
        font-weight: 550;
        margin-bottom: 25px;
    }
    .shop-detail-dialog .item-detail span {
        margin-bottom: 3px;
    }
    .shop-detail-dialog .item-detail sup {
        font-size: 8px;
    }
    .shop-detail-dialog .item-detail .item-number,
    .shop-detail-dialog .item-detail .item-date,
    .shop-detail-dialog .item-detail .other-detail {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }
// page item mobile version end
    // @media (min-width: 768px) and (max-width: 1020px) {
    @media (max-width: 991px) {
        .tab-inner .tab-box .box-right .box-inner .box-items-large {
            margin: 20px 9px !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box #box-large{
            width: 100% !important;
            min-width: calc(100% - 120px)!important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail .img-msg {
            width: 100% !important;
            font-size: 13px !important;
        }
        .tab-inner .tab-box .box-right .box-inner .box-items-large .item {
            width: 100% !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large {
            padding: 0px 10px !important;
        }
        .width-small-1 {
            display: none;
        }
        .empty-result {
            height: calc(100vh - 150px);
        }
        .wrapper .content .content-big .content_box_1 {
            display: none;
        }
        .wrapper .content .content-big .content_box_2 {
            margin-top: 15px;
        }
        .wrapper .padding_1 {
            padding: 55px 15px 60px 15px;
        }
        .wrapper .mobile-nav .search-back {
            height: 26px
        }
        #tab-demo > ul {
            top: 55px;
        }

        #root .mobile-footer {
            display: flex;
            position: fixed;
            width: 100vw;
            height: 55px;
            // top: calc(100vh - 55px);
            background-color: ${(props) => props.theme.white };
            border-top: 1px solid #E7E9EA;
            z-index: 5;
            align-items: center;
            justify-content: space-evenly;
            bottom: 0px;
            padding-bottom: 0px;
        }
        #root .mobile-footer a:visited {
            color: ${(props) => props.theme.black};
        }
        // .wrapper .mobile-footer {
        //     display: flex;
        //     position: fixed;
        //     width: 100vw;
        //     height: 55px;
        //     top: calc(100vh - 55px);
        //     background-color: ${(props) => props.theme.white };
        //     border-top: 1px solid #E7E9EA;
        //     z-index: 5;
        //     align-items: center;
        //     justify-content: space-evenly;
        //     bottom: -60px;
        //     padding-bottom: 60px;
        // }
        // .wrapper .mobile-footer a:visited {
        //     color: ${(props) => props.theme.black};
        // }
        .wrapper .mobile-cart-content {
            display: flex;
            height: 100vh;
            background-color: #F8FAFB;
        }
        .wrapper .mobile-setting-content {
            display: flex;
            height: 100vh;
            background-color: #F8FAFB;
            overflow-y: none;
        }
        .wrapper .content.plan-background {
            display: none;
        }
        .wrapper .header {
            display: none;
        }
        .wrapper .home-mobile-header {
            display: flex;
            width: 100vw;
            margin: 0 auto;
            padding: 16px 20px 16px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #F9F9F9 1px solid;
            background-color: ${(props) => props.theme.white };
        }
        .wrapper .home-mobile-header .header-left,
        .wrapper .home-mobile-header .header-right {
            display: flex;
            align-items: center;
        }
        .wrapper .home-mobile-header .header-left .header-location {
            font-size: 20px;
            margin-left: 8px;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 160px;
        }
        .wrapper .home-mobile-header .header-right span.filter,
        .wrapper .home-mobile-header .header-right span.map {
            margin-left: 15px;
        }
        .wrapper .home-mobile-header span {
            font-size: 22px;
        }
        .wrapper .mobile-content-div {
            display: block;

        }
        .wrapper .mobile-search-content {
            display: flex;
            z-index: 15;
            position: absolute;
            width: 100%;
            height: 100%;
            flex-direction: column;
        }
        .wrapper .mobile-search-content .mobile-nav {
            width: 100vw;
            display: flex;
            padding: 10px 25px 10px 20px;
            box-sizing: border-box;
            align-items: center;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 16;
            border: 1px solid transparent;
        }
        .wrapper .mobile-search-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-nav .search-back svg {
            color: #9C9C9C;
            cursor: pointer;
        }
        .wrapper .mobile-search-content .mobile-nav input {
            margin-left: 10px;
            width: calc(100% - 38px);
            border-radius: 20px;
            border: 1px #9C9C9C solid;
            padding: 10px 20px;
        }
        .wrapper .mobile-search-content .mobile-nav input:focus {
            outline: none;
        }
        .wrapper .mobile-search-content .expand-content {
            display: flex;
            padding: 10px 20px;
            box-sizing: border-box;
            flex-direction: column;
            margin-top: 59px;
        }
        .wrapper .mobile-search-content .expand-content .keyword-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        .wrapper .mobile-search-content .expand-content .title {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-search-content .expand-content .bt_manage {
            font-size: 15px;
            font-weight: 525;
        }
        .wrapper .mobile-search-content .expand-content .search-record {
            display: flex;
            flex-wrap: wrap;
        }
        .wrapper .mobile-search-content .expand-content .search-record .keyword-item {
            padding: 8px;
            margin-right: 15px;
            margin-top: 18px;
            background-color: #F8FAFB;
            border-radius: 6px;
        }
        .wrapper .mobile-search-content .expand-content .search-record .icon.cross {
            margin-left: 15px;
        }
        .wrapper .mobile-search-content .expand-content .search-footer {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .wrapper .mobile-search-content .expand-content .search-footer .bin {
            font-size: 24px;
            margin-right: 5px;
        }
        .wrapper .mobile-search-content .expand-content .search-footer .bin {
            font-size: 24px;
            margin-right: 5px;
        }

        .wrapper .mobile-search-content .search-result {
            padding: 10px 0;
            height: calc(100vh - 48px);
            width: 100vw;
            margin-top: 48px;
        }
        .wrapper .mobile-search-content .search-result .result-tab {
            width: 100vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            box-sizing: border-box;
            border-top: 10px solid ${(props) => props.theme.white};
            z-index: 16;
        }
        .wrapper .mobile-search-content .result-tab .tab {
            height: 35.5px;
        }
        .wrapper .mobile-search-content .result-tab .tab span {
            padding: 15px 20px;
            font-weight: 550;
            font-size: 14px;
        }
        .wrapper .mobile-search-content .result-tab .tab span.active {
            color: ${(props) => props.theme.company_blue};
            border-bottom: 2.5px solid ${(props) => props.theme.company_blue};
        }
        .wrapper .mobile-search-content .search-result .result-tab .filter-btn span {
            font-size: 20px;
            margin-right: 20px;
        }
        .wrapper .mobile-search-content .result-content {
            position: fixed;
            margin-top: 40px;
            padding: 15px;
            height: calc(100vh - 98px);
            width: 100vw;
            box-sizing: border-box;
            overflow-y: auto;
            
        }
        .wrapper .mobile-search-content .result-content .empty-result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(50% - 44px);
            left: calc(50% - 58px);
        }
        .wrapper .mobile-search-content .result-content .empty-result span {
            color: #9F9F9F;
        }
        .wrapper .mobile-search-content .result-content .empty-result .text {
            margin-top: 20px;
        }
        .wrapper .mobile-search-content .result-content .tab-content {
            width: 100%;
            height: 100%;
            position:
        }
        .wrapper .search-filter,
        .wrapper .search-map-content {
            display: flex;
            z-index: 20;
            position: absolute;
            width: 100%;
            height: 100%;
            flex-direction: column;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .search-filter .mobile-nav ,
        .wrapper .search-map-content .mobile-nav {
            width: 100vw;
            display: flex;
            padding: 15px 25px 15px 20px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EDEDED;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 1;
        }
        .wrapper .search-filter .mobile-nav span,
        .wrapper .search-map-content .mobile-nav span {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .search-filter .mobile-nav div,
        .wrapper .search-map-content .mobile-nav div {
            color: #9C9C9C;
        }
        .wrapper .search-filter .search-content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
            width: 100vw;
            height: calc(100vh - 60px);
            margin-top: 60px;
            position: fixed;
            overflow-y: auto;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .search-filter .search-content .block-title {
            font-size: 18px;
            font-weight: 520;
            margin: 15px 0;

        }
        .wrapper .search-filter .search-content .block-content {        
            display: flex;
            flex-wrap: wrap; 
            align-items: center;       
        }
        .wrapper .search-filter .search-content .block-content.shop-category,
        .wrapper .search-filter .search-content .block-content.merchant-category,
        .wrapper .search-filter .search-content .block-content.product-category,
        .wrapper .search-filter .search-content .block-content.tag {        
            margin: 5px;
        }
        .wrapper .search-filter .search-content .block-content.status .status-item,
        .wrapper .search-filter .search-content .block-content.sort .sort-item {        
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 1%;
            background-color: #F8FAFB;
            color: ${(props) => props.theme.company_blue};
            border-radius: 8px;
            height: 50px;
            width: 48%;
            max-width: 175px;
            box-sizing: border-box;
            padding: 0px 5px;
        }
        .wrapper .search-filter .search-content .block-content.status .status-item.active,
        .wrapper .search-filter .search-content .block-content.sort .sort-item.active {        
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
        }
        
        .wrapper .search-filter .search-content .block-content button.item {
            box-sizing: border-box;
            padding: 5px 10px;
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.company_blue};
            border-radius: 10px;
            border: 2px solid ${(props) => props.theme.company_blue};
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .wrapper .search-filter .search-content .block-content button.item i {
            color: #848484;
        }
        .wrapper .search-filter .search-content .block-content button.add {
            box-sizing: border-box;
            padding: 5px 15px;
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.company_blue};
            border-radius: 50px;
            border: 1px solid #F4F4F4;
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .wrapper .search-filter .search-content .block-content input.filter-input {
            padding: 12px;
            text-align: center;
            border: 0px;
            border-bottom: 1px solid #9A9A9A;
            width: 35%;
            max-width: 170px;
            margin-bottom: 10px;
        }
        .wrapper .search-filter .search-content .block-content input.filter-input:focus {
            outline: none;
        }
        .wrapper .search-map-content .mobile-nav .check svg {
            color: ${(props) => props.theme.company_blue};
        }
        .wrapper .search-map-content .map-footer {
            position: fixed;
            bottom: 0;
            height: 54px;
            display: flex;
            align-content: center;
            align-items: center;
        }
        .wrapper .search-map-content .map-footer .address {
            border: 15px solid transparent;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            box-sizing: border-box;
        }
        
        .wrapper .search-result #product {
            padding: 15px 10px 0 10px;
            box-sizing: border-box;
        }
        .wrapper .search-result #product .item {
            display: flex;
            flex-direction: row;
            width: 100%;
            position: relative;
            margin-bottom: 20px;
        }
        .wrapper .search-result #product .item .img-detail {
            width: 120px;
            position: relative;
        }
        .wrapper .search-result #product .item .img-detail img {
            height: 120px;
            width: 120px;
            border-radius: 5px;
            object-fit: scale-down;
        }
        .wrapper .search-result #product .item .img-msg {
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            padding: 5px 1px;
            position: absolute;
            /*z-index: 2;*/
            left: 0;
            bottom: 0;
            font-size: 14px;
            width: 120px;
        }
        .wrapper .search-result #product .item .item-news {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;
            margin-left: 10px;
            width: calc(100% - 120px);
        }
        .wrapper .search-result #product .item .item-news .item-title {
            margin: 0;
            padding: 0;
            font-size: 18px;
            font-weight: 550;
            text-align: left;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .wrapper .search-result #product .item .item-news .item-tag {
            overflow: hidden;
            -webkit-line-clamp: 1;
            white-space: nowrap;
            text-align: left;
            margin-top: 5px;
        }
        .wrapper .search-result #product .item .item-news .item-tag span {
            font-size: 13px;
            background-color: #F8FAFB;
            border-radius: 10px;
            margin: 1.5px;
            line-height: 2em;
            padding: 5px;
        }
        .wrapper .search-result #product .item .item-news .item-price {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            flex-direction: column;
        }
        .wrapper .search-result #product .item .item-news .item-price .price-int {
            font-weight: 550;
            font-size: 24px
        }
        .wrapper .search-result #product .item .item-news .item-price .price-decimal {
            font-size: 20px
        }
        .wrapper .search-result #product .item .item-news .item-price .shop-info span {
            font-size: 13px;
        }
        .wrapper .search-result #product .item .item-news .item-price .shop-info svg {
            font-size: 10px;
            margin-left: 5px;
        }
        .wrapper .search-result #product .item .item-news .item-price .shop-info .shop {
            color: #929292;
            text-align: left;
        }
        .wrapper .search-result #product .item .item-news .item-price .shop-info {
            display: flex;
            justify-content: space-between;
        }
        .wrapper .search-result #product .item .item-news .item-price > div {
            display: flex;
            align-items: baseline;
        }
        .wrapper .search-result #product .item .item-news .item-price > div > .item-delete {
            text-decoration: line-through;
            color: #B8B8B8;
        }
        .wrapper .search-result #product .item span.item-add {
            font-size: 32px;
            margin: 0px 10px;
            padding: 0;
            color: ${(props) => props.theme.company_blue};
            position: absolute;
            bottom: 0px;
            right: 0px;
        }
        .wrapper .search-result #shop {
            padding: 15px 10px 0 10px;
            box-sizing: border-box;
        }
        .wrapper .search-result #shop .box_items {
            background-color: ${(props) => props.theme.white};
            width: 100%;
            margin: 0 auto;
            padding-bottom: 40px;
        }
        .wrapper .search-result #shop .box_items .photo {
            width: 100%;
            border-radius: 3px;
            position: relative;
        }
        .wrapper .search-result #shop .box_items .photo .photo_msg {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            border-radius: 3px;
            position: absolute;
            /*z-index: 2;*/
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0.6);
            color: ${(props) => props.theme.white};
            font-size: 20px;
            font-weight: bold;
            justify-content: center;
        }
        .wrapper .search-result #shop .box_items .photo .photo_msg p {
            text-align: center;
            width: 100%;
        }
        .wrapper .search-result #shop .box_items img {
            width: calc(100vw - 50px);
            height: calc((100vw - 50px) / 1.8);
            max-width: 100%;
            object-fit: cover;
            border-radius: 3px;
        }
        .wrapper .search-result #shop .box_items h4 {
            text-align: left;
            margin: 10px 0;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .wrapper .search-result #shop .box_items .box_detail {
            display: flex;
            justify-content: center;
            color: #ABABAB;
        }
        .wrapper .search-result #shop .box_items .box_detail p.desc {
            width: 70%;
            text-align: left;
            margin: 0;
            font-size: 14px;
            line-height: 18px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .wrapper .search-result #shop .box_items .box_detail p.distance {
            width: 30%;
            text-align: right;
            margin: 0;
            font-size: 14px;
        }
        .wrapper .search-result #merchant {
            padding: 15px 10px 0 10px;
            box-sizing: border-box;
        }
        .wrapper .search-result #merchant .box_items {
            background-color: ${(props) => props.theme.white};
            width: 100%;
            margin: 0 auto;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
        }
        .wrapper .search-result #merchant .box_items .merchant-img img {
            width: calc(100vw - 50px);
            height: calc((100vw - 50px) / 1.8);
            object-fit: cover;
            border-radius: 3px;
        }
        .wrapper .search-result #merchant .box_items .merchant-desc {
            width: 100%;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
        }
        .wrapper .search-result #merchant .box_items .merchant-desc img {
            width: calc((100vw - 50px) / 7);
            height: calc((100vw - 50px) / 7);
            border-radius: 50%;
        }
        .wrapper .search-result #merchant .box_items .merchant-desc .data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - ((100vw - 50px) / 7));
            box-sizing: border-box;
            padding-left: 10px;
        }
        .wrapper .search-result #merchant .box_items .merchant-desc .data .merchant-name {
            font-size: 18px;
            font-weight: 550;
            text-align: left;
        }
        .wrapper .search-result #merchant .box_items .merchant-desc .data .desc {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .wrapper .mobile-filter-content {
            display: flex;
            z-index: 15;
            position: absolute;
            width: 100%;
            height: 100%;
            flex-direction: column;
        }
        .wrapper .mobile-filter-content .mobile-nav {
            width: 100vw;
            display: flex;
            padding: 15px 25px 15px 20px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EDEDED;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 16;
        }
        .wrapper .mobile-filter-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        
        .wrapper .mobile-filter-content .mobile-nav span {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-filter-content .mobile-nav div {
            color: #9C9C9C;
        }
        
        .wrapper .mobile-filter-content .home-filter-content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
            width: 100vw;
            height: calc(100vh - 60px);
            margin-top: 60px;
            position: fixed;
            overflow-y: auto;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-filter-content .home-filter-content .block-title {
            font-size: 18px;
            font-weight: 520;
            margin: 10px 0;

        }
        .wrapper .mobile-filter-content .home-filter-content .block-content {        
            display: flex;
            flex-wrap: wrap; 
            align-items: center;       
        }
        .wrapper .mobile-filter-content .home-filter-content .block-content.shop-category,
        .wrapper .mobile-filter-content .home-filter-content .block-content.merchant-category,
        .wrapper .mobile-filter-content .home-filter-content .block-content.product-category,
        .wrapper .mobile-filter-content .home-filter-content .block-content.tag {        
            margin: 5px;
        }
        .wrapper .mobile-filter-content .home-filter-content .block-content.status .status-item,
        .wrapper .mobile-filter-content .home-filter-content .block-content.sort .sort-item {        
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 1%;
            background-color: #F8FAFB;
            color: ${(props) => props.theme.company_blue};
            border-radius: 8px;
            height: 50px;
            width: 48%;
            max-width: 175px;
            box-sizing: border-box;
            padding: 0px 5px;
        }
        .wrapper .mobile-filter-content .home-filter-content .block-content.status .status-item.active,
        .wrapper .mobile-filter-content .home-filter-content .block-content.sort .sort-item.active {        
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
        }
        
        .wrapper .mobile-filter-content .home-filter-content .block-content button.item {
            box-sizing: border-box;
            padding: 5px 10px;
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.company_blue};
            border-radius: 10px;
            border: 2px solid ${(props) => props.theme.company_blue};
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .wrapper .mobile-filter-content .home-filter-content .block-content button.item i {
            color: #848484;
        }
        .wrapper .mobile-filter-content .home-filter-content .block-content button.add {
            box-sizing: border-box;
            padding: 5px 15px;
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.company_blue};
            border-radius: 50px;
            border: 1px solid #F4F4F4;
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
            margin-right: 10px;
            margin-bottom: 10px;
        }
        
        .wrapper .mobile-map-content .mobile-nav {
            width: 100vw;
            display: flex;
            padding: 10px 25px 10px 20px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EDEDED;
            position: fixed;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-map-content .mobile-nav span {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-map-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-map-content .home-map-content {
            width: 100vw;
            min-height: 100%;
        }
        .wrapper .mobile-map-content .home-map-content .map-footer {
            position: fixed;
            bottom: 0;
            height: 54px;
            display: flex;
            align-content: center;
            align-items: center;
        }   
        .wrapper .mobile-map-content .home-map-content .map-footer .address {
            border: 15px solid transparent;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            box-sizing: border-box;
        }

        .wrapper .mobile-category-content,
        .wrapper .mobile-tag-content,
        .wrapper .mobile-payment-content {
            display: flex;
            z-index: 20;
            position: absolute;
            width: 100%;
            height: 100%;
            flex-direction: column;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-category-content .mobile-nav, 
        .wrapper .mobile-tag-content .mobile-nav, 
        .wrapper .mobile-payment-content .mobile-nav {
            width: 100vw;
            display: flex;
            padding: 15px 25px 15px 20px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 21;
        }
        .wrapper .mobile-category-content .mobile-nav .search-back,
        .wrapper .mobile-tag-content .mobile-nav .search-back,
        .wrapper .mobile-payment-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-category-content .mobile-nav .nav-title, 
        .wrapper .mobile-tag-content .mobile-nav .nav-title, 
        .wrapper .mobile-payment-content .mobile-nav .nav-title {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-category-content .category-content {
            display: flex;
            flex-direction: column;
            background-color: ${(props) => props.theme.white};
            margin-top: 48px;
        }
        .wrapper .mobile-category-content .category-content .category-block-title {
            text-align: left;
            border-bottom: 1px solid #F5F5F5;
            background-color: ${(props) => props.theme.white};
            position: fixed;
            width: 100vw;
            margin-top: 10px;
            z-index: 21;
        }
        .wrapper .mobile-category-content .category-content .category-block-title .title {
            font-size: 20px;
            font-weight: 600;
            padding: 20px;
        }
        .wrapper .mobile-category-content .category-content .category-block-title .category-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 15px 20px;
        }
        .wrapper .mobile-category-content .category-content .category-block-title .category-nav span {
            font-size: 15px;
        }
        .wrapper .mobile-category-content .category-content .block-content {
            margin-top: 81px;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item {
            margin: 0;
            text-align: left;
            position: relative;
            box-sizing: border-box;
            padding: 15px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #F5F5F5;
            border-bottom: 1px solid #F5F5F5;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item .category-title {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 16px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item input{
            margin-right: 2.5%;
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;    
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item .checkbox-custom{
            position: absolute;
            top: -1;
            left: 0;
            height: 14px;
            width: 14px;
            border: solid #BAC3C3 2px;
            border-radius: 2px;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item input:indeterminate ~ .indeterminate-custom{
            position: absolute;
            top: -1;
            left: 0;
            height: 14px;
            width: 14px;
            border: solid #BAC3C3 2px;
            border-radius: 2px;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item input:checked ~ .checkbox-custom,
        .wrapper .mobile-category-content .category-content .mobile-category-item input:indeterminate ~ .indeterminate-custom{
            background-color: ${(props) => props.theme.company_blue};
            border-color : ${(props) => props.theme.company_blue}
        }    
        .wrapper .mobile-category-content .category-content .mobile-category-item .checkbox-custom:after,
        .wrapper .mobile-category-content .category-content .mobile-category-item .indeterminate-custom:after
        {
            content: "";
            position: absolute;
            display: none;
        }   
        .wrapper .mobile-category-content .category-content .mobile-category-item input:checked ~ .checkbox-custom:after,
        .wrapper .mobile-category-content .category-content .mobile-category-item input:indeterminate ~ .indeterminate-custom:after{
            display: block;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item .checkbox-custom:after{
            color: ${(props) => props.theme.white};
            left: 4px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-radius: 1px;
            border-width: 0 2px 2px 0;  
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item .indeterminate-custom:after{
            color: ${(props) => props.theme.white};
            left: 0px;
            top: 6px;
            width: 12px;
            border: solid white;
            border-radius: 1px;
            border-width: 0 2px 2px 0;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item span{
            color: #262626;
        }
        .wrapper .mobile-category-content .category-content .mobile-category-item .category-arrow svg {
            font-size: 1rem;
        }
        .wrapper .mobile-tag-content .block-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px;
            margin-top: 48px;
        }
        .wrapper .mobile-tag-content .block-content .mobile-tag-item {
            border-radius: 5px;
            background-color: #F8F9FB;
            color: #2D2D2E;
            padding: 8px;
            margin: 10px;
            font-size: 14px;
        }
        .wrapper .mobile-tag-content .block-content .mobile-tag-item.selected {
            background-color: ${(props) => props.theme.company_blue };
            color: ${(props) => props.theme.white };
        }
        .wrapper .mobile-payment-content .payment-content {
            display: flex;
            flex-direction: column;
            background-color: ${(props) => props.theme.white};
            margin-top: 48px;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav {
            text-align: left;
            border-bottom: 1px solid #F5F5F5;
            background-color: ${(props) => props.theme.white};
            width: 100vw;
            margin-top: 10px;
            z-index: 21;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav .recent-block {
            display: flex;
            flex-direction: column;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav .recent-block .recent-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100vw;
            padding: 0 20px;
            box-sizing: border-box;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav .recent-block .recent-nav .manage,
        .wrapper .mobile-payment-content .payment-content .payment-nav .recent-block .recent-nav .done {
            margin-left: 10px;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav .recent-nav > span {
            font-size: 20px;
            font-weight: 600;
        }
        .wrapper .mobile-payment-content .payment-content .payment-nav .payment-content-title {
            font-size: 20px;
            font-weight: 600;
            padding: 20px;
        }
        .wrapper .mobile-payment-content .recent-block .recent-content {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
        }
        .wrapper .mobile-payment-content .recent-block .recent-content .keyword-item {
            padding: 8px;
            margin-right: 15px;
            margin-top: 5px;
            background-color: #F8FAFB;
            border-radius: 6px;
        }
        .wrapper .mobile-payment-content .recent-block .recent-content .icon.cross {
            margin-left: 15px;
        }
        .wrapper .mobile-payment-content .payment-content .block-content {
            margin-top: 
        }
        
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item {
            margin: 0;
            text-align: left;
            position: relative;
            box-sizing: border-box;
            padding: 15px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #F5F5F5;
            border-bottom: 1px solid #F5F5F5;
        }
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item .mobile-payment-title {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 16px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item input {
            margin-right: 2.5%;
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;    
        }
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item .checkbox-custom {
            position: absolute;
            top: -1;
            left: 0;
            height: 14px;
            width: 14px;
            border: solid #BAC3C3 2px;
            border-radius: 2px;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item input:checked ~ .checkbox-custom {
            background-color: ${(props) => props.theme.company_blue};
            border-color : ${(props) => props.theme.company_blue}
        }    
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item .checkbox-custom:after {
            content: "";
            position: absolute;
            display: none;
        }   
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item input:checked ~ .checkbox-custom:after {
            display: block;
        }
        .wrapper .mobile-payment-content .payment-content .mobile-payment-item .checkbox-custom:after {
            color: ${(props) => props.theme.white};
            left: 4px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-radius: 1px;
            border-width: 0 2px 2px 0;  
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .wrapper .mobile-cart-content .mobile-nav {
            width: 100vw;
            padding: 18px 25px 18px 20px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 16;
            border-bottom: 1px solid #E7E9EA;
        }
        .wrapper .mobile-cart-content .mobile-nav span {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-cart-content .cart-content {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            max-height: calc(100vh - 117px);            
            overflow-y: auto;
        }
        .wrapper .mobile-cart-content .cart-content a:visited {
            color: ${(props) => props.theme.black};
        }
        .wrapper .mobile-cart-content .cart-content .mobile-shop-item {
            padding: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: ${(props) => props.theme.white};
            border: 1px solid #E9E9E9;
        }
        .wrapper .mobile-cart-content .cart-content .mobile-shop-item .shop-img {
            width: 50px;
            height: 50px;
            margin-right: 15px;
        }
        .wrapper .mobile-cart-content .cart-content .mobile-shop-item img {
            width: 50px;
            height: 50px;
        }
        .wrapper .mobile-cart-content .cart-content .mobile-shop-item .shop-name span {
            font-size: 18px;
            font-weight: 500;
        }
        .wrapper .mobile-setting-content .mobile-nav {
            width: 100vw;
            padding: 17px 18px;
            //padding: 18px 25px 18px 20px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            z-index: 16;
            border-bottom: 1px solid #E7E9EA;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .wrapper .mobile-setting-content  .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-setting-content .mobile-nav .title {
            font-size: 18px;
            font-weight: 550;
            margin-left: -26px;
        }
        .wrapper .mobile-setting-content .mobile-nav span {
            font-size: 18px;
            font-weight: 550;
        }
        .wrapper .mobile-setting-content .setting-content {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            height: 90%;
            max-height: calc(100vh - 180px);            
            //overflow-y: auto;
            //overflow-y: hidden!important;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item {
            padding: 15px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: ${(props) => props.theme.white};
            border: 0.5px solid #F5F5F5;
            cursor: pointer;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item.contact-info {
            margin-bottom: 10px;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .item-info {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .item-info .list-icon {
            margin-right: 15px;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .item-info .list-icon span {
            font-size: 22px;
            color: #9A9A9A;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .arrow-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .arrow-icon span {
            font-size: 15px;
            margin-right: 8px;
            color: #929292;
        }
        .wrapper .mobile-setting-content .setting-content .setting-list-item .arrow-icon svg {
            color: #929292;
            font-size: 1rem;
        }
        .wrapper .mobile-setting-content .setting-content .about {
            position: absolute;
            width: 100vw;
            text-align: center;
            bottom: 10px;
            left: 0;
            color: #959698;
            font-size: 15px;
            cursor: pointer;
        }
        .wrapper .mobile-setting-tab-content {
            top: 0;
            position: absolute;
            width: 100vw;
            height: 100vh;
            background-color: #F8FAFB;
            z-index: 21;
        }
        .wrapper .mobile-planned-orders-content .mobile-nav {
            width: 100vw;
            padding: 18px 25px 18px 20px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            border-bottom: 1px solid #E7E9EA;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .wrapper .mobile-planned-orders-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-planned-orders-content .mobile-nav .title {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-planned-orders-content .mobile-nav .mark {
            color: ${(props) => props.theme.red};
            font-size: 18px;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            max-height: calc(100vh - 117px);            
            overflow-y: auto;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content a:visited {
            color: ${(props) => props.theme.black};
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item {
            padding: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: ${(props) => props.theme.white};
            border: 1px solid #E9E9E9;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item .shop-img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            padding: 5px;
            box-sizing: border-box;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item img {
            width: 50px;
            height: 50px;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item .item-data {
            display: flex;
            height: 60px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
        
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item .item-data .title {
            font-size: 18px;
            font-weight: 550;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item .item-data .order-number {
            color: #919191;
            font-size: 14px;
        }
        .wrapper .mobile-planned-orders-content .planned-orders-content .mobile-order-item .item-data .price-date {
            color: #919191;
            font-size: 14px;
        }        
        .wrapper .mobile-contact-info-content .mobile-nav {
            width: 100vw;
            padding: 18px 25px 18px 20px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            border-bottom: 1px solid #E7E9EA;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
        }
        .wrapper .mobile-contact-info-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-contact-info-content .mobile-nav .title {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-contact-info-content .contact-info-content {
            padding: 20px;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            height: 100vh;
            position: fixed;
            max-height: calc(100vh - 117px);            
            overflow-y: auto;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .mobile-contact-info-content .contact-info-content .data {
            display: flex;
            width: 100vw;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;
        }
        .wrapper .mobile-contact-info-content .contact-info-content .data label {
            font-size: 12px;
            color: #656565;
        }
        .wrapper .mobile-contact-info-content .contact-info-content .data input {
            font-weight: 550;
            font-size: 16px;
            color: #656565;
            width: calc(100vw - 40px);
            border: 0px;
            margin: 5px 0 10px 0;
            padding-bottom: 12px;
            border-bottom: 2px solid #C3C3C3;
        }
        .wrapper .mobile-contact-info-content .contact-info-content .data input:focus {
            outline: none;
        }
        .wrapper .mobile-contact-info-content .contact-info-content .data .letter {
            font-size: 13px;
            color: #656565;
            width: calc(100vw - 40px);
            text-align: right;
        }
        .wrapper .mobile-contact-info-content  .contact-info-footer {
            border-top: 1px solid #E9E9E9;
            width: 100vw;
            position: fixed;
            box-sizing: border-box;
            padding: 10px;
            bottom: 0;
            display: flex;
            flex-direction: column;
            background-color: ${(props) => props.theme.white};
        }   
        .wrapper .mobile-contact-info-content  .contact-info-footer .msg {
            color: #909090;
            font-size: 15px;
        }   
        .wrapper .mobile-contact-info-content  .contact-info-footer .save {
            background-color: ${(props) => props.theme.company_blue};
            border: 0;
            color: ${(props) => props.theme.white};
            font-size: 18px;
            padding: 12px;
            border-radius: 22px;
            margin-top: 12px;
            margin-bottom: 3px;
        }   
        .wrapper .mobile-language-content .mobile-nav {
            width: 100vw;
            padding: 17px 18px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            border-bottom: 1px solid #E7E9EA;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
        }
        .wrapper .mobile-language-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-language-content .mobile-nav .title {
            font-size: 18px;
            font-weight: 550;
            margin-left: -26px;
        }
        .wrapper .mobile-language-content .language-content {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            max-height: calc(100vh - 117px);            
            overflow-y: auto;
        }
        .wrapper .mobile-language-content .language-content .language-block {
            padding: 20px 25px;
            text-align: left;
            background-color: ${(props) => props.theme.white};
            border: 0.5px solid #F5F5F5;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
        }
        .wrapper .mobile-language-content .language-content .language-block label{
            //font-weight: 550;
        }        
        .wrapper .mobile-language-content .language-content .language-block input[type="radio"] {
            position: absolute;
            width: 18px;
            height: 18px;
            opacity: 0;
        }
        .wrapper .mobile-language-content .language-content .language-block input[type="radio"] + label:before {
            content: '';
            border-radius: 100%;
            border: 1px solid #575757;
            display: inline-block;
            width: 1em;
            height: 1em;
            position: relative;
            margin-right: 1.5em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
        }
        .wrapper .mobile-language-content .language-content .language-block input[type="radio"]:checked + label:before {
            background-color: ${(props) => props.theme.company_blue };
            box-shadow: inset 0 0 0 3px ${(props) => props.theme.white };
            border-color:  ${(props) => props.theme.company_blue };
        }
        .wrapper .mobile-language-content .language-content .language-block input[type="radio"]:focus + label:before {
            outline: none;
            border-color: ${(props) => props.theme.company_blue };
        }
        .wrapper .mobile-merchant-content .mobile-nav {
            width: 100vw;
            padding: 18px 25px 18px 20px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
        }
        .wrapper .mobile-merchant-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-merchant-content .merchant-content {
            padding: 5px 40px;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            max-height: calc(100vh - 60px);      
            background-color: ${(props) => props.theme.white};      
            overflow-y: auto;
            display: flex;
            height: 100vh;
            flex-direction: column;
            align-items: flex=start;
        }
        .wrapper .mobile-merchant-content .merchant-content .title {
            font-size: 26px;
            font-weight: 550;
            width: calc(100vw - 80px);
            text-align: left;
            margin-bottom: 28px;
        }
        .wrapper .mobile-merchant-content .merchant-content .prompt {
            text-align: left;
            margin-bottom: 28px;
        }
        .wrapper .mobile-merchant-content .merchant-content input {
            font-size: 16px;
            color: #656565;
            width: calc(100vw - 80px);
            border: 0px;
            margin: 5px 0 20px 0;
            padding-bottom: 18px;
            background-color: transparent;
            border-bottom: 1px solid #CCCCCC;
        }
        .wrapper .mobile-merchant-content .merchant-content input {
        }
        .wrapper .mobile-merchant-content .merchant-content input::placeholder {
            color: #545454;
            opacity: 1;
        }
        .wrapper .mobile-merchant-content .merchant-content input:-ms-input-placeholder {
            color: #545454;
        }
        .wrapper .mobile-merchant-content .merchant-content input::-ms-input-placeholder {
            color: #545454;
        }
        .wrapper .mobile-merchant-content .merchant-content input:focus {
            outline: none;
        }
        .wrapper .mobile-merchant-content .merchant-content .merchant-address {
            position: relative;
        }
        .wrapper .mobile-merchant-content .merchant-content .merchant-address span {
            position: absolute;
            right: 5px;
            top 5px;
            font-size: 25px;
        }
        .wrapper .mobile-merchant-content .merchant-content button {
            background-color: ${(props) => props.theme.company_blue};
            border: 0;
            color: ${(props) => props.theme.white};
            font-size: 18px;
            padding: 18px;
            border-radius: 25px;
            margin-top: 12px;
            margin-bottom: 3px;
        }
    
        .wrapper .mobile-about-content .mobile-nav {
            width: 100vw;
            padding: 17px 18px;
            box-sizing: border-box;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            border-bottom: 1px solid #E7E9EA;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
        }
        .wrapper .mobile-about-content .mobile-nav .search-back {
            color: #9C9C9C;
        }
        .wrapper .mobile-about-content .mobile-nav .title {
            font-size: 18px;
            font-weight: 550;
            margin-left: -26px;
        }
        .wrapper .mobile-about-content .about-content {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100vw;
            margin-top: 60px;
            position: fixed;
            max-height: calc(100vh - 117px);            
            overflow-y: auto;
        }
        .wrapper .mobile-about-content .about-content .about-block {
            padding: 20px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: ${(props) => props.theme.white};
            border: 0.5px solid #F5F5F5;
            cursor:pointer;
        }       
        .wrapper .mobile-about-content .about-content .about-block span.content {
            overflow-y: hidden;
        }
        .wrapper .mobile-about-content .about-content .about-block.policy {
            margin-bottom: 15px;
        }
        .wrapper .mobile-about-content .about-content .about-block .arrow-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-about-content .about-content .about-block .arrow-icon span {
            font-size: 15px;
            margin-right: 8px;
            color: #929292;
        }
        .wrapper .mobile-about-content .about-content .about-block .arrow-icon svg {
            color: #929292;
            font-size: 1rem;
        }
        .wrapper .mobile-merchant-main{
            display: block;
        }
        .wrapper .mobile-merchant-main .mobile-nav{
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 16px 20px 16px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            background-color: ${(props) => props.theme.white };
        }
        .wrapper .mobile-merchant-main .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-merchant-main .mobile-nav .title {
            font-size: 16px;
            overflow: hidden;
            //margin-left: 16px;
        }
        .wrapper .mobile-merchant-main .mobile-nav .merchant-filter {
            color: #9C9C9C;
            font-size: 20px;
        }
        .wrapper .merchant-content {
            padding-top: 52px;
        }
        .wrapper .merchant-content .allImgBox {
            height: calc(((100vw - 16px) * 9) / 16)!important;
            // max-height: 243px;
            width: calc(100vw - 16px);
        }
        .wrapper .merchant-content .topImgBox {
            display: none;
        }
        .wrapper .merchant-content .mainImg {
            width: calc(100vw - 16px)!important;
            position: relative!important;
            height: calc(((100vw - 16px) * 9) / 16)!important;
            // max-height: 243px;
        }
        .wrapper .merchant-content .mainImg img {
            margin: auto;
            width: calc(100vw - 16px)!important;
            height: calc(((100vw - 16px) * 9) / 16)!important;
            // max-height: 243px;
        }
        .wrapper .merchant-content .businessNews {
            //width: calc(100vw - 16px);
            width: 100vw;
            box-sizing: border-box;
            //padding: 15px;
            padding: 10px;
        }
        .wrapper .merchant-content .businessNews .circleImg {
            min-width: 70px;
        }
        .wrapper .merchant-content .businessNews .circleImg img {
            min-width: 70px;
            min-height: 70px;
            box-sizing: border-box;
        }
        .wrapper .merchant-content .businessNews .newsTitle {
            margin-left: 3px;
        }
        .wrapper .merchant-content .businessNews .merchant-title {
            border-bottom: 10px solid  ${(props) => props.theme.white};
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .wrapper .merchant-content .businessNews .merchant-desc {
            color: #878787;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 15;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            //word-break: break-all;
        }
        .wrapper .merchant-content .pc-hr {
            display: none;
        }
        .wrapper .merchant-content #tab-demo > ul  {
            top: 52px;
        }
        .wrapper .merchant-content #tab-demo > ul > li {
            width: calc(100%/3);
            overflow: hidden;
        }
        .wrapper .merchant-content #tab-demo > ul > li.shoplist {
            width: 100%;
        }
        .wrapper .merchant-content #Shop {
            background-color: ${(props) => props.theme.white };
            padding: 5px;
        }
        .wrapper .content .tab-inner .tab-box {
            width: calc(100vw - 30px);
            min-width: 0;
        }
        .wrapper .merchant-content .tab-inner .tab-box {
            width: calc(100vw - 30px);
            min-width: 0;
        }
        .wrapper .merchant-content .tab-inner .tab-box .box-left {
            display: none;
        }
        .wrapper .merchant-content .tab-inner .tab-box .box-right {
            width: 100%;
        }
        .wrapper .merchant-main-filter .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            background-color: ${(props) => props.theme.white };
        }
        .wrapper .merchant-main-filter .mobile-nav span {
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .merchant-main-filter .mobile-nav div {
            color: #9C9C9C;
        }
        .wrapper .merchant-main-filter .main-merchant-filter-content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
            width: 100vw;
            height: calc(100vh - 60px);
            margin-top: 60px;
            position: fixed;
            overflow-y: auto;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .merchant-main-filter .main-merchant-filter-content .block-title {
            font-size: 18px;
            font-weight: 520;
            margin: 15px 0;

        }
        .wrapper .merchant-main-filter .main-merchant-filter-content .block-content {        
            display: flex;
            flex-wrap: wrap; 
            align-items: center;       
        }
        .wrapper .merchant-main-filter .main-merchant-filter-content .block-content.sort .sort-item {        
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 1%;
            background-color: #F8FAFB;
            color: ${(props) => props.theme.company_blue};
            border-radius: 8px;
            height: 50px;
            width: 48%;
            max-width: 175px;
            box-sizing: border-box;
            padding: 0px 5px;
        }
        .wrapper .merchant-main-filter .main-merchant-filter-content .block-content.sort .sort-item.active {        
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
        }
        .wrapper .merchant-content .businessNews .circleImg img {
            min-width: 60px;
            min-height: 60px;
            box-sizing: border-box;
        }
        .wrapper .merchant-content .tab-inner .vertical {
            width: calc(100vw - 30px);
            min-width: 0;
        }
        .wrapper .merchant-content .tab-inner .vertical .about-box {
            padding: 5px;
            width: 100%;
            max-width: 100%;
        }
        .wrapper .merchant-content .tab-inner .vertical .about-item {
            padding: 8px 8px 2px 8px;
        }
        .wrapper .merchant-content .tab-inner .vertical .about-item span {
            font-size: 18px;
            //width: 22px;
            //height: 16px;
        }
        .wrapper .merchant-content .tab-inner .vertical .about-item label {
            font-size: 15px;
            //height: 25px;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            //-webkit-line-clamp: 2;
            //-webkit-box-orient: vertical;
            //overflow: hidden;
            //line-height: 25px;
        }
        .wrapper .merchant-content .tab-inner .vertical .social-media img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
        .tab-inner .vertical .images-box {
            max-width: calc(100vw - 40px);
        }
        .wrapper .content .content-big hr{
            //width: calc(100vw - 16px);
            width: 100vw;
        }
        .wrapper .content .content-big .allImgBox {
            height: calc(((100vw - 16px) * 9) / 16);
            width: calc(100vw - 16px);
        }
        
        .wrapper .content .content-big .businessNews {
            width: 100vw;
            box-sizing: border-box;
            padding: 10px;
        }
        .wrapper .content .content-big #news-large {
            padding: 5px !important;
        }
        .wrapper .content .content-big .businessNews .circleImg {
            min-width: 70px;
        }
        .wrapper .content .content-big .businessNews .circleImg img {
            min-width: 60px;
            min-height: 60px;
            box-sizing: border-box;
        }
        .wrapper .content .content-big .businessNews .newsTitle {
            margin-left: 3px;
        }
        .wrapper .content .content-big .businessNews .shop-title {
            border-bottom: 10px solid  ${(props) => props.theme.white};
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .wrapper .content .content-big .businessNews .shop-desc {
            color: #878787;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 15;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            //word-break: break-all;
        }
        .wrapper .content .content-small.width-small-2 {
            display: none;
        }
        .wrapper .content .content-big.width-big-2 {
            width: 100vw;
            box-sizing: border-box;
            padding-top: 63px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product {
            padding: 0;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title {
            //width: calc(100vw - 16px);
            width: 100vw;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title li {
            //width: 33.3%;
            //width: 50%;
            width: calc(100%/4);
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title #tab-title-two li {
            width: calc(100%/2) !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #tab-large li {
            width: 100% !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title #tab-title-three li {
            width: calc(100%/3) !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title #tab-title-five li {
            width: calc(100%/5) !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo .tab-title li span {
            //font-weight: 550;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box {
            width: 100vw;
            box-sizing: border-box;
            min-width: 0;
            //padding-bottom: 60px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left {
            width: 25vw;
            box-sizing: border-box;
            background-color: #F8FAFB;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left li {
            padding: 15px 5px;
            //width: 25vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left li:not(.active) {
            background-color: transparent;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left li:not(.active) a {
            color: #111314;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left li a {
            font-size: 14px;
            text-align: center;
            width: 100%;
            word-wrap:break-word;
            word-break: break-all;
        }
        
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right {
            width: 80vw;
            min-width: calc(100vw - 120px);
            box-sizing: border-box;
            position: relative;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner {
            padding: 0 0 0 12px;
            // width: 80vw;
            box-sizing: border-box;
            width: 94%;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large{
            padding: 0 10px 0 10px;
            box-sizing: border-box;
            width: 100%;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner p {
            font-size: 15.5px;
            top: 103px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            //box-shadow: 0px 0px 0px transparent;
            //box-sizing: border-box;
            //margin: 10px 0; 
            overflow-x:scroll;
        }
        .tab-inner .tab-box .box-right .box-inner .box-items .item .item-add {
            bottom: 0;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news {
            //margin: 0;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-title {
            font-size: 14px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .price {
            font-size: 14px;
            //margin: 0 5px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .price {
            font-size: 17.5px !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail {
            //margin: 0;
            //margin-right: 10px;
            //margin: 12px 10px 0px 10px;
            height: 70px;
            max-width: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail {
            height: 200px;
            max-width: 200px;
        }

        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail img {
            height: 70px;
            width: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail img {
            height: 200px;
            width: 200px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news {
            width: calc(100% - 80px);
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .item-news {
            width: calc(100% - 12px);
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news * {
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news .item-price {
            max-width: calc(80vw - 165px);
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail .img-msg {
            width: 70px;
            box-sizing: border-box;
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
        }
        .wrapper .content .content-big .allImgBox .topImgBox {
            display:none;
        }
        .wrapper .content .content-big .allImgBox .mainImg {
            position: relative!important;
            height: 100%!important;
            // max-height: 243px;
        }
        .wrapper .content .content-big .allImgBox .mainImg img {
            margin: auto;
            width: calc(100vw - 16px);
            height: calc(((100vw - 16px) * 9) / 16)!important;
            // max-height: 243px;
        }
        .wrapper .content .content-big .allImgBox .mainImg .mainImg_msg {
            width: 100%;
            height: 100%;
        }
        .wrapper .content .content-big .allImgBox .mainImg .mainImg_msg p {
            font-size: 18px;
        }
        .wrapper .mobile-shop-header {
            display: block
        }
        .wrapper .mobile-shop-header .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            align-items: center;
            background-color: ${(props) => props.theme.white };
        }
        .wrapper .mobile-shop-header .mobile-nav .mobile-icon-left .title {
            font-weight: 550;
            margin: 0 20px 0 6px;
            font-size: 15.5px;
            cursor: pointer;
        }
        .wrapper .mobile-shop-header .mobile-nav .mobile-icon-left .app {
            font-weight: 550;
            margin: 0 20px 0 6px;
            font-size: 15px;
            cursor: pointer;
        }
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-right .title {
            font-weight: 550;
            margin: 0 6px 0 20px;
            font-size: 15.5px;
            cursor: pointer;
        }
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-right .app {
            font-weight: 550;
            margin: 0 6px 0 20px;
            font-size: 15px;
            cursor: pointer;
        }
        .wrapper .mobile-shop-header .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-shop-header .mobile-nav .search-back a, 
        .wrapper .mobile-shop-header .mobile-nav .search-back svg {
            height: 26px;
        }
        .wrapper .mobile-shop-header .mobile-nav .search-back .title {
            margin-left: 15px;
            font-size: 20px;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time {
            display: flex;
            align-items: center;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time .time_line {
            font-weight: 400;
            margin: 0 10px 0 13px;
            font-size: 21px;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time .date-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time .date-time span {
            font-size: 10px;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time .now {
            font-weight: 550;
            font-size: 18px;
            margin: 0 5px 0 5px;
            //cursor: pointer;
        }
        .shop-time-dialog {
            width: 100vw;
            max-width: 100vw;
        }
        .shop-time-dialog .MuiDialog-scrollPaper {
            align-items: flex-end;
        }
        .shop-time-dialog .MuiBackdrop-root {
            width: 100vw;
            max-width: 100vw;
        }
        .shop-time-dialog .MuiPaper-root {
            margin: 0;
            width: 100vw;
            max-width: 100vw;
            border-radius: 0;
        }
        .shop-time-dialog .time-dialog-content .title {
            font-size: 18px;
            margin: 15px;
            font-weight: 550;
        }

        .shop-time-dialog .time-dialog-content .date,
        .shop-time-dialog .time-dialog-content .time {
            width: 100%;
            white-space: nowrap;
            padding-bottom: 8px;
        }
        
        .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar,
        .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar {
        }        
        .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar-track,
        .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            background-color: transparent;
            border-radius: 10px
        }       
        .shop-time-dialog .time-dialog-content .date::-webkit-scrollbar-thumb,
        .shop-time-dialog .time-dialog-content .time::-webkit-scrollbar-thumb {
            border: 0px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 9999px;
            background-color: transparent;
        }
            
        .shop-time-dialog .time-dialog-content .date {
            height: auto;
            overflow-y:hidden;
        }
        .shop-time-dialog .time-dialog-content .date button {
            width: 28%;
            margin: 2%;
            padding: 10px 15px;
            background-color: #F3F3F3;
            color: #999999;
            border: 0;
            border-radius: 50px;
            max-width: 100px;
        }
        .shop-time-dialog .time-dialog-content .date button.bt_selected {
            background-color: #2594A2;
            color: #FFFFFF;
        }
        .shop-time-dialog .time-dialog-content .time {
            width: 100%;
            height: auto;
            overflow-y:hidden;
            white-space: nowrap;
        }
        .shop-time-dialog .time-dialog-content .time .btn {
            width: auto;
            margin: 1%;
            padding: 15px 20px;
            background-color: #F3F3F3;
            color: #838383;
            border: 0;
            border-radius: 50px;
        }
        .shop-time-dialog .time-dialog-content .time button:disabled {
            background-color: #F8F8F8;
            color: #E1E1E1;
        }
        .shop-time-dialog .time-dialog-content .time button.bt_selected {
            background-color: #2594A2;
            color: #FFFFFF;
        }
        .shop-time-dialog .time-dialog-content .date-selected {
            width: 100vw;
            border: 0;
            box-sizing: border-box;
            border-top: 1px solid #EDEDED;
            padding: 12px;
        }
        .shop-time-dialog .time-dialog-content .date-selected button {
            width: 95vw;
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
            border: 0;
            padding: 11px;
            border-radius: 5px;
            font-size: 18px;
        }
        .tab-inner .vertical .text-border {
            width: calc(100vw - 90px);
            max-width: calc(100vw - 90px);
            margin: 0 auto;
            box-sizing: border-box;
            word-wrap: break-word;
            //word-break: break-all;
            line-height: 1.2;
            font-size: 15.5px;
        }
        .tab-inner .vertical .text-border {
            width: calc(100vw - 50px);
            max-width: calc(100vw - 50px);
            padding: 3px 15px;
        }
        .tab-inner .vertical .image-border {
            width: calc(100vw - 90px);
            max-width: calc(100vw - 90px);
            margin: 0 auto;
            box-sizing: border-box;
            word-wrap: break-word;
            //word-break: break-all;
            line-height: 1.2;
            font-size: 15.5px;
        }
        .tab-inner .vertical .image-border {
            width: calc(100vw - 50px);
            max-width: calc(100vw - 50px);
            padding: 0px 15px;
        }
        #Post .vertical img:first-child {
            border-top: 15px solid ${(props) => props.theme.white};
        }
        #Post .vertical img:last-child {
            border-bottom: 15px solid ${(props) => props.theme.white};
        }
        .tab-inner .vertical .img-border {
            width: calc(100vw - 50px);
            max-width: calc(100vw - 50px);
        }
        .wrapper #tab-demo #Post .vertical {
            width: calc(100vw - 30px);
            min-width: 0;
            padding: 0;
            padding-bottom: 70px;
        }
        .wrapper #tab-demo #Post .vertical .no-post {
            width: calc(100vw - 50px)!important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical {
            width: calc(100vw - 30px);
            min-width: 0;
            padding: 0;
            padding-bottom: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .about-box {
            padding: 5px;
            width: 100%;
            max-width: 100%;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .about-item {
            padding: 8px 8px 2px 8px;
        }
        .tab-inner .vertical .social-media {
            padding-left: 5px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .about-item span {
            font-size: 18px;
            width: 20px;
            height: 16px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .about-item label {
            font-size: 15px;
            //height: 25px;
            text-overflow: ellipsis;
            display: -webkit-box;
            //-webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 19px;
            max-width: 100%;
            word-wrap: break-word;
            word-break: break-word;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .social-media img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
        
        .wrapper .content .content-big.width-big-2 #tab-demo #About .vertical .social-media-msg {
            justify-content: space-between;
        }
        .mobile-item-dialog {
            display: flex;
            height: 100%;
            width: 100vw;
            max-width: 100vw;
        }
        .mobile-item-dialog .item-dialog-content {
            display: flex;
            flex-direction: column;
            width: calc(100vw - 16px);
            max-width: calc(100vw - 16px);
            padding: 20px;
            box-sizing: border-box;
        }
        .mobile-item-dialog .item-dialog-content .dialog-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: calc(100vw - 40px);
            max-width: calc(100vw - 40px);
            padding-bottom: 70px;
        }
        .mobile-item-dialog .MuiDialog-scrollPaper {
            align-items: flex-end;
        }
        .mobile-item-dialog .MuiBackdrop-root {
            width: 100vw;
            max-width: 100vw;
        }
        .mobile-item-dialog .MuiPaper-root {
            margin: 0;
            width: 100vw;
            max-width: 100vw;
            border-radius: 0;
            height: 70vh;
        }
        .mobile-item-dialog .item-img {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            max-height: 110px;
        }
        .mobile-item-dialog .item-img a {
            width: calc((100vw - 40px)*0.3);
            max-width: 110px;
            max-height: 110px;
        }
        .mobile-item-dialog .item-img img.show,
        .mobile-item-dialog .item-img .item-detail-background.show {
            display: block;
        }
        .mobile-item-dialog .item-img img {
            width: 100%;
            max-width: 110px;
            max-height: 110px;
        }
        .mobile-item-dialog .item-img .view-item {
            width: 100%;
            border: 2px #F8F9FA solid;
            background-color: ${(props) => props.theme.white };
            border-radius: 5px;
            color: ${(props) => props.theme.company_blue };
            margin: 10px auto;
            padding: 15px;
            font-weight: 550;
        }
        .mobile-item-dialog .item-info {
            width: calc((100vw - 40px)*0.7);
            min-width: calc(100vw - 150px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-evenly;
            padding-left: 15px;
        }
        .mobile-item-dialog hr{
            margin: 10px 0;
            width: 100%;
        }
        .mobile-item-dialog .item-info .item-price{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .mobile-item-dialog .item-info .item-price > span{
            font-size: 22px;
        }
        .mobile-item-dialog .item-info .item-price .item-currency {
            color:  ${(props) => props.theme.company_blue };
            font-size: 14px;
        }
        .mobile-item-dialog .item-info .item-price .price {
            margin: 0 8px;
            color:  ${(props) => props.theme.company_blue };
            font-size: 20px;
            font-weight: 550;
        }
        .mobile-item-dialog .item-info .item-price .delete-price {
            font-size: 14px;
            color:  #C1C1C1;
            text-decoration: line-through;
        }
        .mobile-item-dialog .item-info .item-stock {
            font-size: 14px;
        }
        .mobile-item-dialog .item-info .item-stock.normal {
            color:  ${(props) => props.theme.company_blue };
        }
        .mobile-item-dialog .item-info .item-stock.warning {
            color:  #FAA970;
        }
        .mobile-item-dialog .item-info .item-stock.danger {
            color:  #F54039;
        }
        .mobile-item-dialog .item-info .item-data {
            width: 100%;
            font-size: 14px;
            text-align: left;
            font-weight: 550;
        }
        .mobile-item-dialog .item-info .show-detail {
            text-decoration: underline;
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 550;
        }
        .mobile-item-dialog .option {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: left;
        }
        .mobile-item-dialog .option-title-info {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
        .mobile-item-dialog .option-title-info .option-title {
            margin: 0;
        }
        .mobile-item-dialog .option-title-info .msg {
            
            color: #858585;
        }
        .mobile-item-dialog .flex-tag {
            display: flex;
            flex-wrap: wrap;
        }
        .mobile-item-dialog .option-tag {
            padding: 6px 8px ;
            border-radius: 16px;
            background-color: #F8F9FB;
            font-size: 14px;
            font-weight: 550;
            margin: 5px 10px 5px 0;
        }
        .mobile-item-dialog .option-tag[data-type="normal"] {
            border: 0;
        }
        .mobile-item-dialog .option-tag[data-type="selected"] {
            color:  ${(props) => props.theme.company_blue };
            border: 1px solid  ${(props) => props.theme.company_blue };
            background-color: #ECF7F9;
        }
        .mobile-item-dialog .option-tag[data-type="disabled"] {
            color:  #B1B2B5;
            border: 0;
        }
        .mobile-item-dialog .option-tag[data-type="danger"] {
            color:  #F43D36;
            border: 1px solid  #F56762;
        }
        .mobile-item-dialog .item-dialog-footer {
            border-top: 2px solid #D0D0D0;
            border-bottom: 20px solid ${(props) => props.theme.white};
            display: flex;
            flex-direction: row;
            position: fixed;
            bottom: 0;
            padding-top: 10px;
            justify-content: space-between;
            width: calc(100vw - 40px);
            align-items: center;
            background-color: ${(props) => props.theme.white};
        }

        .mobile-item-dialog .item-dialog-footer .amount {
            font-size: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .mobile-item-dialog .item-dialog-footer .amount span {
            font-size: 18px;
            font-weight: 550;
        }
        .mobile-item-dialog .item-dialog-footer .amount label {
            font-size: 20px;
            margin: 0 15px;
        }
        .mobile-item-dialog .item-dialog-footer .footer-right span {
            color: ${(props) => props.theme.red};
            font-size: 18px;
        }
        .mobile-item-dialog .item-dialog-footer .footer-right button {
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
            border: 0;
            padding: 12px 15px;
            border-radius: 30px;
            font-size: 18px;
            margin-left: 18px;
            white-space: nowrap;
        }

        .shop-detail-dialog .MuiPaper-root {
            width: 80vw;
            border-radius: 5px;
        }
        .shop-detail-dialog .item-detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
        }

        .shop-detail-dialog .item-detail .title {
            font-size: 24px;
            color: ${(props) => props.theme.company_blue};
            font-weight: 550;
            margin-bottom: 25px;
        }
        .shop-detail-dialog .item-detail span {
            margin-bottom: 3px;
            text-align: left;
        }
        .shop-detail-dialog .item-detail sup {
            font-size: 8px;
        }
        .shop-detail-dialog .item-detail .item-number,
        .shop-detail-dialog .item-detail .item-date,
        .shop-detail-dialog .item-detail .other-detail {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
        }
        .wrapper .content.item-content {
            display: none;
        }
        .wrapper .mobile-item-main {
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: 100vw;
            position: absolute;
            top: 0;
            left: 0;
            overflow-y: hidden;
        }

        .wrapper .mobile-item-main .mobile-nav {
            display: flex;
            height: 60px;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            background-color: ${(props) => props.theme.white };
        }
        .wrapper .mobile-item-main .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-item-main .mobile-nav .title {
            font-size: 20px;
            margin-left: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-item-main .mobile-item-content {
            padding-top: 110px;
            width: 100vw;
            height: 100vh;
            overflow-y: auto;
        }

        .wrapper .mobile-item-main .mobile-item-content .item-tab {
            // height: calc(100vh - 60px);
            padding-bottom: 70px;
            box-sizing: border-box;
            background-color: #F8F9FB;
        }
        .wrapper .item-tab-title {
            margin-top: 59px;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title {
            display: block;
            position: sticky;
            margin: 0;
            list-style: none;
            background-color: ${(props) => props.theme.white};
            padding-left: 0;
            width: 100vw;
            height: 50px;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title li {
            display: inline-block;
            vertical-align: top;
            margin: 0 -1px -1px 0;
            height: 50px;
            width: calc(100%/4);
            line-height: 50px;
            background: ${(props) => props.theme.white};
            padding: 0 14px;
            list-style: none;
            box-sizing: border-box;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-large li {
            width: 100% !important;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-two li {
            width: calc(100%/2) !important;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-three li {
            width: calc(100%/3) !important;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title #tab-title-five li {
            width: calc(100%/5) !important;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title li.active {
            border-bottom: 3px solid ${(props) => props.theme.company_blue};
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.company_blue};
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-title li span {
            font-weight: 550;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-inner {
            display: flex;
            width: calc(100vw - 16px);
            max-width: 500px;
            margin-left: auto;
            margin-right: auto; 
            // height: calc(100vh - 220px);
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0;
            position: fixed;
            background-color: ${(props) => props.theme.white};
            margin-bottom: 43px;
        }
        .wrapper .mobile-item-main .mobile-item-content .tab-inner:not(.active) {
            display: none!important;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper {
            width: 100vw;
            max-width: 500px;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .swiper-slide {
            width: 100vw!important;
            max-width: 500px!important;
            height: 100vw!important;
            max-height: 500px!important;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .img-mask {
            width: 100vw!important;
            max-width: 500px!important;
            height: 100vw!important;
            max-height: 500px!important;
            position: relative;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .img-swiper .img-mask .mask {
            width: 100vw;
            max-width: 500px;
            height: 100vw;
            max-height: 500px;
            position: absolute;
            z-index: 1;
            background-color: rgba(0,0,0,0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${(props) => props.theme.white};
            font-size: 20px;
            font-weight: 550;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .title {
            font-size: 26px;
            font-weight: 550;
            margin: 10px 0;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .item_category {
            color: #919191;
            margin-bottom: 5px;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .tag-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .tag-list span {
            background-color: #F8FAFB;
            border-radius: 10px;
            margin: 5px;
            line-height: 2em;
            padding: 5px;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info a:visited {
            color: ${(props) => props.theme.black};
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Product .item-info .shop {
            font-size: 18px;
            margin-top: 15px;
            padding-bottom: 2px;
            border-bottom: 1px solid ${(props) => props.theme.black};
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status {
            width: 100vw;
            position: fixed;
            bottom: 66px;    
            z-index: 10;
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status .stock{
            width: 100%;
            text-align: center;
            color: ${(props) => props.theme.white};
            padding: 12px 0;
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status .stock.normal {
            background-color: ${(props) => props.theme.company_blue};
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status .stock.warn {
            background-color: #F7B83F;
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status .stock.danger {
            background-color: #F41C13;
        }
        .wrapper .mobile-item-main .mobile-item-content .item-status .stock.not-available {
            background-color: #969696;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Description {
            background-color: ${(props) => props.theme.white};
            width: 100vw;
            display: flex; 
            align-items: center;
            justify-content: center;
            min-height: calc(100vh - 220px);
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description * {
            color: #9E9E9E;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description span {
            font-size: 60px;

        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Description .no-description p {
            font-weight: 550;
            font-size: 18px;
        }
        // .wrapper .mobile-item-main .mobile-item-footer {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: space-between;
        //     position: fixed;
        //     align-items: center;
        //     bottom: 0px;
        //     width: 100vw;
        //     padding: 0 20px;
        //     box-sizing: border-box;
        //     background-color: ${(props) => props.theme.white};
        //     z-index: 1;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .item-price {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: flex-start;
        //     align-items: baseline;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .item-price span {
        //     color: #949494;
        //     margin-right: 8px;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .item-price span.from {
        //     color: ${(props) => props.theme.black};
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .item-price span.price {
        //     font-size: 22px;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .item-price span.item-delete {
        //     text-decoration: line-through;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .footer-right {
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .footer-right .select {
        //     background-color: ${(props) => props.theme.company_blue};
        //     color: ${(props) => props.theme.white};
        //     border: 0;
        //     padding: 12px 18px;
        //     border-radius: 30px;
        //     font-size: 16px;
        //     margin-right: 15px;
        //     white-space: nowrap;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .footer-right .shop-nav-time {
        //     padding: 20px 0 20px 20px;
        //     border-left: 1px solid #EEEEEE;
        // }
        // .wrapper .mobile-item-main .mobile-item-footer .footer-right span.time_line {
        //     font-size: 26px;
        //     color: ${(props) => props.theme.company_blue};
        // }

        #root .mobile-item-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: fixed;
            align-items: center;
            bottom: 0px;
            width: 100vw;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: ${(props) => props.theme.white};
            z-index: 1;
        }
        #root .mobile-item-footer .item-price {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
        }
        #root .mobile-item-footer .item-price span {
            color: #949494;
            margin-right: 8px;
        }
        #root .mobile-item-footer .item-price span.from {
            color: ${(props) => props.theme.black};
        }
        #root .mobile-item-footer .item-price span.price {
            font-size: 22px;
        }
        #root .mobile-item-footer .item-price span.item-delete {
            text-decoration: line-through;
        }
        #root .mobile-item-footer .footer-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        #root .mobile-item-footer .footer-right .select {
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
            border: 0;
            padding: 12px 18px;
            border-radius: 30px;
            font-size: 16px;
            margin-right: 15px;
            white-space: nowrap;
        }
        #root .mobile-item-footer .footer-right .shop-nav-time {
            padding: 20px 0 20px 20px;
            border-left: 1px solid #EEEEEE;
        }
        #root .mobile-item-footer .footer-right span.time_line {
            font-size: 26px;
            color: ${(props) => props.theme.company_blue};
        }
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }
        
        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .swiper-pagination {
            margin-bottom: 20px;
            width: 107px!important; 
        }
        .swiper-pagination-bullet {
            width: 8px!important;
            height: 8px!important;
            transform: scale(1)!important;
            background-color: ${(props) => props.theme.white};
            opacity: 1;    
            margin: 0 6px!important;
            margin-bottom: 3.5px!important;
        }
        .swiper-pagination-bullet-active {
            width: 15px!important;
            height: 15px!important;    
            margin-bottom: 0px!important;

        }    
        .wrapper .mobile-img-viewer {
            width: 100vw!important;
            height: 100vh!important;
            z-index: 20;
            position: fixed;
            top: 0;
            left: 0;
        }
        .wrapper .mobile-img-viewer .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            align-items: center;
            background-color: ${(props) => props.theme.white };
            height: 64px;
        }
        .wrapper .mobile-img-viewer .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-img-viewer .mobile-nav .img-position {
            font-weight: 550;
            font-size: 22px;
        }
        .wrapper .mobile-img-viewer .img-content {
            width: 100vw;
            height: calc(100vh - 64px);
            margin-top: 64px;
            background-color: ${(props) => props.theme.white };
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .wrapper .mobile-img-viewer .img-content img {
            width: 100%;
            max-width: 100vw;
            max-height: calc(100vh - 64px);
        }
        .wrapper .mobile-img-viewer .viewer-mask {
            width: 100vw;
            height: 100vh;
            background-color: rgba(0,0,0,0.2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            z-index: 50;
            position: absolute;
            top: 0;
            left: 0;
        }
        .wrapper .mobile-img-viewer .viewer-mask button {
            width: 95vw;
            margin-bottom: 10px;
            padding: 25px 0;
            border: 0;
            border-radius: 15px;
            font-size: 20px;
        }
        .wrapper .mobile-img-viewer .viewer-mask .save {
            color: ${(props) => props.theme.company_blue }; 
            background-color: #EFEDED;
        }
        .wrapper .mobile-img-viewer .viewer-mask .cancel {
            color: ${(props) => props.theme.red }; 
            background-color: ${(props) => props.theme.white }; 
        }
        .wrapper .mobile-order-plan-header {
            width: 100vw;
            display: block;
        }
        .wrapper .mobile-order-plan-header .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            align-items: center;
            background-color: ${(props) => props.theme.white };
            height: 64px;
        }
        .wrapper .mobile-order-plan-header .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 
        }
        .wrapper .mobile-order-plan-header .mobile-nav .title {
            font-weight: 550;
            font-size: 22px;
        }
        .wrapper .content.order-plan {
            display: flex;
            width: 100vw;
            height: 100vh;
            padding-top: 64px;
            position: relative;
            box-sizing: border-box;
            min-width: 0;
        }
        .wrapper .content.order-plan .plan-block {
            box-shadow: none;
            width: 100vw;
            height: 100%;
            margin: 0;
            min-width: 0;
        }
        .wrapper .content.order-plan .plan-block .order-plan-title, 
        .wrapper .content.order-plan .plan-block hr {
            display: none;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content {
            flex-direction: column;
            padding: 20px;
            justify-content: flex-start;
            height: calc(100vh - 64px);
            box-sizing: border-box;
            padding-bottom: 120px;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-left {
            width: 100%;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-left > div {
            margin-bottom: 10px;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-right {
            width: calc(100vw - 40px);
            box-sizing: border-box;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-right .shop-title img {
            margin-right: 10px;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-right .item .img-detail {
            max-width: 110px;
            max-height: 110px;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-right .item img {
            max-width: 110px;
            max-height: 110px;
        }
        .wrapper .content.order-plan .plan-block .order-plan-content .content-right .item-news {
            min-width: calc(100% - 110px);
        }
        .wrapper .content.order-plan .plan-block .order-plan-footer {
            position: absolute;
            bottom: 0;
            width: 100vw;
            box-sizing: border-box;
            height: auto;
            z-index: 1;
            background-color: ${(props) => props.theme.white};
        }      
        .wrapper .mobile-order-detail-header {
            width: 100vw;
            display: block;
        }
        .wrapper .mobile-order-detail-header .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 18px 20px 18px 15px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            align-items: center;
            background-color: ${(props) => props.theme.white };
            height: 64px;
        }
        .wrapper .mobile-order-detail-header .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 
        }
        .wrapper .mobile-order-detail-header .mobile-nav .title {
            font-weight: 550;
            font-size: 22px;
        }
        .wrapper .mobile-order-detail-header .mobile-nav .nav-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .mobile-order-detail-header .mobile-nav .nav-right .restorecart {
            font-size: 26px;
        }
        .wrapper .mobile-order-detail-header .mobile-nav .nav-right .exclamation {
            font-size: 20px;
            color: ${(props) => props.theme.red };
            margin-left: 30px;
        }
        .wrapper .content.order-detail {
            display: flex;
            width: 100vw;
            height: 100vh;
            padding-top: 64px;
            position: relative;
            box-sizing: border-box;
            min-width: 0;
        }
        .wrapper .content.order-detail .detail-block {
            box-shadow: none;
            width: 100vw;
            height: 100%;
            margin: 0;
            min-width: 0;
        }
        .wrapper .content.order-detail .detail-block .order-detail-title, 
        .wrapper .content.order-detail .detail-block hr {
            display: none;
        }
        .wrapper .content.order-detail .detail-block .order-detail-content {
            flex-direction: column;
            padding: 20px;
            justify-content: flex-start;
            height: calc(100vh - 64px);
            box-sizing: border-box;
            padding-bottom: 80px;
            background-color: ${(props) => props.theme.white};
        }
        .wrapper .content.order-detail .detail-block .order-detail-content .content {
            width: 100%;
        }
        .wrapper .content.order-detail .detail-block .order-detail-footer {
            position: absolute;
            bottom: 0;
            width: 100vw;
            box-sizing: border-box;
            height: auto;
            z-index: 1;
            background-color: ${(props) => props.theme.white};
            padding: 15px 25px;
            border-top: #EDEDED 1px solid;
        }        
        .wrapper .content.order-detail .detail-block .order-detail-footer div ,
        .wrapper .content.order-detail .detail-block .order-detail-footer span{
            display: none;
        }       
        .wrapper .content.order-detail .detail-block .order-detail-footer button {
            width: calc(100vw - 50px);
            padding: 15px 0;
        }        
    }
 
    @media (min-width: 1595px) and (max-width: 1801px) {
        .content-small .shopping .shopping-plan .full {
            margin: 0;
        }
        .content-small .shopping .shopping-plan .full .plan-item .itemDetail{
            min-width: 200px;
        }
    }
    @media (max-width: 1300px) {
        .wrapper .content .content-small.width-small-2 {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            padding: 0;
            z-index: 10;
        }
        .wrapper .content .content-small.width-small-2.show {
            display: flex;
        }
        .wrapper .content-small .shopping .shopping-title {
            display: none;    
        }
        .wrapper .content#shop-content {
            //overflow-x: overlay;
            //overflow-x: hidden;
            //overflow-y: overlay ;
        }
        .wrapper .content-small .shopping .mobile-nav {
            display: flex;
            flex-direction: row;
            width: 100vw;
            margin: 0 auto;
            padding: 8px 22px 15px 17px;
            text-align: center;
            position: fixed;
            box-sizing: border-box;
            z-index: 5;
            justify-content: space-between;
            border-bottom: #EDEDED 1px solid;
            align-items: center;
            background-color: ${(props) => props.theme.white };
            height: 64px;
        }
        .wrapper .content-small .shopping .mobile-nav .search-back {
            color: #9C9C9C;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .content-small .shopping .mobile-nav .search-back .title {
            margin-left: 15px;
            font-size: 20px;
        }
        .wrapper .content-small .shopping .mobile-nav .shop-nav-time {
            display: flex;
            align-items: center;
        }
        .wrapper .content-small .shopping .mobile-nav .shop-nav-time .time_line {
            font-size: 21px;
            font-weight: 400;
            margin: 0 10px 0 13px;
        }
        .wrapper .content-small .shopping .mobile-nav .shop-nav-time .date-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
        }
        .wrapper .content-small .shopping .mobile-nav .shop-nav-time .date-time span {
            font-size: 10px;
        }
        .wrapper .content-small .shopping .mobile-nav .shop-nav-time .now {
            margin: 0 5px 0 5px;
            font-weight: 550;
            font-size: 18px;
            //cursor: pointer;
        }
        .wrapper .content-small .shopping .mobile-nav .nav-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .wrapper .content-small .shopping .mobile-nav .nav-right .operator-button {
            padding: 6px 0 6px 15px;
            font-weight: 550;
            font-size: 18px;
            border-left: 1px solid #DEDEDE;
        }
        .content-small .shopping .shopping-plan {
            width: 100vw;
            margin-top: 64px;
            padding: 0;
            height: calc(100vh - 64px);
            padding-bottom: 113px;
        }
        .content-small .shopping .shopping-plan .full {
            width: calc(100vw - 30px);
            box-sizing: border-box;
        }
        .content-small .shopping .shopping-plan .full .plan-type {
            max-height: 74px;
        }
        .content-small .shopping .shopping-plan .full .plan-type .circleImg {
            max-height: 74px;
            max-width: 74px;
        }
        .content-small .shopping .shopping-plan .full .plan-type .circleImg img {
            max-height: 60px;
            max-width: 60px;
            margin: 7px;
        }
        .content-small .shopping .shopping-plan .full .plan-item {
            margin: 5px 5px 5px 10px;
            width: calc(100vw - 55px);
        }
        .content-small .shopping .shopping-plan .full .plan-item .itemImg {
            max-width: 100px;
            max-height: 100px;
        }
        .content-small .shopping .shopping-footer {
            position: absolute;
            bottom: -60px;
            height: auto;
            background-color: ${(props) => props.theme.white };
            width: 100vw;
            padding-bottom: 60px;
        }
        .shop-time-dialog {
            width: 100vw;
            max-width: 100vw;
        }
        .wrapper .content .content-big.width-big-2 {
            width: 100%;
            max-width: 100vw;
            //height: calc(100vh - 55px);
        }
        .wrapper .mobile-shop-shopping-plan {
            display: flex;
            width: 100vw;
            border: 0;
            box-sizing: border-box;
            border-top: 1px solid #EDEDED;
            padding: 12px;
            height: 76px;
            position: fixed;
            bottom: 0;
            box-sizing: border-box;
            transition: top 0.4s ease-in-out;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            background-color: ${(props) => props.theme.white};
            z-index: 2;
        }
        .wrapper .mobile-shop-shopping-plan.hideUp {
            bottom: -76px;
        }
        .wrapper .mobile-shop-shopping-plan button {
            width: 100%;
            background-color: ${(props) => props.theme.company_blue};
            color: ${(props) => props.theme.white};
            border: 0;
            padding: 14px;
            border-radius: 5px;
            font-size: 20px;
        }
    }
    @media (max-width: 1120px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left {
            max-width: 200px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-left li {
            max-width: 200px;
            //padding: 15px 10px;
        }
    }
    @media (min-width: 992px) and (max-width: 1825px) {
        .wrapper .content .item-list .box_items {
            //width: calc((100% - 40px) / 2);
        }
        .wrapper .content .item-list .box_items > div.normal,
        .wrapper .content .item-list .box_items > div.photo {
            width: 100%;
            height: calc((((((100vw - 17px) * 0.7) - 360px ) - 40px ) / 32) * 9);
            max-width: 422.3px;
            max-height: 237.54px;
            min-height: calc(((647px - 40px ) / 32) * 9);
        }

        .wrapper .merchant-content .item-list .box_items {
            //width: calc((100% - 40px) / 2);
        }
        .wrapper .merchant-content .item-list .box_items > div.normal,
        .wrapper .merchant-content .item-list .box_items > div.photo {
            width: 100%;
            height: calc((((((100vw - 17px) * 0.7) - 360px ) - 40px ) / 32) * 9);
            max-width: 422.3px;
            max-height: 237.54px;
            min-height: calc(((647px - 40px ) / 32) * 9);
        }
    }
    @media (min-width: 1250px) and (max-width: 1555px) {
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc((100% - 80px) / 4);
        }
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((((100vw - 300px) * 0.97) - 80px) / 64) * 9);
            max-width: 373.5x;
            max-height: 210.09px;
        }
    }
    @media (min-width: 992px) and (max-width: 1250px) {
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc((100% - 40px) / 2);
        }
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((((100vw - 337px) * 0.97) - 40px) / 32) * 9);
            max-width: 423.29px;
            max-height: 238.10px;
        }
    }
    
    @media (max-width: 956px) and (min-width: 670px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            width: calc((100% - 20px) / 2) !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item {
            width: calc(100% - 20px) !important;
        }
    }
    
    
    @media (max-width: 1340px) and (min-width: 1300px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            width: calc((100% - 30px) / 2) !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item {
            width: calc(100% - 20px) !important;
        }
    }
    
    @media (max-width: 991px) and (min-width: 945px) {
        .wrapper .padding_1 {
            padding: 48px 15px;
        }
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc((100% - 60px) / 3);
        }
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((((100vw - 47px) * 0.97) - 60px) / 48) * 9);
            max-width: 300.42px;
            max-height: 168.98px;
        }
        .width-big-1 {
            width: 100%;
            // max-width: calc(100% - 290px);
        }
    }
    @media (max-width: 991px) and (min-width: 955px) {
        .wrapper .merchant-content .item-list .box_items > div.normal,
        .wrapper .merchant-content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100% - 100px ) / 48) * 9);
            max-width: 297.656px;
            max-height: 167.43px;
            min-height: calc(((885px - 60px ) / 48) * 9);
        }
        .wrapper .content .item-list .box_items > div.normal,
        .wrapper .content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100% - 100px ) / 48) * 9);
            max-width: 297.656px;
            max-height: 167.43px;
            min-height: calc(((885px - 60px ) / 48) * 9);
        }
    }
    @media (max-width: 954px) and (min-width: 660px) {
        .wrapper .content .item-list .box_items {
            width: calc((100% - 40px) / 2);
        }
        .wrapper .content .item-list .box_items > div.normal,
        .wrapper .content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 90px ) / 32) * 9);
            max-width: 422px;
            max-height: 237.375px;
            min-height: calc(((590px - 40px ) / 32) * 9);
        }
        .wrapper .merchant-content .item-list .box_items {
            width: calc((100% - 40px) / 2);
        }
        .wrapper .merchant-content .item-list .box_items > div.normal,
        .wrapper .merchant-content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 90px ) / 32) * 9);
            max-width: 422px;
            max-height: 237.375px;
            min-height: calc(((590px - 40px ) / 32) * 9);
        }
    }
    @media (max-width: 768px) {
        .wrapper .home-mobile-header {
            padding: 16px 15px;
        }
        .wrapper .content-small .shopping .mobile-nav {
            padding: 8px 17px 15px;
        }
        .wrapper .mobile-search-content .mobile-nav,
        .wrapper .mobile-map-content .mobile-nav {
            padding: 10px 20px;
        }
        .wrapper .search-filter .mobile-nav ,
        .wrapper .search-map-content .mobile-nav,
        .wrapper .mobile-filter-content .mobile-nav,
        .wrapper .mobile-category-content .mobile-nav, 
        .wrapper .mobile-tag-content .mobile-nav, 
        .wrapper .mobile-payment-content .mobile-nav {
            padding: 15px 20px;
        }
        .wrapper .mobile-cart-content .mobile-nav,
        .wrapper .mobile-setting-content .mobile-nav,
        .wrapper .mobile-planned-orders-content .mobile-nav,
        .wrapper .mobile-contact-info-content .mobile-nav,
        .wrapper .mobile-language-content .mobile-nav,
        .wrapper .mobile-merchant-content .mobile-nav,
        .wrapper .mobile-about-content .mobile-nav {
            padding: 17px 18px;
        }
        .wrapper .merchant-main-filter .mobile-nav,
        .wrapper .mobile-shop-header .mobile-nav,
        .wrapper .mobile-item-main .mobile-nav,
        .wrapper .mobile-img-viewer .mobile-nav,
        .wrapper .mobile-order-plan-header .mobile-nav,
        .wrapper .mobile-order-detail-header .mobile-nav, 
        .wrapper .mobile-merchant-main .mobile-nav {
            padding: 18px 15px;
        }
        .wrapper .merchant-content #tab-demo > ul  {
            top: 55px;
        }
    }
    @media (max-width: 659px) and (min-width: 362px) {
        ::-webkit-scrollbar {
            width: 0px;
        } 
        .wrapper .shareLink{
            margin: 0% 1.5% 0% 0.5%;
        }
        .wrapper .content .item-list .box_items {
            width: calc((100% - 20px));
        }
        .wrapper .content .item-list .box_items > div.normal,
        .wrapper .content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 90px ) / 16) * 9);
            max-width: 569px;
            max-height: 320.062px;
        }
        .wrapper .merchant-content .item-list .box_items {
            width: calc((100% - 20px));
        }
        .wrapper .merchant-content .item-list .box_items > div.normal,
        .wrapper .merchant-content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 90px ) / 16) * 9);
            max-width: 569px;
            max-height: 320.062px;
        }
    }
    
    @media (max-width: 945px) and (min-width: 640px) {
        // .wrapper .padding_1 {
        //     padding: 48px 15px;
        // }
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc((100% - 40px) / 2);
        }
        
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((((100vw - 47px) * 0.97) - 40px) / 32) * 9);
            max-width: 423.775px;
            max-height: 238.37px;
        }
        .width-big-1 {
            width: 100%;
            // max-width: calc(100% - 290px);
        }
    }
    @media (max-width: 991px) and (min-width: 425px) {
        .wrapper .merchant-content .allImgBox {
            // max-height: 380px;
        }
        .wrapper .merchant-content .mainImg img {
            // max-height: 380px;
        }
        .wrapper .content-big.width-big-2 .allImgBox {
            // max-height: 380px;
        }
        .wrapper .content-big.width-big-2 .mainImg {
            // max-height: 380px!important;
        }
        .wrapper .content-big.width-big-2 .mainImg img {
            // max-height: 380px!important;
        }
    }
    @media screen and (min-width: 992px) {
        .wrapper .padding_1 {
            padding: 55px 15px;
        }
        // .width-big-1 {
        //     width: 84%;
        //     max-width: calc(100% - 290px);
        // }
        .wrapper .home-mobile-header {
            display: none;
        }
        .wrapper .mobile-item-main .mobile-item-content #mobile-Description {
            max-width: 500px;
        }
    }
    @media (max-width: 909px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            width: 100%;
        }
    }
    @media (max-width: 640px) {
        // .wrapper .padding_1 {
        //     padding: 48px 15px;
        // }
        .wrapper .content .content-big .content_box_2 {
            margin: 15px 0px;
        }
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc(100% - 20px);
        }
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 67px) / 16) * 9);
            max-width: 590px;
            max-height: 331.875px;
        }
        .width-big-1 {
            width: 100%;
        }
    }
    @media (max-width: 425px) {
        .tab-inner .tab-box .box-right .box-inner .box-items-large {
            margin: 20px 2px !important;
        }
        .tab-inner .tab-box .box-right .box-inner .box-items-large .item {
            display: unset !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail {
            height: 250px!important;
            max-width: 250px!important;
            margin: 15px auto!important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail img {
            height: 250px!important;
            width: 250px!important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            width: calc(80vw - 32px);
            min-width: 0px;
            margin-right: 0;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail {
            //margin: 0;
            margin-right: 10px;
            height: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail img {
            height: 70px;
            width: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail .img-msg {
            width: 70px;
            box-sizing: border-box;
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail .img-msg {
            width: 100% !important;
            font-size: 12px !important;
        }
        .wrapper #tab-demo #Post .vertical {
            width: 100vw;
            padding: 0;
            padding-bottom: 70px;
        }
        .wrapper #tab-demo #Post .vertical img {
            width: 100vw;
            height: auto;
            //border-bottom: 8px solid ${(props) => props.theme.white};
        }
        .tab-inner .tab-box .box-right .box-inner .box-items .item .item-add {
            margin-right: 0;
        }
    }
    @media (max-width: 390px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail {
            width: 250px !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .item-news {
            max-width: 100% !important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .item-news * {
            max-width: 100% !important;
        }
        .wrapper .mobile-shop-header .mobile-nav .mobile-icon-left .title {
            margin: 0 20px 0 2px;
            font-size: 15.5px;
            cursor: pointer;
        }
        .wrapper .mobile-shop-header .mobile-nav .mobile-icon-left .app {
            font-weight: 550;
            margin: 0 20px 0 2px;
            font-size: 15px;
            cursor: pointer;
        }
        //.wrapper .mobile-shop-header .mobile-nav .mobile-icon-left #mobile-lang {
        //    display: none;
        //    visibility: hidden;
        //}
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-right .title {
            margin: 0 2px 0 20px;
            font-size: 15.5px;
            cursor: pointer;
        }
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-right .app {
            font-weight: 550;
            margin: 0 2px 0 20px;
            font-size: 15px;
            cursor: pointer;
        }
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-right #mobile-lang {
            display: none;
            visibility: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item {
            width: calc(80vw - 10px);
            min-width: 0px;
            margin-right: 0;
            overflow-x:scroll;
        }
        .item-news > .item-title {
            -webkit-line-clamp: 1;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news {
            max-width: calc(80vw - 112px);
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news * {
            max-width: calc(80vw - 112px);
            //overflow: hidden;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .item-news .item-price {
            max-width: calc(80vw - 148px);
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail {
            //margin: 0;
            margin-right: 10px;
            height: 70px;
            width: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail img {
            height: 70px;
            width: 70px;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .item .img-detail .img-msg {
            width: 70px;
            box-sizing: border-box;
            font-size: 11px;
            font-weight: 500;
            padding: 4px 0;
            line-height: 1;
        }
    }
    @media (max-width: 400px) {
        .itemDetail-price .item-num > label {
            font-size: 20px;
        }
        .wrapper .content .item-list .box_items .photo .photo_msg {
            font-size: 16px;
        }
        .wrapper .merchant-content .item-list .box_items .photo .photo_msg {
            font-size: 16px;
        }
    }
    @media (max-width: 361px) {
        ::-webkit-scrollbar {
            width: 0px;
        } 
        .wrapper .shareLink{
            margin: 0% 1% 0% 0.5%;
        }
        .wrapper .content .item-list .box_items {
            width: calc(100% - 20px);
            min-width: 0px;
        }
        .wrapper .content .item-list {
            padding: 20px 0;
        }
        .wrapper .content .item-list .box_items > div.normal,
        .wrapper .content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 50px ) / 16) * 9);
            max-width: 311px;
            max-height: 174.938px;
        }
        .wrapper .merchant-content .item-list .box_items {
            width: calc(100% - 20px);
            min-width: 0px;
        }
        .wrapper .merchant-content .item-list {
            padding: 20px 0;
        }
        .wrapper .merchant-content .item-list .box_items > div.normal,
        .wrapper .merchant-content .item-list .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 50px ) / 16) * 9);
            max-width: 311px;
            max-height: 174.938px;
        }
    }
    @media (max-width: 300px) {
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail {
            height: 180px!important;
            max-width: 180px!important;
            margin: 10px auto!important;
        }
        .wrapper .content .content-big.width-big-2 #tab-demo #Product .tab-box .box-right .box-inner .box-items-large .item .img-detail img {
            height: 180px!important;
            width: 180px!important;
        }
        .wrapper .mobile-shop-header .mobile-nav .mobile-icon-left .cart_line {
            display: none;
            visibility: hidden;
        }
        .wrapper .mobile-shop-header .mobile-nav .shop-nav-time .time_line {
            margin: 0px 2px 0px 13px;
        }
        .mobile-time {
            display: none;
            visibility: hidden;
        }
        .wrapper .content .content-big .content_box_2 .box_items,
        .wrapper .content .content-big .content_box_2 .item {
            width: calc(100% - 20px);
            min-width: 0px;
        }
        .wrapper .content .content-big .content_box_2 .box_items > div.normal,
        .wrapper .content .content-big .content_box_2 .box_items > div.photo {
            width: 100%;
            height: calc(((100vw - 67px) / 16) * 9);
            max-width: 590px;
            max-height: 331.875px;
        }
        .wrapper .home-mobile-header .header-left .header-location {
            width: calc(100vw - 160px);
        }
    }
    .hide {
        display: none !important;
    }
    .color-disabled{
        color: #9D9D9D!important;
    }
    .item-detail .app-store-icon{
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        display: block;
        width: 9rem;
    }
    .item-detail .app-stores-icon{
        margin-bottom: 30px;
    } 
    .item-detail .copy-hint{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 15px;
    }
    @media (min-width: 640px){
        .item-detail .app-stores-icon{
            display: flex;
            justify-content: center;
        } 
        .item-detail .app-store-icon{
            height: 2.5rem;
            width: auto;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
    .container404{
        width:100%;
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        text-align:center;
        color:#000;
    }
    
    .container404 h1{
        font-size:140px;
        font-weight:900;
        letter-spacing:20px;
        margin:-15px 0px 12px 20px;
        color:#2594a2;
    }
    
    .container404 a{
        text-decoration:none;
        background:#2594a2aa;
        color:#fff;
        padding:18px 55px;
        display:inline-block;
        border-radius:25px;
        font-size:16px;
        letter-spacing:3.5px;
        text-transform:uppercase;
    }
    
    .container404 a:hover{
        background:#2594a2;
    }

    @media (max-width: 270px){
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-left .time_line {
            display: none;
            visibility: hidden;
        }
        .wrapper .mobile-merchant-main .mobile-nav {
            text-align: left !important;
        }
        .wrapper .mobile-merchant-main .mobile-nav .mobile-icon-left .title {
            font-size: 13.5px;
        }
    }
`;
export default pc_style;
