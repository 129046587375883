import React from "react";
import {
  Route,
  Routes,
} from "react-router-dom";

// pages
//import Home from "./pages/Home";
import ShopWithLocation from "./pages/Shop";
import ProductWithLocation from "./pages/Product";
import Setting from "./pages/Setting";
//import ShoppingCart from "./pages/ShoppingCart";
//import OrderPlan from "./pages/OrderPlan";
//import OrderDetail from "./pages/OrderDetail";
import MerchantWithLocation from "./pages/Merchant";
//import Item from "./pages/Item";
import Page404 from "./pages/404";
import ShopListWithLocation from "./pages/ShopList";
//TODO improve static web code
import UserAppRedirect from "./pages/StaticWeb/UserAppRedirect";
import UserAppWebEn from "./pages/StaticWeb/UserAppWeb/UserAppWebEn";
import UserAppWebTc from "./pages/StaticWeb/UserAppWeb/UserAppWebTc";
import UserAppWebSc from "./pages/StaticWeb/UserAppWeb/UserAppWebSc";
import TermsRedirect from "./pages/StaticWeb/TermsRedirect";
import TermsWebEn from "./pages/StaticWeb/TermsWeb/TermsWebEn";
import TermsWebTc from "./pages/StaticWeb/TermsWeb/TermsWebTc";
import TermsWebSc from "./pages/StaticWeb/TermsWeb/TermsWebSc";
import PrivacyRedirect from "./pages/StaticWeb/PrivacyRedirect";
import PrivacyWebEn from "./pages/StaticWeb/PrivacyWeb/PrivacyWebEn";
import PrivacyWebTc from "./pages/StaticWeb/PrivacyWeb/PrivacyWebTc";
import PrivacyWebSc from "./pages/StaticWeb/PrivacyWeb/PrivacyWebSc";

const AppRouter = () => (
    <Routes>
        {/*<Route path="/s/:shopHandle/:handle" element={<Item />} />*/}
        {/* <Route path="/order-plan" element={<OrderPlan />} /> */}
        {/* <Route path="/order-detail" element={<OrderDetail />} /> */}
        {/* <Route path="/shopping-cart" element={<ShoppingCart />} /> */}

        <Route path="/m/:username" element={<MerchantWithLocation />} />
        <Route path="/s/:handle/:producthandle" element={<ProductWithLocation />} />
        <Route path="/s/:handle" element={<ShopWithLocation />} />
        <Route path="/s" element={<ShopListWithLocation />} />
        <Route path="/setting" element={<Setting />} />

        <Route path="/en/terms" element={<TermsWebEn />} />
        <Route path="/tc/terms" element={<TermsWebTc />} />
        <Route path="/sc/terms" element={<TermsWebSc />} />

        <Route path="/en/privacy" element={<PrivacyWebEn />} />
        <Route path="/tc/privacy" element={<PrivacyWebTc />} />
        <Route path="/sc/privacy" element={<PrivacyWebSc />} />

        <Route path="/en/user/app" element={<UserAppWebEn />} />
        <Route path="/tc/user/app" element={<UserAppWebTc />} />
        <Route path="/sc/user/app" element={<UserAppWebSc />} />

        <Route path="/" element={<UserAppRedirect />} />
        <Route path="/user" element={<UserAppRedirect />} />
        <Route path="/user/app" element={<UserAppRedirect />} />
        <Route path="/app" element={<UserAppRedirect />} />
        <Route path="/en" element={<UserAppRedirect />} />
        <Route path="/tc" element={<UserAppRedirect />} />
        <Route path="/sc" element={<UserAppRedirect />} />

        <Route path="/terms" element={<TermsRedirect />} />
        <Route path="/privacy" element={<PrivacyRedirect />} />

        <Route path="*" element={<Page404 />} />
    </Routes>
);

export default AppRouter;