import Dialog from '@mui/material/Dialog';
import React from "react";
import {useTranslation} from "react-i18next";

function SNS(props) {
    const {t} = useTranslation();
    const { onClose, open, media, mediaId } = props;
    return (
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className="sns-dialog icon-dialog">
        <div className="title text-main-color">{t(`${media}`)}</div>
        <div className="sns-dialog-content list-content">
            <div className="content">
                <div className="list">{mediaId}</div>
            </div>
        </div>
        {/*<div className="sns-dialog-content list-content">
            <div className="content">
                <div className="list" onClick={() => {navigator.clipboard.writeText(mediaId)}}>{t("copy")}</div>
                {/* <div className="list">Send Email</div> }
            </div>
        </div>*/}
        
      </Dialog>
    );
  }
function SNSDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
            <div style={{cursor: "pointer"}} className="icon-div" onClick={handleClickOpen}>
                <img src={"../img/icon/"+props.media+".png"} alt=""></img>
            </div>
            <SNS open={open} onClose={handleClose} media={props.media} mediaId={props.mediaId}/>
        </React.Fragment>
    );
}
export default SNSDialog;
