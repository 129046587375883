import React from "react";
import { useTranslation } from "react-i18next";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
//import styled from "styled-components";
import { Link } from "react-router-dom";
// import Button from '@material-ui/core/Button';
// import VideoCard from "../components/VideoCard";
// import VideoGrid from "../styles/VideoGrid";
import '../../font/demo.css';
import '../../font/iconfont.css';
import {changeLanguage} from "../../utils/utils"
//import { getGoogleMapsAccessToken, getUserPos, getPosName, getPosFullName } from "../../utils/pos";
//import WarningDialog from "../WarningDialog";
//import { Autoplay } from "swiper";
//import moment from 'moment-timezone';
import SelectDateDialog from "../item/SelectDateDialog";

/*const StyledLocationDialog = styled(Dialog)`
    & .MuiPaper-root{
        height: 95%;
        width: 28%;
        min-width: 520px;
    }
`;
const DialogContent = withStyles((theme) => ({
    root: {
        padding: "10px 16px",
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
  
const LocationInputChange = () => {
};*/

const Header = (props) => {
    
    const {t, i18n} = useTranslation();

    //const [locationOpen, setLocationOpen] = React.useState(false);
    
    /*const handleLocationOpen = () => {
      setLocationOpen(true);
    };*/
    /*const handleLocationClose = () => {
        setLocationOpen(false);
    };*/

    /*const updateLocation = (e) => {
        handleLocationClose();
        if(props.reloadBodyLocation){
            props.reloadBodyLocation();
        }
    }*/

    const nextLanguage = () => {
        if(i18n.language==="en"){
            changeLanguage("zh_Hant");
        }else if(i18n.language==="zh_Hant"){
            changeLanguage("zh_Hans");
        }else{
            changeLanguage("en");
        }
        if(props.reloadBody && props.selectTime) {
            props.reloadBody(props.selectTime);
        } else if (props.reloadBody) {
            props.reloadBody();
        }
    }

    return (
    <React.Fragment>
        <div className="header">
            <div className="header-item text-md-left">
                <Link to="/">
                    <span className="icon LuluHK a-logowithtext_web header-item-logo"></span>
                </Link>
                { props.merchant &&
                    <p className="header-username">
                        <span className="text-main-color">@{props.merchantData}</span>
                    </p>
                }
                { props.product &&
                    <p className="header-username">
                        <span style={{marginRight: "9.5px", fontSize: "15px" }} className="icon LuluHK time_line text-main-color icon_pattern"></span>
                        <span className="text-main-color">{props.productTime}</span>
                    </p>
                }
            </div>
            {/*<div className="header-item text-md-center">
                { props.location &&
                    <p>
                        <span className="icon LuluHK location_line text-main-color icon_pattern" onClick={ handleLocationOpen }></span>
                        <span className="text-title-color icon_pattern_2" onClick={ handleLocationOpen }>{props.posName}</span>
                    </p>
                }
            </div>*/}
            <div className="header-item text-md-right">
                <ul>
                    { props.time &&    
                        <li style={{ width: "225px", textAlign: "left" }}>
                            <SelectDateDialog actualCurrentTime={props.actualCurrentTime} canSelectTime={props.canSelectTime} selectTime={props.selectTime} serviceTime={props.serviceTime} deviceTime={props.deviceTime} currentTime={props.currentTime} reloadBody={(selectTime) => props.reloadBody(selectTime)}/>
                        </li>
                    }
                    <li>
                        <Link to="#">
                            <p>
                                <span className="text-main-color icon_pattern" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/setting">
                            <p>
                                <span className="icon LuluHK settings_line text-main-color icon_pattern"></span>
                            </p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        {/*<StyledLocationDialog onClose={handleLocationClose} aria-labelledby="customized-dialog-title" id="location-dialog" open={locationOpen} >
            <MuiDialogTitle disableTypography id="customized-dialog-title">
                <span className="MuiTypography-root">{t("chooseLocation")}</span>
                <IconButton aria-label="close" className="close-dialog" onClick={handleLocationClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers style={{ paddingTop: "5px", paddingLeft: "0px", paddingRight: "0px", overflowY: "hidden"}}>
                <div className="map-content">
                    <div className="content_box_1">
                        <p className="text-main-color">
                            <input type="text" onChange={ LocationInputChange } placeholder="Search for location" value={props.posFullName}/>
                        </p>
                    </div>
                    <MapComponent 
                        lat={props.lat}
                        lon={props.lon}
                        setUserPos={props.setUserPos}
                        getGoogleMapsAccessToken={props.getGoogleMapsAccessToken}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn_location_confirm" onClick={updateLocation}>Confirm</button>
            </DialogActions>
                </StyledLocationDialog>*/}
    </React.Fragment>
    );
};

export default Header;
