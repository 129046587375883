import { getI18n } from "react-i18next";

export async function getShopSeoByID(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/seo/"+id+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    })
}

export async function getShopSeo(handle) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/seo-handle/"+handle+"?locale="+getI18n().language).then((res)=>{
        return res.json();
    })
}

export async function getShopList(param) {
    let url = process.env.REACT_APP_API_SERVER+"/shop/query?";
    Object.keys(param).forEach((key,index)=>{

        if(Array.isArray(param[key])){
            param[key].forEach((item)=>{
                if(index!==0) url+="&";
                url += key;
                url += "[]=";
                url += item;
            });
        }else{
            if(index!==0) url+="&";
            url += key;
            url += "=";
            url += param[key];
        }
        
    })
    return fetch(url).then((res)=>{
        //TODO: error handling
        return res.json();
    })
}

export async function getShopCategoryList(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/category/list/"+id+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    })
}

export async function getShopCategoryInfo(id) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/category/info/"+id+"?locale="+getI18n().language).then((res)=>{
        //TODO: error handling
        return res.json();
    })
}

export async function getShopInfoByID(id, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/info/"+id+"?locale="+getI18n().language+"&ts="+selectTime).then(async (res)=>{
        const time = res.headers.get('time');
        const data = await res.json();
        return {
            data,
            time,
        };
    })
}

export async function getShopInfo(handle, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/info-handle/"+handle+"?locale="+getI18n().language+"&ts="+selectTime).then(async (res)=>{
        const time = res.headers.get('time');
        const data = await res.json();
        return {
            data,
            time,
        };
    })
}

export async function getShopProductList(handle, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/product-list-handle/"+handle+"?locale="+getI18n().language+"&ts="+selectTime).then((res)=>{
        //TODO: error handling
        return res.json();
    })
}

export async function getShopProductListByID(id, selectTime) {
    return fetch(process.env.REACT_APP_API_SERVER+"/shop/product-list/"+id+"?locale="+getI18n().language+"&ts="+selectTime).then((res)=>{
        //TODO: error handling
        return res.json();
    })
}