import React, { useState, useCallback, useEffect } from "react";
import "./ImagesViewer.css";

function ImagesViewer({ images }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const showPrevImage = () => {
    setCurrentImage((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const showNextImage = () => {
    setCurrentImage((prevIndex) =>
      Math.min(prevIndex + 1, images.length - 1)
    );
  };

  useEffect(() => {
    if (!isViewerOpen) return;

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        showPrevImage();
      } else if (event.key === "ArrowRight") {
        showNextImage();
      } else if (event.key === "Escape") {
        closeImageViewer();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isViewerOpen, showPrevImage, showNextImage]);

  return (
    <div>
      {images.map((src, index) => (
        <img
          src={src}
          alt=""
          onClick={() => openImageViewer(index)}
          height="130"
          key={index}
          style={{ cursor: "pointer" }}
        />
      ))}

      {isViewerOpen && (
        <div className="image-viewer-overlay" onClick={closeImageViewer}>
          <div className="image-viewer-content" onClick={(e) => e.stopPropagation()}>
          {currentImage > 0 && (<button className="nav-btn prev-btn" onClick={showPrevImage} disabled={currentImage === 0}>
              &#8249;
            </button>)}
            <img src={images[currentImage]} alt="" className="image-viewer-img" />
            {currentImage < images.length - 1 && (<button className="nav-btn next-btn" onClick={showNextImage} disabled={currentImage === images.length - 1}>
              &#8250;
            </button>)}
          </div>
        </div>
      )}
    </div>
  );
}

export default ImagesViewer;