import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";
import React from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment-timezone';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {shopServiceTime} from  "../../utils/utils";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        maxHeight: "90%",
        maxWidth: "25%",
        minWidth: "500px",
    },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: "10px 16px",
}));
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(1),
}));

function SelectDate(props) {
    const {t} = useTranslation();
    const { onClose, open } = props;
    const [showSnack, setShowSnack] = React.useState(false);
    const handleShowSnack = () => {setShowSnack(true);};
    const handleCloseSnack = () => {setShowSnack(false);};
    
    var selectedTime;
    var selectedMin;
    let selectedIndex = 0;
    let dateList = [];
    let minutes = [];
    const MaxCount = 7;

    const timeZone = "Asia/Hong_Kong";
    const startTime = props.actualCurrentTime ? moment.unix(props.actualCurrentTime) : moment(props.deviceTime) ?? moment(); //Display time in user timezone
    let initTime = props.selectTimeText === "Select" ? startTime : props.initTime ? moment.unix(props.initTime) : moment();
    const deviceTime = props.actualCurrentTime ? moment.unix(props.actualCurrentTime).format("MMM DD, HH:mm") : moment(props.deviceTime).format("MMM DD, HH:mm");
    const currentTime = props.actualCurrentTime ? moment.unix(props.actualCurrentTime).tz(timeZone).format("MMM DD, HH:mm") : moment.unix(props.currentTime).tz(timeZone).format("MMM DD, HH:mm");
    const showTimeZone = currentTime !== deviceTime ? true : false;
    const tzOffset = startTime.utcOffset(); //User TimeZone Offset

    let year = startTime.year();
    let month = startTime.month();
    let date = startTime.date();
    let startDate = moment().year(year).month(month).date(date).startOf('day');
    
    function timeEnable(index, minute) {
        if (props.serviceTime == null) return true;
        let dateTime = moment(startDate).add({days: index, minutes: minute}).unix();
        const dateTimeWeekday = moment.unix(dateTime).tz(timeZone).day()
        const dateTimeHour = moment.unix(dateTime).tz(timeZone).format("HH")
        const dateTimeMinute = moment.unix(dateTime).tz(timeZone).format("mm")
        minute = parseInt(dateTimeHour) * 60 + parseInt(dateTimeMinute) ;
        let timeDay = props.serviceTime[dateTimeWeekday];
        if (timeDay.status === shopServiceTime.open24Hour) {
            return true;
        }
        if (timeDay.status === shopServiceTime.closed) {
            return false;
        }
        for (let i = 0; timeDay.intervals != null && i < timeDay.intervals.length; i++) {
            var time = timeDay.intervals[i];
            if (time.begin <= minute && time.end > minute) {
                return true;
            }
        }
        return false;
    }
 
    function refresh(selectedIndex) {
        minutes = [];
        if (selectedIndex === 0) {
            const firstMin = (startTime.hour() * 60 + startTime.minute())
            //minutes.push(firstMin)
            /*if ((moment(initTime).hour() * 60 + moment(initTime).minute()) == (moment(startTime).hour() * 60 + moment(startTime).minute())) {
                selectedMin = "Now";
            }*/
            for (let i = firstMin - firstMin % 15 + 15; i < 24 * 60; i += 15) {
                minutes.push(i);
                /*if ((moment(initTime).hour() * 60 + moment(initTime).minute()) == i) {
                    selectedMin = i;
                }*/
            }
            if (minutes.length === 0) {
                minutes.push(1425)
            }
            if (props.selectTimeText === "Select") {
                for (let i = 0; i < minutes.length; i++) {
                        if (timeEnable(selectedIndex, minutes[i])) {
                            selectedTime = moment(startDate).add({days: selectedIndex, minutes: minutes[i]}).unix();
                            selectedMin = minutes[i];
                            break;
                        } 
                };
            }
            if (!minutes.includes(selectedMin)) {
                selectedTime = null
                selectedMin = null
            }
        } else {
          for (let i = 0; i < 24 * 60; i += 15) {
            minutes.push(i);
            /*if ((moment(initTime).hour() * 60 + moment(initTime).minute()) == i) {
                selectedMin = i;
            }*/
          }
        }
    }

    if (startTime.isAfter(initTime)) {
        initTime = startTime;
    }
    for (let i = 0; i < MaxCount; i++) {
        const momentDate = moment(startDate).add(i,'days');
        const date = momentDate.locale("en").format("MMM DD");
        let dayOfWeek = momentDate.weekday();
        var dayOfWeekText
        if (dayOfWeek === 0) {dayOfWeekText = t('sunday')}
        if (dayOfWeek === 1) {dayOfWeekText = t('monday')}
        if (dayOfWeek === 2) {dayOfWeekText = t('tuesday')}
        if (dayOfWeek === 3) {dayOfWeekText = t('wednesday')}
        if (dayOfWeek === 4) {dayOfWeekText = t('thursday')}
        if (dayOfWeek === 5) {dayOfWeekText = t('friday')}
        if (dayOfWeek === 6) {dayOfWeekText = t('saturday')}
        const finalStartDate = {date: date, dayOfWeek: dayOfWeekText}
        dateList.push(finalStartDate);
    }

    if (startTime.isBefore(initTime)) {
        for (let i = 0; i < MaxCount; i++) {
            selectedIndex = i; //Find out the selected index
            const momentDate = moment(startTime).add(i,'days');
            const startDate = momentDate.locale("en").format("YYYY MMM DD");
            const initDate = initTime.locale("en").format("YYYY MMM DD");
            if (startDate === initDate) {
                break;
            }
        }
    }

    if (timeEnable(selectedIndex, initTime.hour() * 60 + initTime.minute())) {
        selectedMin = initTime.hour() * 60 + initTime.minute()
        selectedTime = moment(startDate).add({days: selectedIndex, minutes: (initTime.hour() * 60 + initTime.minute())}).unix()
    } /*else {
        selectedMin = "Now"
        selectedTime = "Now"
    }*/

    refresh(selectedIndex);

    const [chosenSelectedIndex, setChosenSelectedIndex] = React.useState(selectedIndex);
    const [timeItems, setTimeItems] = useStateWithCallbackLazy(minutes);
    const [chosenSelectedMin, setChosenSelectedMin] = React.useState(selectedMin);
    const [chosenSelectedTime, setChosenSelectedTime] = React.useState(selectedTime);

    if (startTime.isAfter(moment.unix(chosenSelectedTime))) {
        if (chosenSelectedTime != null && moment.unix(chosenSelectedTime).format("MMM DD") !== startTime.format("MMM DD")) {
            window.location.reload();
    }
    }

    const dateOnClick = (e, i) => {
        if (document.querySelector(".lightbox-content .bt_selected") != null){
            document.querySelector(".lightbox-content .bt_selected").classList.remove("bt_selected");
        }
        if (chosenSelectedIndex !== i) {
            if (document.getElementById(chosenSelectedMin) != null){
                document.getElementById(chosenSelectedMin).classList.remove("bt_selected");
            }
        }
        e.currentTarget.classList.add("bt_selected");
        if (chosenSelectedIndex !== i) {
            selectedIndex = i;
            setChosenSelectedIndex(selectedIndex);
            refresh(selectedIndex);
            selectedTime = null;
            for (let i = 0; i < minutes.length; i++) {
                if (timeEnable(selectedIndex, minutes[i])) {
                    selectedTime = moment(startDate).add({days: selectedIndex, minutes: minutes[i]}).unix();
                    setChosenSelectedTime(selectedTime);
                    selectedMin = minutes[i];
                    setChosenSelectedMin(minutes[i]);
                    break;
                } 
            };
            if (selectedTime == null) {
                selectedTime = null;
                selectedMin = null;
                setChosenSelectedTime(selectedTime);
                setChosenSelectedMin(selectedMin);
            };
            setTimeItems(minutes, () => {
                if (document.getElementById(selectedMin) != null) {
                    document.getElementById(selectedMin).classList.add("bt_selected");
                    document.getElementById(selectedMin).scrollIntoView({/*behavior: "smooth",*/block: "center", inline: "center"})
                }
            });
        };
    };
    const timeOnClick = (e) => {
        if (document.getElementById(chosenSelectedMin) != null){
            document.getElementById(chosenSelectedMin).classList.remove("bt_selected");
        }
        if (document.querySelector(".lightbox-content .time-items .bt_selected") != null){
            document.querySelector(".lightbox-content .time-items .bt_selected").classList.remove("bt_selected");
        }
        /*if (e.target.value == "Now") {
            selectedTime = "Now"
        } else {
            selectedTime = moment(startDate).add({days: chosenSelectedIndex, minutes: e.target.value}).unix();
        }*/
        setChosenSelectedTime(moment(startDate).add({days: chosenSelectedIndex, minutes: e.target.value}).unix());
        setChosenSelectedMin(e.target.value);
        e.currentTarget.classList.add("bt_selected");
    };
    //TODO Bugs: when user has selected a time, say Mar 19 23:55, then user select again on Mar 20 00:13, the timeitems will be stayed as Now until user reselect that day
    //Current Temporary solution, add a few windows reload under several scenarios
    const onConfirm = () => {
        if (chosenSelectedTime == null && selectedTime == null) {
            window.location.reload();
        } else {
            if (chosenSelectedTime == null || isNaN(chosenSelectedTime)) {
                setChosenSelectedMin(selectedMin);
                setChosenSelectedIndex(selectedIndex);
                setChosenSelectedTime(selectedTime);
                setTimeItems(minutes);
                if (props.selectTimeText === "Select") {
                    onClose();
                } else {
                    onClose(selectedTime);
                }
            } else {
                onClose(chosenSelectedTime);
            }
        }
    }
    const onDialogClose = () => {
        if (chosenSelectedTime == null && (props.selectTimeText === "Select" || selectedTime == null)) {
            window.location.reload();
        } else {
            setChosenSelectedMin(selectedMin);
            setChosenSelectedIndex(selectedIndex);
            setChosenSelectedTime(selectedTime);
            setTimeItems(minutes);
            if (props.selectTimeText === "Select") {
                onClose();
            } else {
                onClose(selectedTime);
            }
        }
    }

    React.useEffect(() => {
        if (document.getElementById(chosenSelectedMin) != null) {
            document.getElementById(chosenSelectedMin).scrollIntoView({/*behavior: "smooth",*/block: "center", inline: "center"})
        }
    }, [chosenSelectedMin]);

    return (
        <StyledDialog onClose={() => onDialogClose(chosenSelectedTime)} aria-labelledby="customized-dialog-title" 
        open={open} 
        ref={() => {}}
        //onRendered={() => {}}
        TransitionProps={{ onEntering: () => {
            if (document.getElementById(chosenSelectedMin) != null) {
                document.getElementById(chosenSelectedMin).classList.add("bt_selected");
                document.getElementById(chosenSelectedMin).scrollIntoView({/*behavior: "smooth",*/block: "center", inline: "center"})
            }
        } }}
        //onEntered={() => {}}
        >
            <DialogTitle disableTypography id="customized-dialog-title">
                {showTimeZone 
                    ? <span className="MuiTypography-root">{t("selectedDate")} ( {t("yourTimeZone")} : UTC{tzOffset >= 0 ? "+" : ""}{tzOffset / 60} )</span>
                    : <span className="MuiTypography-root">{t("selectedDate")}</span>
                }
                <IconButton aria-label="close" className="close-dialog" onClick={() => onDialogClose(chosenSelectedTime)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <StyledDialogContent dividers style={{ paddingTop: "0px", overflowY: "hidden" }}>
                <div className="lightbox-content">
                    <p>{t("date")}</p>
                    <div className="date-items">
                    {dateList.map((date, index)=>{
                        if (index === chosenSelectedIndex) {
                            if (index === 0) {
                                return (
                                    <button key={"csi"+index+date} value={date} className="btn bt_selected" onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{t("today")}</button>
                                )
                            } else if (index === 1) {
                                return (
                                    <button key={"csi"+index+date} value={date} className="btn bt_selected" onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{t("tomorrow")}</button>
                                )
                            } else {
                                return (
                                    <button key={"csi"+index+date} value={date} className="btn bt_selected" onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{date.dayOfWeek}</button>
                                )
                            }

                        } else {
                                if (index === 0) {
                                    return (
                                        <button key={index+date} className="btn" value={date} onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{t("today")}</button>
                                    )
                                } else if (index === 1) {
                                    return (
                                        <button key={index+date} className="btn" value={date} onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{t("tomorrow")}</button>
                                    )
                                } else {
                                    return (
                                        <button key={index+date} className="btn" value={date} onClick={(e) => {dateOnClick(e, index)}}>{date.date}<br/>{date.dayOfWeek}</button>
                                    )
                                }

                            }
                            })
                        } 
                    </div>
                    <p>{t("time")}</p>
                            <div className="time-items">
                                {timeItems.map((minute, index)=>{
                                    const hours = (minute / 60);
                                    const rhours = Math.floor(hours);
                                    const minutes = (hours - rhours) * 60;
                                    const rminutes = Math.round(minutes);
                                    const text = rhours.toString().padStart(2, "0") + ":"  + rminutes.toString().padStart(2, "0");
                                    /*if (chosenSelectedIndex == 0 && index == 0) {
                                        if (chosenSelectedMin == "Now") {
                                            return(<button id={"Now"} value={"Now"} onClick={ timeOnClick } className="bt_selected">Now</button>)
                                        } else {
                                            return(<button id={"Now"} value={"Now"} onClick={ timeOnClick }>Now</button>)
                                        }
                                    } else {*/
                                        if (timeEnable(chosenSelectedIndex,minute)) {
                                        if (minute === chosenSelectedMin) {
                                            return(<button key={"csm"+index+minute} style={{cursor: "pointer"}} id={minute} value={minute} onClick={ timeOnClick } className="btn bt_selected">{text}</button>)
                                        } else {
                                            return (<button key={"csi"+index+minute} style={{cursor: "pointer"}} id={minute} value={minute} onClick={ timeOnClick } className="btn">{text}</button>)
                                        }
                                    } else {
                                        return (
                                            <React.Fragment key={index+minute}><button id={minute} value={minute} onClick={handleShowSnack} style={{opacity: "0.4"}} className="btn">{text}</button>
                                            <Snackbar open={showSnack} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                                <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                                                    {t("noServiceHours")}
                                                </Alert>
                                            </Snackbar>
                                            </React.Fragment>
                                        )
                                    }
                               // }
                                })
                                } 
                            </div>
                        </div>
                <StyledDialogActions>
                    <button className="btn btn_ok" onClick={() => onConfirm(chosenSelectedTime)}>{t("ok")}</button>
                </StyledDialogActions>
            </StyledDialogContent>
        </StyledDialog>
    );
  }
function SelectDateDialog(props) {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [showSnack, setShowSnack] = React.useState(false);
    const [showTime, setShowTime] = React.useState(false);

    const timeZone = "Asia/Hong_Kong";
    const currentTime = props.actualCurrentTime ? moment.unix(props.actualCurrentTime).tz(timeZone).format("MMM DD, HH:mm") : moment.unix(props.currentTime).tz(timeZone).format("MMM DD, HH:mm");
    const [selectTimeText, setSelectTimeText] = React.useState("Select")

    /*const handleOpen = () => {
        setOpen(true);
    };*/
    const handleClose = (selectTime) => {
        setOpen(false);
        if (isNaN(selectTime) || selectTime == null) {
            setSelectTimeText("Select");
            setShowTime(false);
        } else {
            setSelectTimeText(moment.unix(selectTime).format("MMM DD, HH:mm")); //Display time in user timezone
            setShowTime(true);
        }
        if(props.reloadBody){
            if (isNaN(selectTime) || selectTime == null) {
                setShowTime(false);
                props.reloadBody();
                //props.reloadBody(moment(moment(props.deviceTime).format("YYYY MMM DD, HH:mm")).unix());
            } else {
                props.reloadBody(selectTime);
            }
        }
    };
    const handleNowOnClick = () => {
        setShowTime(false);
        setSelectTimeText("Select");
        if(props.reloadBody){
            props.reloadBody();
        }
    };

    /*const handleShowSnack = () => {
        setShowSnack(true);
    };*/

    const handleCloseSnack = () => {
        setShowSnack(false);
    };

    if (props.canSelectTime) {
        if (showTime) { 
            return (
                <React.Fragment>
                    <span className="openBox">
                        <p>
                            <span style={{ /*cursor: "pointer", */marginLeft: "10px", marginRight: "9.5px", fontSize: "15px" }} onClick={ handleNowOnClick } className="icon LuluHK time_line text-main-color icon_pattern"></span>
                            <span style={{ /*cursor: "pointer", */fontWeight: "300", fontSize: "15px"}} onClick={ handleNowOnClick } className="text-main-color icon_pattern color-disabled">Now</span>
                            {/*<><span className="color-disabled">|</span>
                            <span style={{ cursor: "pointer", marginLeft: "10px", marginRight: "9.5px", fontSize: "15px" }} onClick={ handleOpen } className="icon LuluHK time_line text-main-color icon_pattern"></span>
                            <span style={{ cursor: "pointer", fontWeight: "550", fontSize: "15.5px" }} onClick={ handleOpen } className="text-main-color icon_pattern_3">{selectTimeText}</span></>*/}
                        </p>
                    </span>
                    <SelectDate open={open} selectTimeText={selectTimeText} initTime={props.selectTime} onClose={(selectTime) => handleClose(selectTime)} deviceTime={props.deviceTime} currentTime={currentTime} actualCurrentTime={props.actualCurrentTime} serviceTime={props.serviceTime}/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <span className="openBox">
                        <p>
                            <span style={{ /*cursor: "pointer", */marginLeft: "10px", marginRight: "9.5px", fontSize: "15px" }} onClick={ handleNowOnClick } className="icon LuluHK time_line text-main-color icon_pattern"></span>
                            <span style={{ /*cursor: "pointer", */fontWeight: "550", fontSize: "15.5px" }} onClick={ handleNowOnClick } className="text-main-color icon_pattern">Now</span>
                            {/*<><span className="color-disabled">|</span>
                            <span style={{ cursor: "pointer", marginLeft: "10px", marginRight: "9px", fontWeight: "300", fontSize: "14px" }} onClick={ handleOpen } className="icon LuluHK time_line icon_pattern color-disabled"></span>
            <span style={{ cursor: "pointer", fontWeight: "300", fontSize: "15px" }} onClick={ handleOpen } className="icon_pattern_3 color-disabled">{selectTimeText}</span></>*/}
                        </p>
                    </span>
                    <SelectDate open={open} selectTimeText={selectTimeText} initTime={props.selectTime} onClose={(selectTime) => handleClose(selectTime)} deviceTime={props.deviceTime} currentTime={currentTime} actualCurrentTime={props.actualCurrentTime} serviceTime={props.serviceTime}/>
                </React.Fragment>
            );        
        }
    } else {
        return (
            <React.Fragment>
                <span className="openBox">
                    <p>
                        <span style={{ /*cursor: "pointer", */marginLeft: "10px", marginRight: "9.5px", fontSize: "15px" }} onClick={ handleNowOnClick } className="icon LuluHK time_line text-main-color icon_pattern"></span>
                        <span style={{ /*cursor: "pointer", */fontWeight: "550", fontSize: "15.5px" }} onClick={ handleNowOnClick } className="text-main-color icon_pattern">Now</span>
                        {/*<><span className="color-disabled">|</span>
                        <span style={{ marginLeft: "10px", marginRight: "9px", fontWeight: "300", fontSize: "14px" }} onClick={ handleShowSnack } className="icon LuluHK time_line icon_pattern color-disabled"></span>
                        <span style={{ fontWeight: "300", fontSize: "15px" }} onClick={ handleShowSnack } className="icon_pattern_3 color-disabled">{selectTimeText}</span></>*/}
                    </p>
                </span>
                <Snackbar open={showSnack} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                        {t("noServiceHoursAvailable")}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        );
    };
}
export default SelectDateDialog;