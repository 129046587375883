import { getI18n } from "react-i18next";
import moment from 'moment';

export const shopStatusCode = {
  draft: 0,
  active: 1,
  temporarilyClosed: 2,
  permanentlyClosed: 3,
  underRenovation: 4,
}

export const eventStatusCode = {
  unlisted: 0,
  active: 1,
}

export const productStatusCode = {
  draft: 0,
  active: 1,
  temporarilyUnavailable: 2,
  unavailable: 3,
}

export const priceStatusCode = {
  priceStatusPrice: 0,
  priceStatusMarket: 1,
  priceStatusUnavailable: 100,
}

export const stockCode = {
  stockIn: 1,
  stockLow: 2,
  stockInOrLow: 3,
  stockOut: 4,
  stockInOrOut: 5,
  stockLowOrOut: 6,
  stockInOrLowOrOut: 7,
}

export const shopServiceTime = {
  custom: 0,
  closed: 1,
  open24Hour: 2,
}

export const productSaleTime = {
  custom: 0,
  unavailable: 1,
  sameAsShop: 2,
  sameAsCommon: 3,
}

export const searchTypeCode = {
  product: 0,
  shop: 1,
  merchant: 2,
}

export const sortCode = {
  relevance: "scoreDesc",
  distance: "distanceAsc",
  priceAsc: 2,
  priceDesc: 3,
}


//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function calcCrow(lat1, lon1, lat2, lon2) 
{
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}

export function getTimeRep(requiredTime,type,showDate = false){ //type: either 'open' or 'available'
  let lang = "";
  switch(getI18n().language){
    case "zh_Hant":
      lang = "zh_Hant";
      moment.locale("zh-hk"); 
      break;
    case "zh_Hans":
      lang = "zh_Hans";
      moment.locale("zh-cn"); 
      break;
    default:
      lang = "en";
      moment.locale("en"); 

  }
  let currentTime = new Date();
  let currentTimeDateFormat = new Date(requiredTime);
  if(currentTime.getDate()===currentTimeDateFormat.getDate() && !showDate){ //today
    return getI18n().t(type+"AtToday",{time:moment(requiredTime).format("HH:mm")});
  }else if(currentTime.getDate()+1===currentTimeDateFormat.getDate() && !showDate) //tomorrow (TODO: when day is next month)
    return getI18n().t(type+"AtTomorrow",{time:moment(requiredTime).format("HH:mm")});
  else{
    if (lang === "zh_Hant" || lang ==="zh_Hans") {
      return getI18n().t(type+"On",{time:moment(requiredTime).format("MMMDo HH:mm")});
    } else {
      return getI18n().t(type+"On",{time:moment(requiredTime).format("MMM DD HH:mm")});
    }
  }
  
}

export function getDateRep(requiredTime){
  switch(getI18n().language){
    case "zh_Hant":
      moment.locale("zh-hk"); 
      break;
    case "zh_Hans":
      moment.locale("zh-cn"); 
      break;
    default:
      moment.locale("en"); 

  }
  return moment(requiredTime).format("ll");
  
}

export function changeLanguage(lang){
  getI18n().changeLanguage(lang);
  localStorage.setItem("language",lang);
}

export function categoryToLevel(categories) {
  let result = [];
  const set = new Set();
  const map = {};
  for (const c of categories) {
    map[c.id] = c;
    set.add(c.id);
  }
  for (const c of categories) {
    if (c.parentID) set.delete(c.parentID);
  }
  for (let c of categories) {
      if (!set.has(c.id)) continue;
      set.delete(c.id);
      let list = [c];

      while (c.parentID) {
        c = map[c.parentID];
        list.push(c);
      }
      
      let s = "";

      for (const i of list.reverse()) {
        if (s !== '') s += ' > ';
        s += i.name;
      }
      result.push(s);
  }
  return result;
}

export function getDeltaOps(htmlString) {
  var finalOutput = []
  for (var i = 0; i < htmlString.length; i++) {
    const html = htmlString[i]
    if ("attributes" in html) {
      const attributeText = JSON.stringify(html.attributes)
      if (attributeText === "{\"block\":\"ol\"}") {html.attributes = {list:"ordered"}}
      if (attributeText === "{\"block\":\"ul\"}") {html.attributes = {list:"bullet"}}
      if (attributeText === "{\"block\":\"quote\"}") {html.attributes = {blockquote:true}}
      if (attributeText === "{\"b\":true}") {html.attributes = {bold:true}}
      if (attributeText === "{\"i\":true}") {html.attributes = {italic:true}}
      if (attributeText === "{\"heading\":1}") {html.attributes = {header:1}}
      if (attributeText === "{\"heading\":2}") {html.attributes = {header:2}}
      if (attributeText === "{\"heading\":3}") {html.attributes = {header:3}}
    }
    finalOutput.push(html)
  };
  return finalOutput
}
