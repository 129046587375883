import React from "react";
import { useTranslation } from "react-i18next";
import {changeLanguage} from "../../utils/utils"

const Language = () => {
    const {i18n} = useTranslation();

    //TODO: Device language?
    return (
        <div className="language-content sub-content active" id="language">
            <div className="language-block">
                <input type="radio" className="language" value="tc" id="tc" name="language" checked={i18n.language==="zh_Hant"} onClick={(e)=>changeLanguage("zh_Hant")}/>
                <label for="tc" style={{cursor: "pointer"}}>繁體中文</label>
            </div>
            <div className="language-block">
                <input type="radio"  className="language" value="cn" id="cn" name="language" checked={i18n.language==="zh_Hans"} onClick={(e)=>changeLanguage("zh_Hans")} />
                <label for="cn" style={{cursor: "pointer"}}>简体中文</label>
            </div>
            <div className="language-block">
                <input type="radio"  className="language" value="en" id="en" name="language" checked={i18n.language==="en"} onClick={(e)=>changeLanguage("en")} />
                <label for="en" style={{cursor: "pointer"}}>English</label>
            </div>
        </div>
    )
}
export default Language;  