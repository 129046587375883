import { getI18n } from "react-i18next";

export async function getShopList(limit = 12, skip = 0, lat = 22.288706, lon = 114.172773) {
  //const categories = [];
  //const categoriesQueryString = categories.map(category => `categories[]=${category}`).join('&');
  //const tags = [/*8520001,8520002*/];
  //const tagsQueryString = tags.map(tag => `tags[]=${tag}`).join('&');
  const params = {
    limit,
    skip,
    lat,
    lon,
    //merchantManage: true,
  };
  const queryString = new URLSearchParams(params).toString();
  //const url = `${process.env.REACT_APP_API_SERVER}/shop/query?${queryString}&${categoriesQueryString}&${tagsQueryString}&locale=${getI18n().language}`;
  const url = `${process.env.REACT_APP_API_SERVER}/shop/query?${queryString}&locale=${getI18n().language}`;
  const res = await fetch(url);
  const data = await res.json();
  return data;
}