import Dialog from '@mui/material/Dialog';
import React from "react"
import { useTranslation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';

function WarningDialog(props) {
    const {t} = useTranslation();
    const { open, onClose} = props;
    return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className="shop-detail-dialog">
      <div className="item-detail">
          {props.title && <div className="title">{props.title}</div>}
          <div style={{width: "100%"}}>
              {props.children || //Default content: use our app dialog
              <>
              {props.source === "app" ? <><Tooltip title={t("openApp")}>
            {<div className="text-main-color copy-hint" style={{cursor: "pointer"}} onClick={() => window.open(props.shareLink, "_blank")}>
              <span style={{fontWeight: "bold", textAlign: "center"}}>{t('openAppIfInstall')}</span>
              </div>}
            </Tooltip>
              <hr></hr></> : null}
              <img src="/logo_round200.png" alt="luluhk" style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
                display: "block",
                width: "100px",
              }}/>
              <p style={{fontWeight: "bold"}}>{t("useOurApp1")}</p>
              <p>{t("useOurApp2")}</p>
              <div className="app-stores-icon">
                <a href="https://testflight.apple.com/join/caGmshwq" target="_blank" rel="noreferrer"><img src={"/img/"+t("appStoreIcon")} alt="apple" className="app-store-icon"/></a>
                <a href="https://play.google.com/store/apps/details?id=com.luluhk.luluhk" target="_blank" rel="noreferrer"><img src={"/img/"+t("googlePlayIcon")} alt="google" className="app-store-icon"/></a>
              </div>
              </>}
          </div>
      </div>
    </Dialog>
    );
  }
export default WarningDialog;