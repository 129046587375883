const EmptyError = (props) => {
    return(
        <div className="empty-result">
            <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
            <span className="text">{props.msg?props.msg:"No results found"}</span>
        </div>
    );
}

export default EmptyError;
