import React from "react";
import { useTranslation } from "react-i18next";
import DownloadAppDialog from  "../DownloadAppDialog";
import EmptyLoading from "../EmptyLoading";
import {productStatusCode, shopStatusCode, stockCode, priceStatusCode, getTimeRep} from  "../../utils/utils";

function ProductItem(props){

    const {t, i18n} = useTranslation();
    const [shareLink/*, setShareLink*/] = React.useState(process.env.REACT_APP_LINK +"/s/"+ props.shopHandle + '/' + props.handle + '?id=' + props.id);

    let item_tag = props.tagList.map((tag, i) => 
        <span key={ i }>{ tag.name }</span>
    )

    let needCover, statusMsg;
        switch(props.status){
            case productStatusCode.active:
                if(props.isAvailable){
                    needCover = false;
                    if (props.stock === stockCode.stockOut) {
                        needCover = true;
                        statusMsg = t("stockOut");
                    }
                }else{
                    needCover = true;
                    if (props.stock === stockCode.stockOut) {
                        statusMsg = t("stockOut");
                    }else if(props.nextAvailableTime){
                        statusMsg = getTimeRep(props.nextAvailableTime,"available",true);
                    }else if(props.shopStatus===shopStatusCode.temporarilyClosed){
                        statusMsg = t("productShopTemporarilyClosed");
                    }else if(props.shopStatus===shopStatusCode.permanentlyClosed){
                        statusMsg = t("productShopPermanentlyClosed");
                    }else if(props.shopStatus===shopStatusCode.underRenovation){
                        statusMsg = t("productShopUnderRenovation");
                    }else if(!props.isOpen){
                        statusMsg = t("shopClosedToday");
                    }else{
                        statusMsg = t("productUnavailableToday");
                    }
                }
                break;
            case  productStatusCode.unavailable:
                needCover = true;
                statusMsg = t("productUnavailable");
                break;
            case  productStatusCode.temporarilyUnavailable:
                needCover = true;
                if(props.shopStatus===shopStatusCode.temporarilyClosed){
                    statusMsg = t("productShopTemporarilyClosed");
                }else if(props.shopStatus===shopStatusCode.permanentlyClosed){
                    statusMsg = t("productShopPermanentlyClosed");
                }else if(props.shopStatus===shopStatusCode.underRenovation){
                    statusMsg = t("productShopUnderRenovation");
                }else if(props.shopStatus===shopStatusCode.active && !props.isOpen){
                    statusMsg = t("shopClosedToday");
                }else{
                    statusMsg = t("productTemporarilyUnavailable");
                }
                break;
            default:
                needCover = true;
                statusMsg = t("notInSaleTime");
                break;
        }
    var discount = false;
    var low = props.priceLow;
    var high = props.priceHigh;
    var currency = "HKD";
    var lowResult = (low != null && low[currency] != null) ? low[currency] / 100 : null;
    var low2String = (lowResult != null && lowResult.toString() !== "NaN") ? lowResult.toString() : "-";
    if (low2String.includes(".")) {
        if(low2String.length - low2String.indexOf(".") !== 3) {
            low2String += "0";
        }
    }
    var discountOriginLow2String = low2String;
    if (props.priceStatus === priceStatusCode.priceStatusPrice) {
        if (low != null && low[currency] != null) {
            if (props.priceDiscountLow[currency] !== props.priceLow[currency] || props.priceDiscountHigh[currency] !== props.priceHigh[currency]) {
                discount = true;
                low = props.priceDiscountLow;
                high = props.priceDiscountHigh;
                lowResult = (low != null && low[currency] != null) ? low[currency] / 100 : null;
                low2String = (lowResult != null && lowResult.toString() !== "NaN") ? lowResult.toString() : "-";
                if (low2String.includes(".")) {
                    if(low2String.length - low2String.indexOf(".") !== 3) {
                        low2String += "0";
                    }
                }
            }
        }
    }

    var sharePrice = t("priceUnavailable");
    if (props.status !== productStatusCode.unavailable) {
        if (low2String !== "-" && props.priceStatus === priceStatusCode.priceStatusPrice) {
            if (low[currency] !== high[currency]) {
                (i18n.language==="en") ? sharePrice = t("priceRangeFormatFrom") + " HKD " + low2String : sharePrice = "HKD " + low2String + " " + t("priceRangeFormatFrom")
            } else {
                sharePrice = "HKD " + low2String
            }
        } else if (props.priceStatus === priceStatusCode.priceStatusMarket) {
            sharePrice = t("priceMarket")
        }
    }

    const [openDownloadApp, setOpenDownloadApp] = React.useState(false);
  
    const handleClickOpenDownloadApp = () => {
        setOpenDownloadApp(true);
    };
  
    const handleCloseDownloadApp = () => {
        setOpenDownloadApp(false);
    };

    const productUrl = (props.selectTime != null && props.selectTime !== "") ? '/s/'+ props.shopHandle + "/" + props.handle + "?ts=" + props.selectTime : '/s/'+ props.shopHandle + "/" + props.handle

    if (shareLink) {
        return(
            <React.Fragment>
                { needCover === false
                    ?
                    <div className="item">
                        <DownloadAppDialog source={"product"} open={openDownloadApp} onClose={handleCloseDownloadApp} shareLink={shareLink} title={props.title} shopAddress={props.shopAddress} shopWhatsapp={props.shopWhatsapp} shopPhone={props.shopPhone} sharePrice={sharePrice} shopName={props.shopName}/>
                        {props.source==="product" ? 
                            <>
                            <div className="img-detail" onClick={handleClickOpenDownloadApp}>
                                <img src={ props.imgSrc } alt={ props.imgAlt }/>
                            </div>
                            <div className="item-news" onClick={handleClickOpenDownloadApp}>
                                <h4 className="item-title">{ props.title }</h4>
                                {props.category === "uncategorized" ? null : <div className="item-category">{props.category}</div>}
                                <div className="item-tag">{ item_tag }</div>
                                <div className="item-price">
                                    {props.status !== productStatusCode.unavailable && low2String !== "-" && props.priceStatus === priceStatusCode.priceStatusPrice
                                    ? (low[currency] !== high[currency] && discount
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>&nbsp;
                                                <h5>{t("priceRangeFormatFrom")}</h5>
                                            </div>
                                        ): low[currency] !== high[currency] && !discount 
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5>{t("priceRangeFormatFrom")}</h5>
                                            </div>
                                        ): low[currency] === high[currency] && discount 
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                        ): (i18n.language==="en" ? 
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                        )
                                    ): (props.status !== productStatusCode.unavailable && props.priceStatus === priceStatusCode.priceStatusMarket
                                    ? <div><h5>{t("priceMarket")}</h5></div>
                                    : <div><h5>{t("priceUnavailable")}</h5></div>)
                                    }
                                </div>
                            </div>
                            {/* <span className="item-add" onClick={handleOpen}>+</span> */}
                            </>
                        :
                            <>
                            <a href={productUrl} target="_blank" rel="noopener noreferrer">
                                <div className="img-detail">
                                    <img src={ props.imgSrc } alt={ props.imgAlt }/>
                                </div>
                            </a>
                            <a href={productUrl} target="_blank" rel="noopener noreferrer">
                                <div className="item-news">
                                    <h4 className="item-title">{ props.title }</h4>
                                    <div className="item-tag">{ item_tag }</div>
                                    <div className="item-price">
                                        {props.status !== productStatusCode.unavailable && low2String !== "-" && props.priceStatus === priceStatusCode.priceStatusPrice
                                        ? (low[currency] !== high[currency] && discount
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>&nbsp;
                                                    <h5>{t("priceRangeFormatFrom")}</h5>
                                                </div>
                                            ): low[currency] !== high[currency] && !discount 
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5>{t("priceRangeFormatFrom")}</h5>
                                                </div>
                                            ): low[currency] === high[currency] && discount 
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                            ): (i18n.language==="en" ? 
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                            )
                                        ): (props.status !== productStatusCode.unavailable && props.priceStatus === priceStatusCode.priceStatusMarket
                                        ? <div><h5>{t("priceMarket")}</h5></div>
                                        : <div><h5>{t("priceUnavailable")}</h5></div>)
                                        }
                                    </div>
                                </div>
                                {/* <span className="item-add" onClick={handleOpen}>+</span> */}
                            </a>    
                            </>
                        }
                    </div>
                    :
                    <div className="item item-opacity">
                        <DownloadAppDialog style={{zIndex: "50"}} source={"product"} open={openDownloadApp} onClose={handleCloseDownloadApp} shareLink={shareLink} title={props.title} shopAddress={props.shopAddress} shopWhatsapp={props.shopWhatsapp} shopPhone={props.shopPhone} sharePrice={sharePrice} shopName={props.shopName} />
                        {props.source==="product" ?
                            <>
                            <div className="img-detail" onClick={handleClickOpenDownloadApp}>
                                <img src={ props.imgSrc } alt={ props.imgAlt }/>
                                <div className="img-msg">{ statusMsg }</div>
                            </div>
                            <div className="item-news" onClick={handleClickOpenDownloadApp}>
                                <h4 className="item-title">{ props.title }</h4>
                                {props.category === "uncategorized" ? null : <div className="item-category">{props.category}</div>}
                                <div className="item-tag">{ item_tag }</div>
                                <div className="item-price">
                                {props.status !== productStatusCode.unavailable && low2String !== "-" && props.priceStatus === priceStatusCode.priceStatusPrice
                                    ? (low[currency] !== high[currency] && discount
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>&nbsp;
                                                <h5>{t("priceRangeFormatFrom")}</h5>
                                            </div>
                                        ): low[currency] !== high[currency] && !discount 
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5>{t("priceRangeFormatFrom")}</h5>
                                            </div>
                                        ): low[currency] === high[currency] && discount 
                                        ? (i18n.language==="en" ? 
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                            </div>
                                        ): (i18n.language==="en" ? 
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                            :
                                            <div>
                                                <h5 className="text-main-color">HKD</h5>&nbsp;
                                                <h4 className="text-main-color price">{low2String }</h4>
                                            </div>
                                        )
                                    ): (props.status !== productStatusCode.unavailable && props.priceStatus === priceStatusCode.priceStatusMarket
                                    ? <div><h5>{t("priceMarket")}</h5></div>
                                    : <div><h5>{t("priceUnavailable")}</h5></div>)
                                    }
                                </div>
                            </div>
                            {/* <span className="item-add" onClick={handleOpen}>+</span> */}
                            </> 
                        :
                            <>
                            <a href={productUrl} target="_blank" rel="noopener noreferrer">
                                <div className="img-detail">
                                    <img src={ props.imgSrc } alt={ props.imgAlt }/>
                                    <div className="img-msg">{ statusMsg }</div>
                                </div>
                            </a>
                            <a href={productUrl} target="_blank" rel="noopener noreferrer">
                                <div className="item-news">
                                    <h4 className="item-title">{ props.title }</h4>
                                    <div className="item-tag">{ item_tag }</div>
                                    <div className="item-price">
                                    {props.status !== productStatusCode.unavailable && low2String !== "-" && props.priceStatus === priceStatusCode.priceStatusPrice
                                        ? (low[currency] !== high[currency] && discount
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{ low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>&nbsp;
                                                    <h5>{t("priceRangeFormatFrom")}</h5>
                                                </div>
                                            ): low[currency] !== high[currency] && !discount 
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5>{t("priceRangeFormatFrom")}</h5>&nbsp;
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5>{t("priceRangeFormatFrom")}</h5>
                                                </div>
                                            ): low[currency] === high[currency] && discount 
                                            ? (i18n.language==="en" ? 
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>&nbsp;
                                                    <h5 className="item-delete line">{ discountOriginLow2String }</h5>
                                                </div>
                                            ): (i18n.language==="en" ? 
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                                :
                                                <div>
                                                    <h5 className="text-main-color">HKD</h5>&nbsp;
                                                    <h4 className="text-main-color price">{low2String }</h4>
                                                </div>
                                            )
                                        ): (props.status !== productStatusCode.unavailable && props.priceStatus === priceStatusCode.priceStatusMarket
                                        ? <div><h5>{t("priceMarket")}</h5></div>
                                        : <div><h5>{t("priceUnavailable")}</h5></div>)
                                        }
                                    </div>
                                </div>
                                {/* <span className="item-add" onClick={handleOpen}>+</span> */}
                            </a>
                            </>
                        }
                    </div>     
                }
            </React.Fragment>  
        )
    }else{ //EmptyLoading
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-big width-big-2">
                        <EmptyLoading msg={t("loading")}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default ProductItem;
