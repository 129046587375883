import { Helmet } from "react-helmet";
import Header from  "../components/item/Header";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {changeLanguage} from "../utils/utils";
import React from "react";
import { withTranslation } from 'react-i18next';

class Page404 extends React.Component{
    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this); //to be deleted in full web
        if(localStorage.getItem("language")){
            changeLanguage(localStorage.getItem("language"));
        }
    }
    
    goBack(){
        try {
            this.props.history.goBack();
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t} = this.props;
        return (
            <>
            <Helmet>
                <title>{t('404NotFound')}</title>
                <meta name="description" content="404 Not Found - Lulu HK"/>
                <meta name="author" content="Lulu HK Technologies Limited"/>
                <meta property="og:title" content="路路香港 LULU HK" />
                <meta property="og:description" content="香港 Hong Kong | 泊車指南 Parking Directory" />
                <meta property="og:image" content="/img/luluhk/luluhk_og.png" />
            </Helmet>
            <div className="wrapper">
                <Header />
                <div className="mobile-item-main">
                    <div className="mobile-nav">
                        <div className="search-back">
                            <ArrowBackIosIcon onClick={this.goBack}/>
                            <span className="text-main-color title"></span>
                        </div>
                    </div>
                    <div className="mobile-item-content">
                        <div class="container404">
                            <h2>{t('pageNotFound')}</h2>     
                            <h1>404</h1>
                            <a href="/">{t('goBackHome')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
    }

export default withTranslation()(Page404);
