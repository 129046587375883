
import Router from "./Router";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { normalTheme } from "./styles/theme";
import  DesktopStyle  from  "./styles/DesktopStyle";
import ReactGA from 'react-ga4';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const emotionCache = createCache({
    key: 'custom',
    nonce: localStorage.getItem('nonce') || 'default_nonce',
});

const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
};

const App = () => {
  usePageTracking();

  return(
    <CacheProvider value={emotionCache}>
    <ThemeProvider theme={ normalTheme }>
      <DesktopStyle />
      <ToastContainer
        autoClose={2500}
        position="top-right"
        closeButton={false}
      />
      <Router />
    </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
