import React from "react"
import { withTranslation } from "react-i18next";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../font/demo.css'
import '../font/iconfont.css'
import '../styles/shop.css'
import '../font/app_iconfont.js'
import Header from  "../components/item/Header"
import Page404 from "./404";
import EmptyError from "../components/EmptyError";
import EmptyLoading from "../components/EmptyLoading";
import ShoppingPlan from  "../components/item/ShoppingPlan"
import ProductItem from "../components/shop/ProductItem"
import WarningDialog from  "../components/WarningDialog";
import {getProductInfo, getProductInfoByID} from "../services/product";
import {categoryToLevel} from  "../utils/utils";
import {changeLanguage} from "../utils/utils";
import { confirmAlert } from 'react-confirm-alert';
import '../styles/react-confirm-alert.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment-timezone';
import {getCurrentTime} from "../services/common";

class Product extends React.Component{
    constructor(props){
        super(props)
        this.productChange = this.productChange.bind(this);
        this.lastScrollY = 0;
        this.goBack = this.goBack.bind(this);
        this.state = {
            product: null,
            hasError: "",
            shareLink: null,
            selectTime: null,
          };
    }
    async componentDidMount() { 
        window.addEventListener('scroll', this.handleScroll);
        
        const {t, i18n} = this.props;
        const handle = this.props.params.producthandle;
        const shopHandle = this.props.params.handle;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const queryLocale = params.get('locale') ? params.get('locale').toLowerCase() : "";
        var locale = "";

        if (queryLocale === "tc" || queryLocale === "zhhant" || queryLocale === "zh_hant" || queryLocale === "zh-hant" || queryLocale === "zhhk" || queryLocale === "zh_hk" || queryLocale === "zh-hk" || queryLocale === "zhtw" || queryLocale === "zh_tw" || queryLocale === "zh-tw" || queryLocale === "zhmo" || queryLocale === "zh_mo" || queryLocale === "zh-mo") {
            locale = "zh_Hant"
        } else if (queryLocale === "sc" || queryLocale === "zhhans" || queryLocale === "zh_hans" || queryLocale === "zh-hans" || queryLocale.slice(0,2) === "zh") {
            locale = "zh_Hans"
        } else if (queryLocale === "en" || queryLocale.slice(0,2) === "en") {
            locale = "en";
        }
        if (locale === "") {
            if(localStorage.getItem("language")){
                i18n.changeLanguage(localStorage.getItem("language"));
            }
        } else {
            i18n.changeLanguage(locale);
            localStorage.setItem("language",locale);
        }

        async function isValidTime(unixTime) {
            let now = moment();

            try {
                const time = await getCurrentTime("UserWeb");
                if (time) {
                  now = moment.unix(time);
                  return performValidityCheck(now);
                }
            } catch (err) {
                return performValidityCheck(now);
            }

            function performValidityCheck(now) {
              let year = now.year();
              let month = now.month();
              let date = now.date();
              let startOfDayNow = moment().year(year).month(month).date(date).startOf('day');
              let endOfDaySevenDaysLater = moment(startOfDayNow).add(6, 'days').endOf('day');
            
              if (!moment.unix(unixTime).isBefore(startOfDayNow) && !moment.unix(unixTime).isAfter(endOfDaySevenDaysLater)) {
                return true
              } else {
                return false
              }
            }

            return false;
        }

        let querySelectTime = params.get('ts');
        if (querySelectTime != null) {
            const isValid = await isValidTime(querySelectTime);
            if (!isValid) {
                querySelectTime = null;
            }
        }
        const selectTime = this.state.selectTime ?? querySelectTime;
        const queryID = params.get('id');

        if (queryID != null && queryID !== "") {
            getProductInfoByID(queryID, selectTime).then((res)=>{
                if(res.data){
                    if (res.data.tags.find(c => c.id === "18")) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                            return (
                                <div className='react-confirm-alert-body'>
                                    <h1>{t("adultTagWarningTitle")}</h1>
                                    <p>{t("adultTagWarningContent")}</p>
                                    <div className='react-confirm-alert-button-group'>
                                        <button className='warning-button' 
                                            onClick={() => {
                                                onClose();
                                                window.location.replace("https://www.luluhk.com");
                                            }}>
                                                {t("adultTagWarningNo")}
                                        </button>
                                        <button
                                            onClick={() => {
                                                onClose();
                                                this.setState({selectTime: selectTime, product: res.data});
                                                        this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                                            }}
                                        >
                                            {t("adultTagWarningYes")}
                                        </button>
                                    </div>
                                </div>
                            );
                            },
                            closeOnClickOutside: false
                        });  
                    } else {
                        this.setState({selectTime: selectTime, product: res.data});
                            this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                    };
                    //params.delete('id')
                    window.history.replaceState(null, '', `/s/${res.data.shopHandle}/${res.data.handle}`);
                }else{ //invalid shop id
                    getProductInfo(shopHandle, handle, selectTime).then((res)=>{
                        if(res.data){
                            if ((res.data.tags.find(c => c.id === "18"))) {
                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                    return (
                                        <div className='react-confirm-alert-body'>
                                            <h1>{t("adultTagWarningTitle")}</h1>
                                            <p>{t("adultTagWarningContent")}</p>
                                            <div className='react-confirm-alert-button-group'>
                                                <button className='warning-button' 
                                                    onClick={() => {
                                                        onClose();
                                                        window.location.replace("https://www.luluhk.com");
                                                    }}>
                                                        {t("adultTagWarningNo")}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        onClose();
                                                        this.setState({selectTime: selectTime, product: res.data});
                                                            this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                                                    }}
                                                >
                                                    {t("adultTagWarningYes")}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                    },
                                    closeOnClickOutside: false
                                });  
                            } else {
                                this.setState({selectTime: selectTime, product: res.data});
                                    this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                            };
                        }else{ //invalid shop handle
                            this.setState({hasError: "productInfoError"});
                        }    
                        //params.delete('id')
                        window.history.replaceState(null, '', `/s/${shopHandle}/${handle}`);
                    }).catch((err)=>{
                        //params.delete('id')
                        window.history.replaceState(null, '', `/s/${shopHandle}/${handle}`);
                        this.setState({hasError: "netError"})
                    }); 
                }
            }).catch((err)=>{
                this.setState({hasError: "netError"})
            }); 
        } else {
            getProductInfo(shopHandle, handle, selectTime).then((res)=>{
                if(res.data){
                    if (res.data.tags.find(c => c.id === "18")) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                            return (
                                <div className='react-confirm-alert-body'>
                                    <h1>{t("adultTagWarningTitle")}</h1>
                                    <p>{t("adultTagWarningContent")}</p>
                                    <div className='react-confirm-alert-button-group'>
                                        <button className='warning-button' 
                                            onClick={() => {
                                                onClose();
                                                window.location.replace("https://www.luluhk.com");
                                            }}>
                                                {t("adultTagWarningNo")}
                                        </button>
                                        <button
                                            onClick={() => {
                                                onClose();
                                                this.setState({selectTime: selectTime, product: res.data});
                                                    this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                                            }}
                                        >
                                            {t("adultTagWarningYes")}
                                        </button>
                                    </div>
                                </div>
                            );
                            },
                            closeOnClickOutside: false
                        });  
                    } else {
                        this.setState({selectTime: selectTime, product: res.data});
                            this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
                    };
                }else{ //invalid shop handle
                    this.setState({hasError: "productInfoError"});
                }
                //params.delete('id')
                window.history.replaceState(null, '', `/s/${shopHandle}/${handle}`);
            }).catch((err)=>{
                //params.delete('id')
                window.history.replaceState(null, '', `/s/${shopHandle}/${handle}`);
                this.setState({hasError: "netError"})
            }); 
        }   
    } 
    componentDidUpdate(prev) {
        if(prev.params.handle!==this.props.params.handle || prev.params.productHandle!==this.props.params.productHandle || prev.location.search!==this.props.location.search){
            this.componentDidMount();
        } 
    } 
    reloadBody(shopHandle, handle, selectTime){
        getProductInfo(shopHandle, handle, selectTime).then((res)=>{
            if(res.data){
                this.setState({selectTime: selectTime, product: res.data});
                    this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.shopHandle + '/' + res.data.handle + '?id=' + res.data.id});
            }else{ //invalid shop handle
                this.setState({hasError: "productInfoError"});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError"})
        });
    }
    componentWillUnmount() { 
        window.removeEventListener('scroll', this.handleScroll);
    } 
    tabChange(e){
        document.querySelector(".tab-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".tab-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }

    productChange(e){
        document.querySelector(".box-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".box-right .box-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }
    handleScroll() {
        if(document.querySelector(".wrapper .mobile-shop-shopping-plan")){
            let scrollClass = document.querySelector(".wrapper .mobile-shop-shopping-plan").classList;
            var st = this.scrollY;
            // 判斷是向上捲動，而且捲軸超過 200px
            if (st < this.lastScrollY) {
                scrollClass.remove('hideUp');
            } else {
                scrollClass.add('hideUp');
            }
            this.lastScrollY = st;
        }
    }
    showMobilePlan(e){
        document.querySelector('.wrapper .content .content-small.width-small-2').classList.add('show');
        document.querySelector('.wrapper .content .content-big.width-big-2').classList.add('hide');
        
    }
    goBack(){
        try {
            this.props.navigate(-1);
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t, i18n} = this.props;
        const { product, hasError, shareLink, selectTime} = this.state;
        const nextLanguage = () => {
            if(i18n.language==="en"){
                changeLanguage("zh_Hant");
                this.reloadBody(shopHandle, handle, selectTime);
            }else if(i18n.language==="zh_Hant"){
                changeLanguage("zh_Hans");
                this.reloadBody(shopHandle, handle, selectTime);
            }else{
                changeLanguage("en");
                this.reloadBody(shopHandle, handle, selectTime);
            }
            /*if(props.reloadBody){
                props.reloadBody();
            }*/
        }

        const handle = this.props.params.producthandle;
        const shopHandle = this.props.params.handle;
        
        if(product && !hasError){
            let result = [];
            result = categoryToLevel(product.categories);
            var desc = ""
            if (result === undefined || result.length === 0 || (result.length === 1 && result[0].trim() === '')) {
                desc = "uncategorized"
            } else {
            for (var i = 0; i < result.length; i++) {
                if (i===6) {break}
                var categoryDesc = (i === result.length - 1 || i === 5) ? result[i].split('>').pop().trim() : `${result[i].split('>').pop().trim()}\xa0 • \xa0`
                desc = desc + categoryDesc
            }}
            return (
            <React.Fragment>
                <Helmet>
                    <title>{`${product.name} - ${product.shopName} - Lulu HK`}</title>
                    <meta property="og:title" content={`${product.name} - ${product.shopName} - Lulu HK`} />
                    <meta property="og:description" content={`${product.shopAddress}`} />
                    <meta property="og:image" content={`${product.cover.substring(0, product.cover.lastIndexOf("."))}_luluimg-m.jpg`} />
                    <meta name="description" content={`${product.shopAddress}`}/>
                </Helmet>
                <div className="wrapper">
                    <Header reloadBody={()=>this.reloadBody(shopHandle, handle, selectTime)}></Header>
                    <div className="mobile-shop-header">
                        <div className="mobile-nav">
                        <div className="mobile-icon-left">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                        <div className="mobile-icon-right">
                        </div>
                        </div>
                    </div>
                    <div className="mobile-shopping-plan-content hide">
                        <div className="shopping-plan-content">
                            <div className="empty-plan">
                            </div>
                        </div>
                    </div>
                    <div className="content" id="shop-content">
                        <div className="content-big width-big-2">
                            <div className="businessNews" id={"news-large"} style={{cursor: "pointer"}} onClick={() => window.open(`https://www.luluhk.com/s/${product.shopHandle}?id=${product.shopID}`, "_self")}>
                                <div className="newsTitle">
                                    <span className="text-main-color shop-title">{product.shopName}</span>
                                    <span className="shop-desc">{product.shopAddress}</span>
                                </div>
                                <div className="shareLink text-darksub-color">
                                    <p><ArrowForwardIosIcon/></p>
                                </div>
                            </div>
                            <hr/>
                            <div id="tab-demo">
                                <ul className="tab-title" id="tab-large">
                                    <li key="product" className="active"><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                </ul>
                                <div id="Product" className="tab-inner active">
                                    <div className="tab-box">
                                        <div className="box-right" id="box-large">
                                        <div className="box-inner">
                                            <div className="box-items-large">
                                                <ProductItem
                                                    key={product.id}
                                                    id={product.id}
                                                    handle={product.handle}
                                                    shopHandle={product.shopHandle}
                                                    status = {product.status}
                                                    shopStatus = {product.shopStatus}
                                                    stock = {product.stock}
                                                    isAvailable = {product.isAvailable}
                                                    isOpen = {product.isOpen}
                                                    nextAvailableTime = {product.nextAvailableTime}
                                                    imgSrc={product.cover}
                                                    imgAlt={product.name}
                                                    title={product.name}
                                                    tagList= {product.tags}
                                                    priceStatus={product.priceStatus}
                                                    priceLow={product.priceLow}
                                                    priceDiscountLow={product.priceDiscountLow}
                                                    priceHigh={product.priceHigh}
                                                    priceDiscountHigh={product.priceDiscountHigh}
                                                    shopName={product.shopName}
                                                    shopAddress={product.shopAddress}
                                                    shopPhone={product.shopPhones[0]}
                                                    shopWhatsapp={product.shopSNS.whatsapp}
                                                    category={desc}
                                                    source={"product"}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>       
                            </div>
                        </div>
                        <div className="content-small width-small-2">
                            <ShoppingPlan
                                productList = {[]}
                                invalidList = {[]}
                                shopImgSrc = ""
                                shopImgAlt = ""
                                shopTitle = ""
                                total = ""
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
            );
        }else if(hasError){ //header + EmptyError
            if(hasError==="productInfoError"){
                return <Page404 />
            }else{
                return(
                    <React.Fragment>
                    <div className="wrapper">
                    {/*<Header actualCurrentTime={actualCurrentTime} time={true} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={product.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}></Header>*/}
                        <Header productTime="" reloadBody={()=>this.reloadBody(shopHandle, handle, selectTime)}></Header>
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                            <div className="mobile-icon-right">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                            </div>
                        </div>
                        <div className="mobile-shopping-plan-content hide">
                            <div className="shopping-plan-content">
                                <div className="empty-plan">

                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="content-big width-big-2">
                                <EmptyError msg={t("error")+": "+t(hasError)}/>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                );
            }
        }else{ //header + EmptyLoading
            return(
                <React.Fragment>
                    <div className="wrapper">
                        {<Header productTime="" reloadBody={()=>this.reloadBody(shopHandle, handle, selectTime)}></Header>}
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                            <div className="mobile-icon-right">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                            </div>
                        </div>
                        <div className="mobile-shopping-plan-content hide">
                            <div className="shopping-plan-content">
                                <div className="empty-plan">

                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="content-big width-big-2">
                                <EmptyLoading msg={t("loading")}/>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
            );
        }
    }
}

const ProductWithLocation = (props) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return <Product {...props} location={location} params={params} navigate={navigate} />;
};

export default withTranslation()(ProductWithLocation);
