import React from "react"
import { withTranslation } from "react-i18next";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../font/demo.css'
import '../font/iconfont.css'
import '../styles/shop.css'
import '../font/app_iconfont.js'
import Header from  "../components/item/Header"
import BoxItem from "../components/home/BoxItem"
import Page404 from "../pages/404";
import EmptyError from "../components/EmptyError";
import EmptyLoading from "../components/EmptyLoading";
import ShoppingPlan from  "../components/item/ShoppingPlan"
import UsernameRedirect from  "../components/item/UsernameRedirect"
import SNSDialog from  "../components/item/SNSDialog"
import ProductItem from "../components/shop/ProductItem"
import Scrollspy from 'react-scrollspy';
import {getShopInfo, getShopInfoByID, getShopProductList, getShopProductListByID} from "../services/shop";
import {getMerchantShopByID} from "../services/merchant";
import ServiceHoursDialog from "../components/item/ServiceHoursDialog";
import CategoryDialog from "../components/item/CategoryDialog";
import AwardDialog from "../components/item/AwardDialog";
import TagDialog from "../components/item/TagDialog";
import WarningDialog from  "../components/WarningDialog";
import {productStatusCode, shopStatusCode, getTimeRep, categoryToLevel, shopServiceTime} from  "../utils/utils";
import ShareSnack from  "../components/item/ShareSnack"
import moment from 'moment-timezone';
import {changeLanguage, getDeltaOps} from "../utils/utils";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import '../styles/react-confirm-alert.css';
import ImagesViewer from "../components/item/ImagesViewer";

class Shop extends React.Component{
    constructor(props){
        super(props)
        this.productChange = this.productChange.bind(this);
        this.lastScrollY = 0;
        this.goBack = this.goBack.bind(this);
        this.state = {
            shop: null,
            shopList: null,
            productList: null,
            hasError: "",
            currentTime: null,
            actualCurrentTime: null,
            deviceTime: null,
            selectTime: null,
            cartOpen: false,
            shareLink: null,
            loading: true,
          };
    }
    componentDidMount() { 
        window.addEventListener('scroll', this.handleScroll);
        
        const {t, i18n} = this.props;
        const handle = this.props.params.handle;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const queryID = params.get('id'); 
        
        const queryLocale = params.get('locale') ? params.get('locale').toLowerCase() : "";
        var locale = "";

        if (queryLocale === "tc" || queryLocale === "zhhant" || queryLocale === "zh_hant" || queryLocale === "zh-hant" || queryLocale === "zhhk" || queryLocale === "zh_hk" || queryLocale === "zh-hk" || queryLocale === "zhtw" || queryLocale === "zh_tw" || queryLocale === "zh-tw" || queryLocale === "zhmo" || queryLocale === "zh_mo" || queryLocale === "zh-mo") {
            locale = "zh_Hant"
        } else if (queryLocale === "sc" || queryLocale === "zhhans" || queryLocale === "zh_hans" || queryLocale === "zh-hans" || queryLocale.slice(0,2) === "zh") {
            locale = "zh_Hans"
        } else if (queryLocale === "en" || queryLocale.slice(0,2) === "en") {
            locale = "en";
        }
        if (locale === "") {
            if(localStorage.getItem("language")){
                i18n.changeLanguage(localStorage.getItem("language"));
            }
        } else {
            i18n.changeLanguage(locale);
            localStorage.setItem("language",locale);
        }
        //const selectTime = moment(moment(new Date()).format("YYYY MMM DD, HH:mm")).unix();

        //TODO Simplify this part
        if (queryID != null && queryID !== "") {
            getShopInfoByID(queryID, null).then((res)=>{
                if(res.data.data){
                    this.setState({shop: res.data.data});
                    if(res.time){
                        this.setState({actualCurrentTime: res.time});
                    }else{
                        this.setState({actualCurrentTime: moment().unix()});
                    }
                    if ((res.data.data.tags.find(c => c.id === "18")) || (res.data.data.productTags.includes("18"))) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                            return (
                                <div className='react-confirm-alert-body'>
                                    <h1>{t("adultTagWarningTitle")}</h1>
                                    <p>{t("adultTagWarningContent")}</p>
                                    <div className='react-confirm-alert-button-group'>
                                        <button className='warning-button' 
                                            onClick={() => {
                                                onClose();
                                                window.location.replace("https://www.luluhk.com");
                                            }}>
                                                {t("adultTagWarningNo")}
                                        </button>
                                        <button
                                            onClick={() => {
                                                onClose();
                                                this.setState({currentTime: moment().unix()});
                                                this.setState({deviceTime: new Date()});
                                                this.setState({selectTime: null});

                                                getShopProductListByID(queryID, null).then((res)=>{
                                                    if(res.data){
                                                        this.setState({productList: res.data.data});
                                                    }else{ //invalid shop id
                                                        this.setState({hasError: "shopInfoError", loading: false});
                                                }
                                                }).catch((err)=>{
                                                    this.setState({hasError: "netError", loading: false})
                                                }); 

                                                getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                                                    if(res.data){
                                                        let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                                        let handleSet = new Set();
                                                        let shopList = [];
                                                        shopListWithDuplicate.map((shop) => {
                                                            if (!handleSet.has(shop.handle)) {
                                                              handleSet.add(shop.handle);
                                                              shopList.push(shop);
                                                            }
                                                            return shop
                                                          });
                                                        shopList.sort((a,b)=>{
                                                            if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                return 1;
                                                            }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                return -1;
                                                            }else{
                                                                return 0;
                                                            }
                                                        });
                                                        this.setState({shopList: shopList});
                                                    }else{
                                                        this.setState({hasError: "shopInfoError", loading: false});
                                                    }
                                                }).catch((err)=>{
                                                    this.setState({hasError: "netError", loading: false})
                                                });
                                                
                                                this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});
                                            }}
                                        >
                                            {t("adultTagWarningYes")}
                                        </button>
                                    </div>
                                </div>
                            );
                            },
                            closeOnClickOutside: false
                        });  
                    } else {
                        this.setState({currentTime: moment().unix()});
                        this.setState({deviceTime: new Date()});
                        this.setState({selectTime: null});

                        getShopProductListByID(queryID, null).then((res)=>{
                            if(res.data){
                                this.setState({productList: res.data.data});
                            }else{ //invalid shop id
                                this.setState({hasError: "shopInfoError", loading: false});
                            }
                        }).catch((err)=>{
                            //this.setState({hasError: "shopInfoError"});
                            this.setState({hasError: "netError", loading: false})
                        });

                        getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                            if(res.data){
                                let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                let handleSet = new Set();
                                let shopList = [];
                                shopListWithDuplicate.map((shop) => {
                                    if (!handleSet.has(shop.handle)) {
                                      handleSet.add(shop.handle);
                                      shopList.push(shop);
                                    }
                                    return shop
                                  });
                                shopList.sort((a,b)=>{
                                    if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                        return 1;
                                    }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                        return -1;
                                    }else{
                                        return 0;
                                    }
                                });
                                this.setState({shopList: shopList});
                            }else{
                                this.setState({hasError: "shopInfoError", loading: false});
                            }
                        }).catch((err)=>{
                            this.setState({hasError: "netError", loading: false})
                        });

                        this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});
                    };
                    //params.delete('id')
                    window.history.replaceState(null, '', `/s/${res.data.data.handle}`);
                }else{ //invalid shop id
                    getShopInfo(handle, null).then((res)=>{
                        if(res.data.data){
                            this.setState({shop: res.data.data});
                            if(res.time){
                                this.setState({actualCurrentTime: res.time});
                            }else{
                                this.setState({actualCurrentTime: moment().unix()});
                            }
                            if ((res.data.data.tags.find(c => c.id === "18")) || (res.data.data.productTags.includes("18"))) {
                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                    return (
                                        <div className='react-confirm-alert-body'>
                                            <h1>{t("adultTagWarningTitle")}</h1>
                                            <p>{t("adultTagWarningContent")}</p>
                                            <div className='react-confirm-alert-button-group'>
                                                <button className='warning-button' 
                                                    onClick={() => {
                                                        onClose();
                                                        window.location.replace("https://www.luluhk.com");
                                                    }}>
                                                        {t("adultTagWarningNo")}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        onClose();
        
                                                        this.setState({currentTime: moment().unix()});
                                                        this.setState({deviceTime: new Date()});
                                                        this.setState({selectTime: null});
        
                                                        getShopProductList(handle, null).then((res)=>{
                                                            if(res.data){
                                                                this.setState({productList: res.data.data});
                                                            }else{ //invalid shop handle
                                                                this.setState({hasError: "shopInfoError", loading: false});
                                                        }
                                                        }).catch((err)=>{
                                                            //this.setState({hasError: "shopInfoError"});
                                                            this.setState({hasError: "netError", loading: false})
                                                        });

                                                        getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                                                            if(res.data){
                                                                let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                                                let handleSet = new Set();
                                                                let shopList = [];
                                                                shopListWithDuplicate.map((shop) => {
                                                                    if (!handleSet.has(shop.handle)) {
                                                                      handleSet.add(shop.handle);
                                                                      shopList.push(shop);
                                                                    }
                                                                    return shop
                                                                  });
                                                                shopList.sort((a,b)=>{
                                                                    if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                        return 1;
                                                                    }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                        return -1;
                                                                    }else{
                                                                        return 0;
                                                                    }
                                                                });
                                                                this.setState({shopList: shopList});
                                                            }else{
                                                                this.setState({hasError: "shopInfoError", loading: false});
                                                            }
                                                        }).catch((err)=>{
                                                            this.setState({hasError: "netError", loading: false})
                                                        });

                                                        this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});                       
                                                    }}
                                                >
                                                    {t("adultTagWarningYes")}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                    },
                                    closeOnClickOutside: false
                                });  
                            } else {
                                this.setState({currentTime: moment().unix()});
                                this.setState({deviceTime: new Date()});
                                this.setState({selectTime: null});
        
                                getShopProductList(handle, null).then((res)=>{
                                    if(res.data){
                                        this.setState({productList: res.data.data});
                                    }else{ //invalid shop handle
                                        this.setState({hasError: "shopInfoError", loading: false});
                                    }
                                }).catch((err)=>{
                                    //this.setState({hasError: "shopInfoError"});
                                    this.setState({hasError: "netError", loading: false})
                                });
                                
                                getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                                    if(res.data){
                                        let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                        let handleSet = new Set();
                                        let shopList = [];
                                        shopListWithDuplicate.map((shop) => {
                                            if (!handleSet.has(shop.handle)) {
                                              handleSet.add(shop.handle);
                                              shopList.push(shop);
                                            }
                                            return shop
                                          });
                                        shopList.sort((a,b)=>{
                                            if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                return 1;
                                            }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                return -1;
                                            }else{
                                                return 0;
                                            }
                                        });
                                        this.setState({shopList: shopList});
                                    }else{
                                        this.setState({hasError: "shopInfoError", loading: false});
                                    }
                                }).catch((err)=>{
                                    this.setState({hasError: "netError", loading: false})
                                });

                                this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});
                            };
                        }else{ //invalid shop handle
                            this.setState({hasError: "shopInfoError", loading: false});
                        }    
                        //params.delete('id')
                        window.history.replaceState(null, '', `/s/${handle}`);
                    }).catch((err)=>{
                        //params.delete('id')
                        window.history.replaceState(null, '', `/s/${handle}`);
                        this.setState({hasError: "netError", loading: false})
                    }); 
                }
            }).catch((err)=>{
                this.setState({hasError: "netError", loading: false})
            }); 
        } else {
            getShopInfo(handle, null).then((res)=>{
                if(res.data.data){
                    this.setState({shop: res.data.data});
                    if(res.time){
                        this.setState({actualCurrentTime: res.time});
                    }else{
                        this.setState({actualCurrentTime: moment().unix()});
                    }
                    if ((res.data.data.tags.find(c => c.id === "18")) || (res.data.data.productTags.includes("18"))) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                            return (
                                <div className='react-confirm-alert-body'>
                                    <h1>{t("adultTagWarningTitle")}</h1>
                                    <p>{t("adultTagWarningContent")}</p>
                                    <div className='react-confirm-alert-button-group'>
                                        <button className='warning-button' 
                                            onClick={() => {
                                                onClose();
                                                window.location.replace("https://www.luluhk.com");
                                            }}>
                                                {t("adultTagWarningNo")}
                                        </button>
                                        <button
                                            onClick={() => {
                                                onClose();
                                                this.setState({currentTime: moment().unix()});
                                                this.setState({deviceTime: new Date()});
                                                this.setState({selectTime: null});

                                                getShopProductList(handle, null).then((res)=>{
                                                    if(res.data){
                                                        this.setState({productList: res.data.data});
                                                    }else{ //invalid shop handle
                                                        this.setState({hasError: "shopInfoError", loading: false});
                                                    }
                                                }).catch((err)=>{
                                                    this.setState({hasError: "netError", loading: false})
                                                });

                                                getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                                                    if(res.data){
                                                        let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                                        let handleSet = new Set();
                                                        let shopList = [];
                                                        shopListWithDuplicate.map((shop) => {
                                                            if (!handleSet.has(shop.handle)) {
                                                              handleSet.add(shop.handle);
                                                              shopList.push(shop);
                                                            }
                                                            return shop
                                                          });
                                                        shopList.sort((a,b)=>{
                                                            if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                return 1;
                                                            }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                                                return -1;
                                                            }else{
                                                                return 0;
                                                            }
                                                        });
                                                        this.setState({shopList: shopList});
                                                    }else{
                                                        this.setState({hasError: "shopInfoError", loading: false});
                                                    }
                                                }).catch((err)=>{
                                                    this.setState({hasError: "netError", loading: false})
                                                });

                                                this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});
                                            }}
                                        >
                                            {t("adultTagWarningYes")}
                                        </button>
                                    </div>
                                </div>
                            );
                            },
                            closeOnClickOutside: false
                        });  
                    } else {
                        this.setState({currentTime: moment().unix()});
                        this.setState({deviceTime: new Date()});
                        this.setState({selectTime: null});
                        getShopProductList(handle, null).then((res)=>{
                            if(res.data){
                                this.setState({productList: res.data.data});
                            }else{ //invalid shop handle
                                this.setState({hasError: "shopInfoError", loading: false});
                            }
                        }).catch((err)=>{
                            this.setState({hasError: "netError", loading: false})
                        });

                        getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                            if(res.data){
                                let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                                let handleSet = new Set();
                                let shopList = [];
                                shopListWithDuplicate.map((shop) => {
                                    if (!handleSet.has(shop.handle)) {
                                      handleSet.add(shop.handle);
                                      shopList.push(shop);
                                    }
                                    return shop
                                  });
                                shopList.sort((a,b)=>{
                                    if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                        return 1;
                                    }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                        return -1;
                                    }else{
                                        return 0;
                                    }
                                });
                                this.setState({shopList: shopList});
                            }else{
                                this.setState({hasError: "shopInfoError", loading: false});
                            }
                        }).catch((err)=>{
                            this.setState({hasError: "netError", loading: false})
                        });
                        this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id, loading: false});
                    };
                }else{ //invalid shop handle
                    this.setState({hasError: "shopInfoError", loading: false});
                }
                //params.delete('id')
                window.history.replaceState(null, '', `/s/${handle}`);
            }).catch((err)=>{
                //params.delete('id')
                window.history.replaceState(null, '', `/s/${handle}`);
                this.setState({hasError: "netError", loading: false})
            }); 
        }   
    } 
    componentDidUpdate(prev) {
        if(prev.params.handle!==this.props.params.handle || prev.location.search!==this.props.location.search){
            this.componentDidMount();
        } 
    } 
    reloadBody(handle, selectTime){
        //this.setState({loading: true});
        document.querySelector(".tab-title li.active").classList.remove('active');
        document.querySelector('span[data-href="About"]').parentElement.classList.add('active');
        document.querySelector(".tab-inner.active").classList.remove('active');
        document.getElementById('About').classList.add("active");

        this.setState({currentTime: moment().unix()});
        this.setState({deviceTime: new Date()});
        this.setState({selectTime: selectTime});
        getShopInfo(handle, selectTime).then((res)=>{
            if(res.data.data){
                this.setState({shop: res.data.data});
                if(res.time){
                    this.setState({actualCurrentTime: res.time});
                }else{
                    this.setState({actualCurrentTime: moment().unix()});
                }
                this.setState({shareLink: process.env.REACT_APP_LINK +"/s/"+ res.data.data.handle + '?id=' + res.data.data.id});
                getMerchantShopByID(res.data.data.owner.id).then((res)=>{
                    if(res.data){
                        let shopListWithDuplicate = res.data.owner.concat(res.data.mall);
                        let handleSet = new Set();
                        let shopList = [];
                        shopListWithDuplicate.map((shop) => {
                            if (!handleSet.has(shop.handle)) {
                              handleSet.add(shop.handle);
                              shopList.push(shop);
                            }
                            return shop
                          });
                        shopList.sort((a,b)=>{
                            if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) > b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                return 1;
                            }else if (a.handle.toUpperCase().localeCompare(b.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'}) < b.handle.toUpperCase().localeCompare(a.handle.toUpperCase(), undefined, {numeric: true,sensitivity: 'base'})){
                                return -1;
                            }else{
                                return 0;
                            }
                        });
                        this.setState({shopList: shopList});
                    }else{
                        this.setState({hasError: "shopInfoError", loading: false});
                    }
                }).catch((err)=>{
                    this.setState({hasError: "netError", loading: false})
                });
            }else{ //invalid shop handle
                this.setState({hasError: "shopInfoError", loading: false});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError", loading: false})
        });
        getShopProductList(handle, selectTime).then((res)=>{
            if(res.data){
                this.setState({productList: res.data.data, loading: false});
            }else{ //invalid shop handle
                this.setState({hasError: "shopInfoError", loading: false});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError", loading: false})
        });
    }
    componentWillUnmount() { 
        window.removeEventListener('scroll', this.handleScroll);
    } 
    tabChange(e){
        document.querySelector(".tab-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".tab-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }

    productChange(e){
        document.querySelector(".box-title li.active").classList.remove('active')
        e.currentTarget.parentElement.classList.add("active")
        document.querySelector(".box-right .box-inner.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
    }
    handleScroll() {
        if(document.querySelector(".wrapper .mobile-shop-shopping-plan")){
            let scrollClass = document.querySelector(".wrapper .mobile-shop-shopping-plan").classList;
            var st = this.scrollY;
            // 判斷是向上捲動，而且捲軸超過 200px
            if (st < this.lastScrollY) {
                scrollClass.remove('hideUp');
            } else {
                scrollClass.add('hideUp');
            }
            this.lastScrollY = st;
        }
    }
    showMobilePlan(e){
        document.querySelector('.wrapper .content .content-small.width-small-2').classList.add('show');
        document.querySelector('.wrapper .content .content-big.width-big-2').classList.add('hide');
        
    }
    goBack(){
        try {
            this.props.navigate(-1);
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t, i18n} = this.props;
        const { shop, shopList, productList, loading, hasError, selectTime, currentTime, deviceTime, actualCurrentTime, shareLink } = this.state;
        if (loading) {
            return (
                <div className="content">
                    <div className="content-big width-big-2">
                        <EmptyLoading msg={t("loading")}/>
                    </div>
                </div>
            )
        }
        const nextLanguage = () => {
            if(i18n.language==="en"){
                changeLanguage("zh_Hant");
                this.reloadBody(handle, selectTime);
            }else if(i18n.language==="zh_Hant"){
                changeLanguage("zh_Hans");
                this.reloadBody(handle, selectTime);
            }else{
                changeLanguage("en");
                this.reloadBody(handle, selectTime);
            }
            /*if(props.reloadBody){
                props.reloadBody();
            }*/
        }

        const handle = this.props.params.handle;
        function findProductById(id){
            if(productList){
                let index;
                for(index=0;index<productList.length;index++){
                    if(productList[index].id===id){
                        return index;
                    }
                }
            }
            return -1;
        }

        function ShopCover(shop){
            let needCover, statusMsg;
            
            switch(shop.status){
                case shopStatusCode.active:
                    if(shop.isOpen){
                        needCover = false;
                    }else{
                        needCover = true;
                        if(shop.nextOpenTime){
                            statusMsg = getTimeRep(shop.nextOpenTime,"open",true);
                        }else{
                            statusMsg = t("shopClosedToday");
                        }
                    }
                    break;
                case shopStatusCode.temporarilyClosed:
                    needCover = true;
                    statusMsg = t("shopTemporarilyClosed");
                    break;
                case shopStatusCode.underRenovation:
                    needCover = true;
                    statusMsg = t("shopUnderRenovation");
                    break;
                case shopStatusCode.permanentlyClosed:
                    needCover = true;
                    statusMsg = t("shopPermanentlyClosed");
                    break;
                default: //error message: "Shop Closed"
                    needCover = true;
                    statusMsg = t("shopClosed");
                    break;
            }
            if(needCover){
                return(
                    <div className="mainImg_msg">
                        <p>{statusMsg}</p>
                    </div>
                )
            }else{
                return null
            }
        }

        function canSelectTime(dateTime) {
            if(!hasError){
            if (shop.status !== shopStatusCode.active) {
              return false;
            }
            for (let i = 0; i < 7; i++) {
                const timeZone = "Asia/Hong_Kong";
                const weekday = moment.unix(dateTime).tz(timeZone).weekday();
                const hour = moment.unix(dateTime).tz(timeZone).hour();
                const minute = moment.unix(dateTime).tz(timeZone).minute();
                const week = (weekday + i) % 7;
                if (shop.serviceTime[week].status === shopServiceTime.open24Hour) {
                    return true;
                }
                if (shop.serviceTime[week].status === shopServiceTime.custom) {
                    let minutes = [];
                    if (week === weekday) {
                        minutes.push(hour * 60 + minute);
                        for (let i = minutes[0] - minutes[0] % 15 + 15; i < 24 * 60; i += 15) {
                            minutes.push(i);
                        }
                        for (var a = 0; a < minutes.length; a++) {
                            for (var t = 0; t < shop.serviceTime[week].intervals.length; t++) {
                                if (shop.serviceTime[week].intervals[t].begin <= minutes[a] && shop.serviceTime[week].intervals[t].end >= minutes[a]) {
                                    return true;
                                }
                            }
                        }
                    } else {
                        for (var time = 0; time < shop.serviceTime[week].intervals.length; time++) {
                            if (shop.serviceTime[week].intervals[time].end - shop.serviceTime[week].intervals[time].begin >= 15) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }
        }
        if(shop && shopList && productList && !hasError){
            /*let emailsText = "", phonesText = "";
            shop.emails.forEach((email,index)=>{
                if(index!=0){
                    emailsText = emailsText.concat(", ")
                }
                emailsText = emailsText.concat(email);
            });
            shop.phones.forEach((phone,index)=>{
                if(index!=0){
                    phonesText = phonesText.concat(", ")
                }
                phonesText = phonesText.concat(phone);
            });*/
            let result = [];
            result = categoryToLevel(shop.categories);
            let shopID = "";
            shopID = shop.id;
            //TODO Allow scrolling in collection left bar
            let collectionNameHandle = [];
            let collectionNames = [];
            let pUncategorized = [];
            for(let i=0;i<productList.length;i++){
                pUncategorized.push(productList[i].id)
            }
            shop.collections.forEach((collection,index)=>{
                if (collection.products.length !== 0) {
                    var pUnavailable = 0;
                    collection.products.forEach((product)=>{
                        const index = pUncategorized.findIndex(v => v === product);
                        const productListIndex = findProductById(product);
                        if (index !== -1) {
                            pUncategorized.splice(index, 1);
                        }
                        if (productListIndex !== -1) {
                            if (productList[productListIndex].status === productStatusCode.unavailable) {
                                pUnavailable += 1;
                            }
                        } else {
                            pUnavailable += 1;
                        }
                    })
                    if (pUnavailable !== collection.products.length) {
                        collectionNameHandle.push({name: collection.name, id: collection.id, products: collection.products});
                        collectionNames.push(collection.name);
                    } 
                }
            });
            if (pUncategorized.length !== 0) {
                collectionNameHandle.push({name: t("uncategorized"), id: "uncategorized", products: pUncategorized});
            }
            
            let item_tag = shop.tags.map((tag, i) => 
                {if (tag.remarks && tag.remarks !== "") {
                    return <TagDialog
                        key={i} 
                        tag={tag}
                    />
                } else {
                    return <span key={ i }>{ tag.name }</span>
                }}
            );
            let inStorePayments = shop.inStorePayments;
            inStorePayments.sort((a,b)=>{
                return a.rank-b.rank
            });
            const descriptions =
        (shop.descriptions != null && shop.descriptions.length !== 0) ? shop.descriptions : (shop.owner.descriptions != null &&
            shop.owner.descriptions.length !== 0) ? shop.owner.descriptions : [];

            return (
            <React.Fragment>
                <Helmet>
                    <title>{`${shop.name} - Lulu HK`}</title>
                    <meta property="og:title" content={`${shop.name} - Lulu HK`} />
                    <meta property="og:description" content={shop.bio} />
                    <meta property="og:image" content={`${shop.cover.substring(0, shop.cover.lastIndexOf("."))}_luluimg-m.jpg`} />
                    <meta name="description" content={shop.bio}/>
                </Helmet>
                <div className="wrapper">
                    <Header actualCurrentTime={actualCurrentTime} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={shop.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}></Header>
                    <div className="mobile-shop-header">
                        <div className="mobile-nav">
                        <div className="mobile-icon-left">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            {/*<span className="icon LuluHK cart_line icon_pattern color-disabled title" onClick={(e)=>{this.setState({cartOpen: true})}}></span>
                            <WarningDialog open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                            {/*<div className="search-back">
                                <ArrowBackIosIcon onClick={this.goBack} />
                                <span className="text-main-color title"></span>
                </div>*/}
                        </div>
                    </div>
                    <div className="mobile-shopping-plan-content hide">
                        <div className="shopping-plan-content">
                            <div className="empty-plan">
                            </div>
                        </div>
                    </div>
                    <div className="content" id="shop-content">
                        <div className="content-big width-big-2">
                            <div className="allImgBox">
                                <div className="topImgBox" style={{ backgroundImage: "url("+shop.cover+")" }}></div>
                                <div className="mainImg">
                                    <img src={shop.cover} alt="" height="100%"/>
                                    <ShopCover
                                        status={shop.status}
                                        isOpen={shop.isOpen}
                                        nextOpenTime={shop.nextOpenTime}
                                    />
                                </div>
                            </div>
                            <div className="businessNews">
                                <div className="circleImg">
                                    <img src={shop.owner.avatar} alt=""/>
                                </div>
                                <div className="newsTitle">
                                    <span className="text-main-color shop-title">{shop.name}</span>
                                    <span className="shop-desc">{shop.bio}</span>
                                </div>
                                <div className="shareLink text-main-color">
                                    <p>
                                        <ShareSnack source={'shop'} shareLink={shareLink} title={shop.name} address={shop.address} phone={shop.phones[0]} whatsapp={shop.sns.whatsapp}/>
                                    </p>
                                </div>
                            </div>
                            <hr/>
                            <div id="tab-demo">
                            { //5 tabs
                                (((shop.isParking != null && shop.isParking
                                    && ((shop.parkID != null && shop.parkID !== "")
                                        || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                    (shop.isParking != null && !shop.isParking &&
                                        shop.parkings != null && shop.parkings.length !== 0)) &&
                                        productList.length !== 0 &&
                                        (descriptions != null &&descriptions.length!==0) && (shopList && shopList.length > 1))
                                    ? <ul className="tab-title" id="tab-title-five">
                                        <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                        <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                        <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                        <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                        <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                    </ul>
                                    : //4 tabs
                                    ((((shop.isParking != null && shop.isParking
                                    && ((shop.parkID != null && shop.parkID !== "")
                                        || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                    (shop.isParking != null && !shop.isParking &&
                                        shop.parkings != null && shop.parkings.length !== 0)) ||
                                    productList.length !== 0) &&
                                    (descriptions != null &&descriptions.length!==0) && (shopList && shopList.length > 1)) ||
                                    ((((shop.isParking != null && shop.isParking
                                        && ((shop.parkID != null && shop.parkID !== "")
                                            || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                        (shop.isParking != null && !shop.isParking &&
                                            shop.parkings != null &&
                                            shop.parkings.length !== 0)) && productList.length !== 0 &&
                                        ((
                                            (descriptions != null &&descriptions.length!==0)) || (shopList && shopList.length > 1))))
                                    ? (
                                        ((((shop.isParking != null && shop.isParking
                                            && ((shop.parkID != null && shop.parkID !== "")
                                                || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                            (shop.isParking != null && !shop.isParking &&
                                                shop.parkings != null && shop.parkings.length !== 0)) ||
                                            productList.length !== 0) &&
                                            (descriptions != null &&descriptions.length!==0) && (shopList && shopList.length > 1)) ?
                                            (productList.length !== 0 ? 
                                                //case 1
                                        <ul className="tab-title">
                                            <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                            <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                            <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                            <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                        </ul>
                                                : //case 2
                                        <ul className="tab-title">
                                            <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                            <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                            <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                            <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                        </ul>
                                            )
                                            : ((shopList && shopList.length > 1) ?
                                                //case 3
                                        <ul className="tab-title">
                                            <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                            <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                            <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                            <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                        </ul>
                                                ://case 4
                                            <ul className="tab-title">
                                                <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                                <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                                <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                            </ul>
                                            )
                                    )
                                    : //3 tabs
                                    (((((shop.isParking != null && shop.isParking
                                    && ((shop.parkID != null && shop.parkID !== "")
                                        || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                    (shop.isParking != null && !shop.isParking &&
                                        shop.parkings != null && shop.parkings.length !== 0)) ||
                                    productList.length !== 0 || (
                                    (descriptions != null &&descriptions.length!==0))) && (shopList && shopList.length > 1)) ||
                                    ((((shop.isParking != null && shop.isParking
                                        && ((shop.parkID != null && shop.parkID !== "")
                                            || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                        (shop.isParking != null && !shop.isParking &&
                                            shop.parkings != null &&
                                            shop.parkings.length !== 0)) && (productList.length !== 0 ||
                                        (
                                            (descriptions != null &&descriptions.length!==0)) ||
                                        (shopList && shopList.length > 1)))) || (productList.length !== 0 &&
                                    (((shop.isParking != null && shop.isParking
                                        && ((shop.parkID != null && shop.parkID !== "")
                                            || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                        (shop.isParking != null && !shop.isParking &&
                                            shop.parkings != null &&
                                            shop.parkings.length !== 0)) ||
                                        (
                                            (descriptions != null &&descriptions.length!==0)) ||
                                        (shopList && shopList.length > 1))) || ((
                                    (descriptions != null &&descriptions.length!==0)) &&
                                    (((shop.isParking != null && shop.isParking
                                        && ((shop.parkID != null && shop.parkID !== "")
                                            || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                        (shop.isParking != null && !shop.isParking &&
                                            shop.parkings != null &&
                                            shop.parkings.length !== 0)) || productList.length !== 0 ||
                                        (shopList && shopList.length > 1)))) 
                                        ? (
                                            ((((shop.isParking != null && shop.isParking
                                                && ((shop.parkID != null && shop.parkID !== "")
                                                    || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                                (shop.isParking != null && !shop.isParking &&
                                                    shop.parkings != null && shop.parkings.length !== 0)) ||
                                                productList.length !== 0 || (
                                                (descriptions != null &&descriptions.length!==0))) && (shopList && shopList.length > 1)) ?
                                                //case 1
                                                <ul className="tab-title" id="tab-title-three">
                                                    {productList.length !== 0 ?
                                                        <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                                    : (descriptions != null &&descriptions.length!==0) ?
                                                        <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                        : <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                                    }
                                                    <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                                    <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                </ul> 
                                                : ((((shop.isParking != null && shop.isParking
                                                    && ((shop.parkID != null && shop.parkID !== "")
                                                        || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                                    (shop.isParking != null && !shop.isParking &&
                                                        shop.parkings != null &&
                                                        shop.parkings.length !== 0)) && (productList.length !== 0 ||
                                                    (
                                                        (descriptions != null &&descriptions.length!==0)) ||
                                                    (shopList && shopList.length > 1)))) ?
                                                    //case 2
                                                    <ul className="tab-title" id="tab-title-three">
                                                        {productList.length !== 0 ?
                                                            <><li key="product" className=""><span data-href="Product" onClick={this.tabChange}>{t("product")}</span></li>
                                                            <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li></>
                                                        : (descriptions != null &&descriptions.length!==0) ?
                                                        <><li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                        <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li></>
                                                            : <><li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                                            <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li></>
                                                        }
                                                        <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                    </ul> 
                                                    : (productList.length !== 0 &&
                                                        (((shop.isParking != null && shop.isParking
                                                            && ((shop.parkID != null && shop.parkID !== "")
                                                                || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                                            (shop.isParking != null && !shop.isParking &&
                                                                shop.parkings != null &&
                                                                shop.parkings.length !== 0)) ||
                                                            ((descriptions != null &&descriptions.length!==0)) ||
                                                            (shopList && shopList.length > 1))) ?
                                                            //case 3
                                                            <ul className="tab-title" id="tab-title-three">
                                                                <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                                                {(descriptions != null &&descriptions.length!==0) ?
                                                                    <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                                : (shopList && shopList.length > 1) ?
                                                                    <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                                                :
                                                                <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                                                }
                                                                <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                            </ul> :
                                                            //case 4
                                                            <ul className="tab-title" id="tab-title-three">
                                                                {productList.length !== 0 ?
                                                                    <><li key="product" className=""><span data-href="Product" onClick={this.tabChange}>{t("product")}</span></li>
                                                                    <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li></>
                                                                : (shopList && shopList.length > 1) ?
                                                                <><li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                                <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li></>
                                                                    : <><li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                                    <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li></>
                                                                }
                                                                <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                            </ul>   
                                        ) 
                                        : 
                                        //2 tabs
                                        (((shop.isParking != null &&
                                    shop.isParking
                                    && ((shop.parkID != null && shop.parkID !== "")
                                        || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                    (shop.isParking != null && !shop.isParking &&
                                        shop.parkings != null && shop.parkings.length !== 0)) ||
                                    productList.length !== 0 || (
                                    (descriptions != null &&descriptions.length!==0)) || (shopList && shopList.length > 1)) 
                                    ? (
                                        ((shop.isParking != null &&
                                            shop.isParking
                                            && ((shop.parkID != null && shop.parkID !== "")
                                                || (shop.parkLink != null && shop.parkLink !== ""))) ||
                                            (shop.isParking != null && !shop.isParking &&
                                                shop.parkings != null && shop.parkings.length !== 0)) ?
                                                //case 1
                                                <ul className="tab-title" id="tab-title-two">
                                                    <li key="vacancy" className=""><span data-href="Vacancy" onClick={this.tabChange}>{t("vacancy")}</span></li>
                                                    <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                </ul> 
                                                : productList.length !== 0 ?
                                                //case 2
                                                <ul className="tab-title" id="tab-title-two">
                                                    <li key="product" className=""><span data-href="Product" onClick={ this.tabChange }>{t("product")}</span></li>
                                                    <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                </ul> 
                                                : (descriptions != null &&descriptions.length!==0) ?
                                                //case 3
                                                <ul className="tab-title" id="tab-title-two">
                                                    <li key="post" className=""><span data-href="Post" onClick={ this.tabChange }>{t("post")}</span></li>
                                                    <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                </ul> 
                                                :
                                                //case 4
                                                <ul className="tab-title" id="tab-title-two">
                                                    <li key="shop" className=""><span data-href="Shop" onClick={ this.tabChange }>{t("shop")}</span></li>
                                                    <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                                </ul> 
                                    ) : 
                                        //1 tab
                                        <ul className="tab-title" id="tab-large">
                                            <li key="about" className="active"><span data-href="About" onClick={ this.tabChange }>{t("about")}</span></li>
                                        </ul>               
                            }      
                                <div id="Product" className="tab-inner">
                                    {productList.length === 0 ? 
                                        <div className="no-product" style={{ display: "block", marginTop: "5em" }}>
                                            <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                            <p>{t("noProductInShop")}</p>
                                        </div>
                                    : 
                                        <div className="tab-box">
                                            <div className="box-left">
                                                {/*TODO Scroll to #collection on page load*/}
                                            <Scrollspy className="box-title" items={ collectionNames } currentClassName="current" offset={-110}>
                                                    {collectionNameHandle.map((collection,index)=>{
                                                        return <li key={index}><a href={"#"+collection.id}>{collection.name}</a></li>
                                                    })}
                                                </Scrollspy>
                                            </div>
                                            <div className="box-right">
                                                {collectionNameHandle.map((collection,index)=>{
                                                    return(
                                                        <div key={index} id={collection.id} className={"box-inner "+(index===0?"active":"")}>
                                                            <p>{collection.name}</p>
                                                            <div className="box-items">
                                                                {collection.products.map((productID)=>{
                                                                    const productListIndex = findProductById(productID)
                                                                    if (productListIndex !== -1) {
                                                                        return(
                                                                        <ProductItem
                                                                            key={productID}
                                                                            selectTime={selectTime}
                                                                            id={productList[productListIndex].id}
                                                                            handle={productList[productListIndex].handle}
                                                                            shopHandle={shop.handle}
                                                                            status = {productList[productListIndex].status}
                                                                            shopStatus = {shop.status}
                                                                            stock = {productList[productListIndex].stock}
                                                                            isAvailable = {productList[productListIndex].isAvailable}
                                                                            isOpen = {productList[productListIndex].isOpen}
                                                                            nextAvailableTime = {productList[productListIndex].nextAvailableTime}
                                                                            imgSrc={productList[productListIndex].cover}
                                                                            imgAlt={productList[productListIndex].name}
                                                                            title={productList[productListIndex].name}
                                                                            tagList= {productList[productListIndex].tags}
                                                                            priceStatus={productList[productListIndex].priceStatus}
                                                                            priceLow={productList[productListIndex].priceLow}
                                                                            priceDiscountLow={productList[productListIndex].priceDiscountLow}
                                                                            priceHigh={productList[productListIndex].priceHigh}
                                                                            priceDiscountHigh={productList[productListIndex].priceDiscountHigh}
                                                                            shopName={shop.name}
                                                                            shopAddress={shop.address}
                                                                            shopPhone={shop.phones[0]}
                                                                            shopWhatsapp={shop.sns.whatsapp}
                                                                        />
                                                                        )
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }    
                                </div>        
                                <div id="Post" className="tab-inner">
                                    <div className="vertical">
                                        {descriptions &&descriptions.length!==0 ? 
                                            descriptions.map((description,index)=>{
                                                if(description.type==="text"){
                                                    var dataList = JSON.parse(description.data)
                                                    var deltaOps =  getDeltaOps(dataList)
                                                    var cfg = {};
                                                    var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
                                                    var html = converter.convert();
                                                    return <div key={index} className="text-border">{parse(html)}</div>
                                                }else{
                                                    return <img key={index} className="image-border" src={description.data} alt=""/>
                                                }
                                            })
                                        :
                                            <div className="no-post" style={{ display: "block", marginTop: "5em" }}>
                                                <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                                <p>{t("noPost")}</p>
                                            </div> 
                                        }
                                    </div>
                                </div>
                                <div id="Vacancy" className="tab-inner">
                                    <div className="no-post" style={{ display: "block", marginTop: "5em" }}>
                                        <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                        <p>{t("vacancyApp")}</p>
                                    </div>
                                </div>
                                <div id="Shop" className="tab-inner">
                                    <div className="tab-box">
                                        <div className="box-right">
                                            <div className="item-list">  
                                                {shopList ? shopList.map((shop,index)=>{
                                                    let displayStatus, displayStatusMsg;
                                                    let shopResult = "";
                                                    shopResult = categoryToLevel(shop.categories);
                                        
                                                    var desc = ""
                                                    if (shopResult === undefined || shopResult.length === 0 || (shopResult.length === 1 && shopResult[0].trim() === '')) {
                                                        desc = "uncategorized"
                                                    } else {
                                                    for (var i = 0; i < shopResult.length; i++) {
                                                        if (i===3) {break}
                                                        var categoryDesc = (i === shopResult.length - 1 || i === 2) ? shopResult[i].split('>').pop().trim() : `${shopResult[i].split('>').pop().trim()}\xa0 • \xa0`
                                                        desc = desc + categoryDesc
                                                    }}
                                                    switch(shop.status){
                                                        case shopStatusCode.active:
                                                            if(shop.isOpen){
                                                                displayStatus = "normal";
                                                            }else{
                                                                displayStatus = "photo";
                                                                if(shop.nextOpenTime){
                                                                    displayStatusMsg = getTimeRep(shop.nextOpenTime,"open");
                                                                }else{
                                                                    displayStatusMsg = t("shopClosedToday");
                                                                }
                                                            }
                                                            break;
                                                        case shopStatusCode.temporarilyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopTemporarilyClosed");
                                                            break;
                                                        case shopStatusCode.underRenovation:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopUnderRenovation");
                                                            break;
                                                        case shopStatusCode.permanentlyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopPermanentlyClosed");
                                                            break;
                                                        default:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopClosed");
                                                            break;
                                                    }
                                                    return <BoxItem
                                                        key={shop.id} //TODO: why sometimes error?
                                                        handle={shop.handle}
                                                        status={displayStatus}
                                                        msg={displayStatus==="photo"?displayStatusMsg:null}
                                                        imgSrc={shop.cover}
                                                        imgAlt={shop.name}
                                                        name={shop.name}
                                                        desc={desc}
                                                        isSameShop={shop.id === shopID}
                                                        showID={true}
                                                    />  
                                                }): <div>{t("loading")}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="About" className="tab-inner active">
                                    <div className="vertical">
                                        <div className="about-box">
                                            <div className="about-item">
                                                <span className="icon LuluHK home_line"></span>
                                                <label>{shop.name}</label>
                                            </div>
                                            {(result === undefined || result.length === 0 || (result.length === 1 && result[0].trim() === '')) ? null : <div className="about-item">
                                                <span className="icon LuluHK category"></span>
                                                <label>{result.map((category,index)=>{
                                                    if (index === (result.length-1)){
                                                        return(
                                                            <CategoryDialog 
                                                                key={category.split('>').pop().trim()}
                                                                category={category}
                                                            />
                                                        )
                                                    } else {
                                                        return(
                                                            <React.Fragment key={category.split('>').pop().trim()}>
                                                                <CategoryDialog
                                                                    category={category}
                                                                />,&nbsp;
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })}</label>
                                            </div>}
                                            <div className="about-item">
                                                <span className="icon LuluHK time_line"></span>
                                                <label><ServiceHoursDialog 
                                                    status={shop.status} serviceTime={shop.serviceTime} lastOrder={shop.lastOrder} isPublicHolidayWeek={shop.isPublicHolidayWeek} selectTime={selectTime} currentTime={currentTime} deviceTime={deviceTime} actualCurrentTime={actualCurrentTime}
                                                /></label>
                                            </div>
                                            <div className="about-item">
                                                <span className="icon LuluHK location_line"></span>
                                                <label><a href={`https://www.google.com/maps/search/${shop.lat},${shop.lon}/@${shop.lat},${shop.lon},17z`} target="_blank" rel="noreferrer">
                                                {shop.address}
                                                </a></label>
                                            </div>
                                            {shop.phones&&shop.phones.length!==0 ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK call_line"></span>
                                                    <label>{shop.phones.map((phone,index)=>{
                                                            if (index === (shop.phones.length-1)){
                                                                return(
                                                                    <a key={phone} href={`tel:${phone}`}>{phone}</a>
                                                                )
                                                            } else {
                                                                return(
                                                                    <React.Fragment key={phone}><a href={`tel:${phone}`}>{phone}</a>,&nbsp;</React.Fragment>
                                                                )
                                                            }
                                                        })}</label>
                                                </div>
                                            :null}
                                            {shop.emails&&shop.emails.length!==0 ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK mail_line"></span>
                                                        <label>{shop.emails.map((email,index)=>{
                                                            if (index === (shop.emails.length-1)){
                                                                return(
                                                                    <a key={email} href={`mailto:${email}`}>{email}</a>
                                                                )
                                                            } else {
                                                                return(
                                                                    <React.Fragment key={email}><a href={`mailto:${email}`}>{email}</a>,&nbsp;</React.Fragment>
                                                                )
                                                            }
                                                        })}</label>
                                                </div>
                                            :null}
                                            {shop.website ?
                                                <div className="about-item">
                                                    <span className="icon LuluHK lang"></span>
                                                    <a href={shop.website} target="_blank" rel="noreferrer">{shop.website}</a>
                                                </div>
                                            :null}
                                            {shop.inStorePayments&&shop.inStorePayments.length!==0 ? 
                                                <div className="about-item">
                                                    <span style={{fontSize: "14px"}} className="icon LuluHK payment_line"></span>
                                                    <label>{inStorePayments.map((payment,i) => {
                                                            if (i === (shop.inStorePayments.length-1)){
                                                                return(
                                                                    <React.Fragment key={payment.name}>{payment.name}</React.Fragment>
                                                                )
                                                            } else {
                                                                return(
                                                                    <React.Fragment key={payment.name}>{payment.name},&nbsp;</React.Fragment>
                                                                )
                                                            }
                                                    })}</label>
                                                </div>
                                            : null}
                                            <div className="social-media">
                                                {shop.sns.facebook?
                                                    <a href={shop.sns.facebook} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/facebook.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.instagram?
                                                    <a href={shop.sns.instagram} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/instagram.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.youtube?
                                                    <a href={shop.sns.youtube} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/youtube.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {/*{shop.sns.weibo?
                                                    <a href={shop.sns.weibo} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/weibo.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.twitter?
                                                    <a href={shop.sns.twitter} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/twitter.png" alt=""></img>
                                                    </a>
                                                :null}*/}
                                                {shop.sns.messenger?
                                                    <a href={shop.sns.messenger} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/messenger.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.whatsapp?
                                                    <a href={shop.sns.whatsapp} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/whatsapp.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.wechat?
                                                    <SNSDialog media="wechat" mediaId={shop.sns.wechat}/>
                                                :null}
                                                {/*shop.sns.qq?
                                                    <SNSDialog media="qq" mediaId={shop.sns.qq}/>
                                                :null*/}
                                                {shop.sns.deliveroo?
                                                    <a href={shop.sns.deliveroo} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/deliveroo.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.foodpanda?
                                                    <a href={shop.sns.foodpanda} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/foodpanda.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {shop.sns.openrice?
                                                    <a href={shop.sns.openrice} target="_blank" rel="noreferrer">
                                                        <img src="/img/icon/openrice.png" alt=""></img>
                                                    </a>
                                                :null}
                                                {/* <SNSDialog />
                                                <YoutubeDialog /> */}
                                            </div>
                                            {shop.tags&&shop.tags.length!==0 ? <div className="item-tag">{ item_tag }</div> : null}
                                            {shop.awards&&shop.awards.length!==0 ? 
                                                <div className="item-award">
                                                    {shop.awards.map((award, i) => {
                                                        if (award.description) {
                                                            return(
                                                                <AwardDialog
                                                                    key={award.name} 
                                                                    award={award}
                                                                />
                                                            )
                                                        } else {
                                                            return (
                                                                <span key={award.name}>{award.name}</span>
                                                            )
                                                        }
                                                    })}       
                                                </div>
                                            : null}
                                            {((shopList && shopList.length > 1) || (shop.malls&&shop.malls.length!==0)) ? <hr/> : null}
                                            {(shopList && shopList.length > 1) ? <div className="social-media-msg">
                                                <h3>{t("owner")}</h3>
                                                <UsernameRedirect username={shop.owner.username}/>
                                            </div> : null}
                                            {shop.malls&&shop.malls.length!==0 ?
                                                <div className="social-media-msg">
                                                    <h3 style={{margin:"8px 0 15px 0"}}>{t("shoppingMall")}</h3>
                                                    <div className="landmark">
                                                        {shop.malls.map((mall,index)=>{
                                                            return(
                                                                <UsernameRedirect key={mall.username} username={mall.username}/>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            :null}

                                            {shop.images&&shop.images.length!==0 ? 
                                                <div className="social-media-image-hr">
                                                    <hr/>
                                                </div>
                                            : null}
                                            {shop.images&&shop.images.length!==0 ? 
                                                <div className="social-media-image">
                                                    <h3>{t("ui003003ShopPhotos")}</h3>&nbsp;
                                                    <label>{t("ui003017ImageLength",{
                                                        length: shop.images.length, 
                                                    })}</label>
                                                </div>
                                            : null}
                                            {shop.images&&shop.images.length!==0 ? 
                                                <div className="images-box">
                                                    <ImagesViewer images={shop.images}/>
                                                </div>
                                                : null}
                                            
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            {/* <div className="mobile-shop-shopping-plan">
                                <button className="shop-mobile-shopping-plan" onClick={this.showMobilePlan}>View Plan</button>
                            </div> */}
                        </div>
                        <div className="content-small width-small-2">
                            <ShoppingPlan
                                productList = {[
                                ]}
                                invalidList = {[
                                ]}
                                shopImgSrc = ""
                                shopImgAlt = ""
                                shopTitle = ""
                                total = "0"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
            );
        }else if(hasError){ //header + EmptyError
            if(hasError==="shopInfoError"){
                return <Page404 />
            }else{
                return(
                    <React.Fragment>
                    <div className="wrapper">
                    {/*<Header actualCurrentTime={actualCurrentTime} time={true} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={shop.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}></Header>*/}
                        <Header reloadBody={()=>this.reloadBody(handle)}></Header>
                        <div className="mobile-shop-header">
                            <div className="mobile-nav">
                            <div className="mobile-icon-left">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            {/*<span className="icon LuluHK cart_line icon_pattern color-disabled title" onClick={(e)=>{this.setState({cartOpen: true})}}></span>
                            <WarningDialog open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                                {/*<div className="search-back">
                                    <ArrowBackIosIcon onClick={this.goBack} />
                                    <span className="text-main-color title"></span>
                </div>*/}
                                {/*<TimeDialogItem actualCurrentTime={actualCurrentTime} time={true} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={shop.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}/>*/}
                            </div>
                        </div>
                        <div className="mobile-shopping-plan-content hide">
                            <div className="shopping-plan-content">
                                <div className="empty-plan">

                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="content-big width-big-2">
                                <EmptyError msg={t("error")+": "+t(hasError)}/>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                );
            }
        }else{ //header + EmptyLoading
            return(
                <React.Fragment>
                    <div className="wrapper">
                    {/*<Header actualCurrentTime={actualCurrentTime} time={true} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={shop.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}></Header>*/}
                        {<Header reloadBody={()=>this.reloadBody(handle)}></Header>}
                        <div className="mobile-shop-header">
                            <div className="mobile-nav">
                            <div className="mobile-icon-left">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            {/*<span className="text-main-color app" onClick={() => window.open(shareLink, "_blank")}>APP</span>*/}
                            {/*<span className="icon LuluHK cart_line icon_pattern color-disabled title" onClick={(e)=>{this.setState({cartOpen: true})}}></span>
                            <WarningDialog open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>*/}
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={shareLink} open={ this.state.cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                        </div>
                                {/*<div className="search-back">
                                    <ArrowBackIosIcon onClick={this.goBack} />
                                    <span className="text-main-color title"></span>
                </div>*/}
                                {/*<TimeDialogItem actualCurrentTime={actualCurrentTime} time={true} canSelectTime={actualCurrentTime ? canSelectTime(actualCurrentTime) : canSelectTime(currentTime)} selectTime={selectTime} serviceTime={shop.serviceTime} deviceTime={deviceTime} currentTime={currentTime} reloadBody={(selectTime)=>this.reloadBody(handle, selectTime)}/>*/}
                            </div>
                        </div>
                        <div className="mobile-shopping-plan-content hide">
                            <div className="shopping-plan-content">
                                <div className="empty-plan">

                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="content-big width-big-2">
                                <EmptyLoading msg={t("loading")}/>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
            );
        }
    }
}

const ShopWithLocation = (props) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return <Shop {...props} location={location} params={params} navigate={navigate} />;
};

export default withTranslation()(ShopWithLocation);
