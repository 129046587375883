import React from "react";
import { Link } from 'react-router-dom';

function UsernameRedirect(props) {
    //const [open, setOpen] = React.useState(false);
  
    /*const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };*/
    return (
        <React.Fragment>
            <div /*onClick={handleClickOpen}*/ style={{margin: "2.5px 0 2.5px 0", cursor: "pointer"}}>
                <Link /*target="_blank"*/ key={props.username} to={"/m/"+props.username}>
                {"@"+props.username}
                </Link>
            </div>
            {/*<DownloadAppDialog open={open} onClose={handleClose} username={props.username}/>*/}
        </React.Fragment>
    );
}
export default UsernameRedirect;
